import { Component } from 'react'
import companyImg from '../../../../assets/images/company.png'
import './styles.sass'
import { withRouter } from '../../../../insfrastructure/utils/with-router-component'
import { CandidateService } from '../../../../insfrastructure/services/candidates'
import { parseJwt } from '../../../../insfrastructure/utils/JWTParser'
import { retriveJob } from '../../../../insfrastructure/services/jobs'
import { StaticValues } from '../../../../insfrastructure/services/static_values'

class JobOfferDetails extends Component<any> {
  state: any = {
    offer: {},
    formErrors: {},
    user_data: {},
    job_types: {},
    usertype: ''
  }

  BRLReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  componentDidMount() {
    this.getStaticValues();
    this.getUserData();
    this.retriveOffer();
  }

  async getUserData() {
      const auth_token = localStorage.getItem('auth_token');
      if(!auth_token) return;
  
      const candidateService = new CandidateService();
              
      const candidate = await candidateService.retrieve();
  
      this.setState({ user_data: candidate, usertype: parseJwt(auth_token).profiles[0] });
  }

  getStaticValues = async () => {
    try {  
      const staticValues = JSON.parse(localStorage.getItem('static_values')+'') as StaticValues;
  
      const job_types = staticValues.job_type;
  
      this.setState({ job_types });
    } catch (error) {}
  }

  retriveOffer = async () => {
      try {
        this.setState({ loading: true });

        const offer = await retriveJob(1);

        this.setState({ offer });
        
        setTimeout(() => {
          this.setState({ loading: false }); 
        }, 1000);
      } catch (error) {
        setTimeout(() => {
          this.setState({ loading: false }); 
        }, 500);
      }
  }

  onSubmit = () => {
    try {
      this.setState({ loading: true });
      setTimeout(() => {
        this.setState({ loading: false });
      }, 200);
    } catch (error) {
      this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='job-offer-details company-page'>
        <div className='block'>
            <h3>Vaga</h3>
            <div className='columns'>
              <div className='column'>
                <div className='sub-columns'>
                  <div className='sub-column'>
                    <p>Nome: <b>{this.state.offer.job_title?.name}</b></p>
                    <p>Tipo da Vaga: <b>{this.state.job_types[this.state.offer.job_type]}</b></p>
                  </div>
                  <div className='sub-column'>
                    <p>Salário: <b>{this.BRLReal.format(this.state.offer.salary)}</b></p>
                    <p>Cidade: <b>{this.state.offer.city}</b></p>
                  </div>
                </div>
                <p>Descrição: {this.state.offer.job_description}</p>
              </div>
              <div className='column'>
                <img src={companyImg} alt='company' />
              </div>
            </div>
            {this.state.usertype === 'candidate' && <button className='primary' onClick={this.onSubmit}>Candidatar-se</button>}
            {this.state.usertype === 'company' && <button className='primary' onClick={this.onSubmit}>Editar</button>}
        </div>
      </div>
    )
  }
}

export default withRouter(JobOfferDetails);
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import searchIcon from '../../../../../../assets/icons/search.png'
import filterIcon from '../../../../../../assets/icons/filter.png'
import { listJobsOpen } from '../../../../../../insfrastructure/services/jobs'
import { StaticValues } from '../../../../../../insfrastructure/services/static_values'

export default class Oportunities extends Component {
    state: Record<string, any> = {
        job_types: [
          // {
          //   name: 'effective',
          //   label: 'EFETIVA',
          //   selected: false
          // }
        ],
        job_results : [],
        loading_job: false,
        slides: [
          'https://d2d7ho1ae66ldi.cloudfront.net/ArquivoNoticias/7daf2200-2aca-11ed-aa6e-9587410378a2/melkor-morgoth.jpg',
          'https://images.nightcafe.studio/jobs/Qgdvd7TlGKDSABG5r7Ie/Qgdvd7TlGKDSABG5r7Ie--1--mew4h.jpg?tr=w-1600,c-at_max'
        ]
    }

    BRLReal = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    })

    async componentDidMount() {
        setTimeout(() => {
          this.getStaticValues(); 
        });

        try {
          const job_results = (await listJobsOpen({ offset: 0, limit: 3 })).results.map(x => x.node);
          console.log(job_results);
          
          this.setState({ job_results });
        } catch (error) {
          console.log(error);
        };
    }

    getStaticValues = async () => {
      try {  
        const staticValues = JSON.parse(localStorage.getItem('static_values')+'') as StaticValues;          
        const job_types = staticValues.job_type;
        console.log(job_types);
    
        this.setState({ job_types });
      } catch (error) {}
    }

    render() {
        return (
            <div className='oportunities'>
            <div className='content'>
                <h1>OPORTUNIDADES <span>EMPREGA MECÂNICO</span></h1>
                <div className='job-types'>
                {
                    Object.values(this.state.job_types).map((x:any, i:number) =>
                    <div key={'job_type_'+i} className={'job-type '+ (x.selected ? 'selected' : '')}>
                      {x.replaceAll('_', ' ')}
                    </div>)
                }
                </div>
                <div className='oportunities-searcher'>
                    <Link to='offers'><img className='search-icon' src={searchIcon} alt='search' /></Link>
                    <input type='text' placeholder='Pesquisar vaga' />
                    <img className='filter-icon' src={filterIcon} alt='filter' />
                </div>
                <div className='job-results'>
                {
                    !this.state.loading_job ?
                    <>
                    {
                        this.state.job_results.length === 0 ?
                        <p>Nenhuma oportunidade encontrada</p> :
                        this.state.job_results.map((x: any, i:number) => 
                        <div key={'job_'+i} className='job'>
                          <div className='top-row'>
                              <div className='job-info'>
                              <div className='main'>
                                  <h3>{x.jobTitle.name}</h3>
                                  <p>{x.company}</p>
                              </div>
                              <div className='location'>
                                  <p>{x.city.displayName}</p>
                              </div>
                              <div className='salary'>
                                  <p>{this.BRLReal.format(x.salary)}</p>
                              </div>
                              </div>
                              {window.innerWidth > 1000 &&
                              <div className='sign-in'>
                              <button className='primary'>Candidatar-se</button>
                              </div>}
                          </div>
                          <div className='bottom-row'>
                              <div className='experience'>
                              <p><b>Experiencia:</b> 1 a 2 anos</p>
                              </div>
                              <div className='observations'>
                              <p dangerouslySetInnerHTML={{__html: x.jobDescription}}></p>
                              </div>
                              <div className='type'>
                              <span>{this.state.job_types[x.jobType.toLowerCase()]}</span>
                              </div>
                          </div>
                          {window.innerWidth <= 1000 &&
                          <div className='sign-in'>
                              <button className='primary'>Candidatar-se</button>
                          </div>}
                        </div>)
                    }
                    </> : 
                    <>
                        <p>Carregando...</p>
                    </>
                }
                </div>
            </div>
            </div>
        )
    }
}

import React, { Component } from 'react'
import leftIcon from '../../../../../assets/icons/arrow-left.png'
import rightIcon from '../../../../../assets/icons/arrow-right.png'
import './styles.sass'

interface State {
  slides: any[],
  currentSlide: any,
  sliderWidth: any,
  sliderInterval: any,
  sliderRef: any
};

export class ItemSlider extends Component<{ passedSlides: any[] }> {
  state: State = {
    slides: [],
    currentSlide: 1,
    sliderWidth: 0,
    sliderInterval: null,
    sliderRef: null
  }
  sliderRef = React.createRef<any>()

  componentDidMount() {
    if(!this.sliderRef.current) return;

    const sliderWidth = (this.sliderRef.current as HTMLElement).offsetWidth;

    // if(passedSlides.length === 1) {
    //   return;
    // };

    const slidesCopy = this.props.passedSlides.map((x, index) => ({
      image: x
    }));
  
    const firstSlide = [...slidesCopy].shift();
    const lastSlide = [...slidesCopy].pop();

    const slides = [
      lastSlide,
      ...slidesCopy,
      firstSlide
    ].map((x, index) => ({
      ...x,
      left: sliderWidth * index
    }));

    this.setState({
      ...this.state,
      sliderWidth,
      slides
    });

    setTimeout(() => {
      const slidesCopy = slides.map(x => {

        x.left = x.left - sliderWidth;

        return x;
      });

      this.setState({
        ...this.state,
        slidesCopy
      });
    });
  }

  onBtnClick(orientation: string) {
    const slidesCopy = this.state.slides.map(x => {

      x.left = orientation === 'next' ? x.left - this.state.sliderWidth : x.left + this.state.sliderWidth;

      return x;
    });

    this.setState({
      ...this.state,
      slidesCopy,
      currentSlide: orientation === 'next' ? this.state.currentSlide + 1 : this.state.currentSlide - 1
    });
  }

  jumpToPosition(currentSlide: number) {
    this.state.slides.map((x, index) => {

      x.left = (this.state.sliderWidth * index) - (this.state.sliderWidth * currentSlide);

      return x;
    });

    this.setState({
      ...this.state,
      currentSlide
    });
  }

  render() {
    return (
      <div className='slider-container'>
        <div ref={this.sliderRef} className='item-slider-container noselect'>
          {this.state.slides.length > 1 &&
          <>
            {this.state.currentSlide > 1 &&
            <button className='previous jump-btn' onClick={() => this.onBtnClick('before')}>
              <img src={leftIcon} />
            </button>}
            {this.state.currentSlide < this.props.passedSlides.length &&
              <button className='next jump-btn' onClick={() => this.onBtnClick('next')}>
              <img src={rightIcon} />
            </button>}
          </>}
          <div className='slides'>
            {
              this.state.slides.map((slide, index) => 
                <div key={'item_slide_'+index} className='slide' style={{width: this.state.sliderWidth, left: slide.left}}>
                  <img className='background' src={slide.image} />
                </div>  
              )
            }
          </div>
          {/* {this.props.passedSlides.length > 1 &&
          <div className='slide-dots'>
            {
              Array.from(Array(this.props.passedSlides.length).keys()).map(i => 
                <div key={'item_dot_'+i} className={'dot '+ (this.state.currentSlide === i+1 ? 'selected' : '')} onClick={() => this.jumpToPosition(i+1)}></div>
              )
            }
          </div>} */}
        </div>
      </div>
    )
  }
}
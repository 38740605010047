import { Component } from 'react'
import './styles.sass'

interface Props {
    name: string,
    label: string,
    options: any[],
    disabled?: boolean,
    value?: any,
    onChange?: (value: any) => any
}

export default class RadioInput extends Component<Props> {
    state = {   
        currentValue: null,
        opened: null
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
        console.log(this.context)
    }

    onSelect = (option: any) => {
        this.props.onChange && this.props.onChange(option.value);
        this.setState({ currentValue: option.value });
    }

    render() {
        return (
            <div className={'radio-container '+(this.props.disabled ? 'disabled' : '')}>
                <label>{this.props.label}</label>
                <div className='options'>
                    {
                        this.props.options && this.props.options.map((x: any, i: number) => 
                        <div key={'radio_option_'+i+(new Date().getTime())} className='option' onClick={() => this.onSelect(x)}>
                            <span>{x.label}</span>
                            <input
                            name={this.props.name}
                            type='radio'
                            value={x.value}
                            defaultChecked={x.value === this.state.currentValue} />
                        </div>)
                    }
                </div>
            </div>
        )
    }
}

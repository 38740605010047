import { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'
import searchIcon from '../../../../assets/icons/search.png'
import filterIcon from '../../../../assets/icons/filter.png'
import storageIcon from '../../../../assets/icons/storage.png'
import plusIcon from '../../../../assets/icons/plus.png'
import editIcon from '../../../../assets/icons/edit.png'
import peopleIcon from '../../../../assets/icons/people.png'
import duplicateIcon from '../../../../assets/icons/duplicate.png'
import './styles.sass'
import { CandidateService } from '../../../../insfrastructure/services/candidates'
import { parseJwt } from '../../../../insfrastructure/utils/JWTParser'

class JobSubscriptions extends Component<RouterProps> {
  state = {
    user_data: {},
    usertype: '',
    offers: {
      data: [],
      count: 0
    },
    loading: false,
    search: ''
  }

  componentDidMount() {
    this.getUserData();
    this.listOffers({});
  }

  async getUserData() {
    const auth_token = localStorage.getItem('auth_token');
    if(!auth_token) return;

    const candidateService = new CandidateService();
            
    const candidate = await candidateService.retrieve();

    this.setState({ user_data: candidate, usertype: parseJwt(auth_token).profiles[0] });
  }

  listOffers = async (params: Record<string, any>) => {
    try {
      this.setState({ loading: true });

      const offers = {
        data: [
          {
            id: 1,
            name: 'Mecânico de Linha Pesada',
            location: 'São Paulo - SP',
            paymment: 'R$ 1.000,00'
          },
          {
            id: 2,
            name: 'Mecânico de Linha Pesada',
            location: 'São Paulo - SP',
            paymment: 'R$ 1.000,00'
          }
        ],
        count: 75
      };

      this.setState({ offers });
      
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 500);
    }
  }

  onSearch = async () => {
    this.listOffers({ search: this.state.search });
  }

  render() {
    return (
      <div className='job-subscriptions company-page'>
        <div className='block'>
            <div className='block-header'>
              <div className='title'>
                <img src={storageIcon} />
                <h3>Vagas</h3>
              </div>
              {this.state.usertype === 'company' &&
              <Link to='/offers/create'>
                <button className='primary add-btn'>
                  <img src={plusIcon} />
                  <span>Criar vaga</span>
                </button>
              </Link>}
            </div>
            <div className='general-vision'>
              <div className='data'>
                <h3>Visão Geral</h3>
                <h4>Vagas abertas: <b>2</b></h4>
                <h4>Vagas preenchidas: <b>0</b></h4>
              </div>
              <div className='graph-container'>
                <CircularChart percentage={!this.state.loading ? this.state.offers.count : 0} />
                <h4>Empregabilidade</h4>
              </div>
            </div>
            <div className='oportunities-searcher'>
              <img className='search-icon' src={searchIcon} onClick={this.onSearch} />
              <input type='text' placeholder='Pesquisar vaga' onChange={e => this.setState({ search: e.target.value })} />
              <img className='filter-icon' src={filterIcon} />
            </div>
            <div className='offers'>
              {this.state.loading && <p>Carregando...</p>}
              {!this.state.loading && this.state.offers.data.length === 0 && <p>Nenhuma vaga encontrada</p>}
              {!this.state.loading && this.state.offers.data.length > 0 &&
              <>
                <div className='job-results'>
                  <table cellSpacing={0}>
                    <thead>
                      <tr>
                        <th className='name'>Nome da vaga:</th>
                        <th>Data de publicação:</th>
                        <th>Status:</th>
                        {this.state.usertype === 'company' && <th>Candidatos:</th>}
                        {this.state.usertype === 'company' && <th className='see-candidates'>Ver candidatos</th>}
                        <th className='actions'>{this.state.usertype === 'company' ? 'Editar vaga' : 'Acessar vaga'}</th>
                        {this.state.usertype === 'company' && <th>Duplicar:</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.offers.data.map((offer, index) =>
                        <tr>
                          <td>Auxiliar de mecânico de auto</td>
                          <td>17/10/2022</td>
                          <td>
                            <span className='status open'>Aberta</span>
                          </td>
                          {this.state.usertype === 'company' && <td>3</td>}
                          {this.state.usertype === 'company' &&
                          <td>
                            <Link to={'/offers/'+1+'/candidates'}>
                              <img className='people-icon' src={peopleIcon} />
                            </Link>
                          </td>}
                          <td>
                            {this.state.usertype === 'candidate' && 
                            <Link to={'/offers/'+1+'/details'}>
                              <img className='edit-icon' src={editIcon} />
                            </Link>}
                            {this.state.usertype === 'company' && 
                            <Link to={'/offers/'+1}>
                              <img className='edit-icon' src={editIcon} />
                            </Link>}
                          </td>
                          {this.state.usertype === 'company' && 
                          <td>
                            <img className='duplicate-icon' src={duplicateIcon} />
                          </td>}
                        </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </>}
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(JobSubscriptions)

export const CircularChart = ({ percentage }: { percentage: number }) => {
  return (
    <svg width={100} height={100} viewBox="0 0 36 36">
      <path className="circle-bg" style={{
         fill: 'none',
         stroke: '#E6E6E6',
         strokeWidth: 2.5
      }}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#9FD67B"
        stroke-width="2.5"
        stroke-dasharray={`${percentage}, 100`}
        style={{ transition: '0.5s' }}
      />
      <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="8px" dy=".3em">{percentage}%</text>
    </svg>
  )
}

import { Location, NavigateFunction, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";

export interface RouterProps {
    location: Location<any>,
    context: any,
    params?: Record<string, any>,
    navigate: NavigateFunction
}
  
export interface Params {
    params: Record<string, any>
}
  
export function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      let context = useOutletContext();
      return (
        <Component
          {...props}
          location={location}
          params={params}
          context={context}
          navigate={navigate}
        />
      );
    }
  
    return ComponentWithRouterProp;
}
import React, { Component } from 'react'
import arrow from '../../../assets/icons/arrow.png'
import './styles.sass'

interface SelectOptions { 
  name: string,
  label: string,
  placeholder?: string,
  options: any[],
  readonly?: boolean,
  disabled?: boolean,
  value?: any,
  icon?: any,
  hasSearch?: boolean,
  onChange?: (value: any) => any,
  onSearchChange?: (value: any) => any
}

export class Select extends Component<SelectOptions> {
  ref: React.RefObject<HTMLInputElement>
  searchTimeout: any = null

  constructor(props: any) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    currentValue: this.props.value,
    opened: null
  }

  componentDidMount() {
    document.addEventListener('click', e => {
      if(this.props.disabled || this.props.readonly) return;

      const currentRef = this.ref.current as HTMLElement;
      const searchInput = currentRef?.querySelector('.search-input');

      if (searchInput?.contains(e.target as HTMLElement)) {
        return
      };

      const clickedInComponent = this.ref.current && (this.ref.current as HTMLElement).contains(e.target as HTMLElement);

      if(clickedInComponent) {
        this.setState({ opened: !this.state.opened }, () => {
          currentRef.style.zIndex = this.state.opened ? '10000' : '1' ;
        });
      } else {
        this.setState({ opened: false });
        if(currentRef) {
          currentRef.style.zIndex = '1';
        };
      };
    });
  }

  componentDidUpdate(prevProps: Readonly<SelectOptions>, prevState: Readonly<{}>, snapshot?: any): void {
    if(prevProps.value !== this.props.value) {
      this.setState({ currentValue: this.props.value });
    };
  }

  onSelect = (option: any) => {
    this.props.onChange && this.props.onChange(option.value);
    this.setState({ currentValue: option.value });
  }

  onSearch = (e: any) => {
    clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      this.props.onSearchChange && this.props.onSearchChange(e.target.value);
    }, 1000);
  }

  render() {
    console.log(this.props.options);
    
    return (
      <div ref={this.ref} className={'select-container '+(this.props.disabled ? 'disabled' : '')+(this.props.readonly ? 'readonly' : '')+(this.state.opened ? 'opened' : '')}>
        <label>{this.props.label}</label>
        <div className={'select-input-container '+(this.props.icon ? 'with-icon' : '')}>
          {this.props.icon && <img src={this.props.icon} className='icon' />}
          <input value={this.state.currentValue ? this.props.options.filter(x => x.value === this.state.currentValue)[0]?.label : ''} placeholder={this.props.placeholder} readOnly/>
          <img className='arrow' src={arrow} />
        </div>
        {this.state.opened && 
        <div className='options'>
          {
            this.props.hasSearch &&
            <input className='search-input' onChange={(e) => this.onSearch(e)} />
          }
          <ul>
            <li key={this.props.label+'_empty'} onClick={() => this.onSelect({ value: '' })}>-</li>
            {
              this.props.options.map((x: any, i: number) => 
              <li key={x.label+'_'+x.value} onClick={() => this.onSelect(x)}>{x.label}</li>)
            }
          </ul>
        </div>}
      </div>
    )
  }
}


// export const Select = ({ name, label, placeholder, value, options, disabled, onChange }: SelectOptions) => {
//     const[currentValue, setCurrentValue] = useState(value)
//     const [opened, toggleOpened] = useState(false)
//     const ref = useRef(null)

//     useEffect(() => {
//       document.addEventListener('click', e => {
//         if(disabled) return;

//         const clickedInComponent = ref.current && (ref.current as HTMLElement).contains(e.target as HTMLElement);

//         if(clickedInComponent) {
//           toggleOpened(!opened);
//         } else {
//           toggleOpened(false);
//         };
//       });
//     }, [])

//     const onSelect = (option: any) => {
//       onChange && onChange(option.value);
//       setCurrentValue(option.value);
//     }

//     return (
//       <div ref={ref} className={'select-container '+(disabled ? 'disabled' : '')}>
//         <label>{label}</label>
//         <span>{currentValue}</span>
//         {opened && 
//         <div className='options'>
//           <ul>
//             {
//               options.map((x, i) => 
//               <li key={new Date().getTime()} onClick={() => onSelect(x)}>{x.label}</li>)
//             }
//           </ul>
//         </div>}
//       </div>
//     )
// }
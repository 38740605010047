import { Component } from 'react'
import searchIcon from '../../../assets/icons/search.png'
import filterIcon from '../../../assets/icons/filter.png'
import './styles.sass'
import { Link } from 'react-router-dom'

export default class SearchInput extends Component {
    render() {
        return (
            <div className='oportunities-searcher'>
                <Link to='offers'><img className='search-icon' src={searchIcon} /></Link>
                <input type='text' placeholder='Pesquisar vaga' />
                <img className='filter-icon' src={filterIcon} />
            </div>
        )
    }
}

import { Component } from 'react'
import companyImg from '../../../../../../assets/images/company.png'
import { RouterProps, withRouter } from '../../../../../../insfrastructure/utils/with-router-component';
import './styles.sass'

class CompanyFormConfirmationSuccess extends Component<RouterProps> {
  render() {
    return (
      <div className='company-sign-up-form-confirmation-success'>
          <div className='content'>
            <div className='block'>
              <div>
                <h3>Parabéns!</h3>
                <p>Sua <b>conta</b> foi <b>ativada</b>!</p>
              </div>
              <img src={companyImg} />
              <div>
                <p>Comece agora a publicar suas <b>Vaga de Emprego</b>!</p>
              </div>
              <button className='primary' onClick={() => this.props.navigate('/login')}>Continuar</button>
            </div>
          </div>
      </div>
    )
  }
}

export default withRouter(CompanyFormConfirmationSuccess)
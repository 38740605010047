import React, { Component } from 'react'
import Modal from '../modal'
import warningIcon from '../../../assets/icons/warning.png'
import keepIcon from '../../../assets/icons/keep.png'
import deleteIcon from '../../../assets/icons/delete.png'
import './styles.sass'

interface ModalProps {
    render: boolean,
    title: string,
    text?: string,
    onConfirm: any,
    onClose: () => void
}  

export default class ConfirmationModal extends Component<ModalProps> {
  render() {
    return (
      <Modal render={this.props.render} onClose={() => this.props.onClose()}>
        <div className='modal-content'>
            <img src={warningIcon} className='warning-icon' />
            <h3>{this.props.title}</h3>
            {this.props.text && <p>{this.props.text}</p>}
            <div className='btns'>
                <button className='cancel' onClick={() => this.props.onClose()}>
                  <img src={keepIcon}/>
                  <span>Continuar exibindo</span>
                </button>
                <button className='confirm' onClick={() => this.props.onConfirm()}>
                  <img src={deleteIcon}/>
                  <span>Deletar</span>
                </button>
            </div>
        </div>
      </Modal>
    )
  }
}

import { Component } from 'react'
import candidateImg from '../../../../../../assets/images/candidate.png'
import './styles.sass'
import { RouterProps, withRouter } from '../../../../../../insfrastructure/utils/with-router-component';

class CandidateFormConfirmationSuccess extends Component<RouterProps> {
  render() {
    return (
      <div className='candidate-sign-up-form-confirmation-success candadite'>
          <div className='content'>
            <div className='block'>
              <div>
                <h3>Parabéns!</h3>
                <p>Sua <b>conta</b> foi <b>ativada</b>!</p>
              </div>
              <img src={candidateImg} />
              <div>
                <p>Comece agora a buscar sua <b>Vaga de Emprego</b>!</p>
              </div>
              <button className='secondary' onClick={() => this.props.navigate('/login')}>Continuar</button>
            </div>
          </div>
      </div>
    )
  }
}

export default withRouter(CandidateFormConfirmationSuccess)
import axios from "axios";
import { environment } from "../../environments/environment";
import { ListResponse } from "./utils";

export interface Expirence {
    id: number,
    company_name: string,
    position: string,
    start_date: string,
    end_date: string | null,
    description: string,
    candidate: string
}

export const listExpirences = async (candidate_id: any): Promise<ListResponse<Expirence>> => {
    const response = await axios.get<ListResponse<Expirence>>(environment.apiUrl+'/api/experience?candidate_id='+candidate_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
};

export const createExpirence = async (data: Expirence): Promise<ListResponse<Expirence>> => {
    const response = await axios.post<ListResponse<Expirence>>(environment.apiUrl+'/api/experience/', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 201) {
        throw Error('error');
    };

    return response.data;
};

export const updateExpirence = async (experience_id: number, data: Expirence): Promise<ListResponse<Expirence>> => {
    const response = await axios.put<ListResponse<Expirence>>(environment.apiUrl+'/api/experience/'+experience_id+'/', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
};


export const deleteExpirence = async (experience_id: number) => {
    const response = await axios.delete(environment.apiUrl+'/api/experience/'+experience_id+'/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 204) {
        throw Error('error');
    };
};

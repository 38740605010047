import axios from "axios";
import { environment } from "../../environments/environment";

export interface Postcode {
    cep: string,
    logradouro: string,
    complemento: string,
    bairro: string,
    localidade: string,
    uf: string,
    ibge: string,
    city_id: number
}

export class PostcodeService {
    auth_token = ''

    constructor() {
        this.auth_token = 'Bearer '+localStorage.getItem('auth_token');
    }

    retrieve = async (postcode: string) => {
        const response = await axios.get<Postcode>(environment.apiUrl+'/api/cep/'+postcode, {
            // headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': this.auth_token
            // }
        });
    
        if(response.status !== 200) {
            throw Error('error');
        };
    
        return response.data;
    };
}

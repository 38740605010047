import { Component } from 'react'
import { Input } from '../../../../components/input'
import { Select } from '../../../../components/select'
import CheckboxInput from '../../../../components/checkbox'
import { ErrorMessage, emptyField, errorsLength, formatErrors, searchCEP, validateCEP } from '../../../../../insfrastructure/utils/form-validators'
import pcdImg from '../../../../../assets/icons/pcd.png'
import locationImg from '../../../../../assets/icons/location.png'
import './styles.sass'

interface Props {
  inital_data?: any,
  onSubmit: (data: any) => void
}

export default class BasicInfo extends Component<Props> {
  state: any = {
    form: {

    },
    formErrors: {}
  }
  timeout: any = null

  componentDidMount() {
    if(this.props.inital_data) {
      this.setState({ form: this.props.inital_data });
    };
  }

  onSubmit = async () => {
    const errors = formatErrors({
      name: [emptyField(this.state.form.name, 'string')],
      type: [emptyField(this.state.form.type, 'string')],
      cep: [validateCEP(this.state.form.cep)],
      uf: [emptyField(this.state.form.uf, 'string')],
      city: [emptyField(this.state.form.city, 'string')],
      neighborhood: [emptyField(this.state.form.neighborhood, 'string')],
      street: [emptyField(this.state.form.street, 'string')],
      number: [emptyField(this.state.form.number, 'string')],
      description: [emptyField(this.state.form.type, 'string')]
    });

    if(errors['cep'].length === 0) {
      const result = await searchCEP(this.state.form.cep);
      if(result.erro === 'true') {
        errors.cep = ['CEP inválido'];
      };
    };

    this.setState({ formErrors: errors });

    if(errorsLength(errors) > 0) return;

    this.props.onSubmit(this.state.form);
  }

  onCEPChange = async (value: any) => {
    clearTimeout(this.timeout);
    
    this.timeout = setTimeout(async () => {
      let uf = '';
      let city = '';
      let neighborhood = '';
      let street = '';

      if(validateCEP(value) === '') {
        const result = await searchCEP(value);
        uf = result.uf;
        city = result.localidade;
        neighborhood = result.bairro;
        street = result.logradouro;
      };
  
      this.setState({
        form: {
          ...this.state.form,
          uf,
          city,
          neighborhood,
          street
        }
      });
    }, 500);
  }

  render() {
    return (
      <div className='basic-info'>
        <div className='columns'>
          <div className='column'>
            <Input
            name='name'
            label='Título'
            value={this.state.form.name}
            onChange={e => this.setState({ form: { ...this.state.form, name: e } })}/>
            <ErrorMessage errors={this.state.formErrors['name']} />
            <Select
            name='type'
            label='Tipo'
            value={this.state.form.type}
            options={[
              {
                label: 'Efetiva',
                value: 'Efetiva'
              },
              {
                label: 'Estágio',
                value: 'Estágio'
              },
              {
                label: 'Freelancer',
                value: 'Freelancer'
              },
              {
                label: 'Meio Período',
                value: 'Meio Período'
              },
              {
                label: 'Temporária',
                value: 'Temporária'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, type: e } })}/>
            <ErrorMessage errors={this.state.formErrors['type']} />
            <div className='location-section'>
              <div className='location-title'>
                <img src={locationImg}/>
                <h4>Localização</h4>
              </div>
              <Input
              name='cep'
              label='CEP'
              mask='99999-999'
              value={this.state.form.cep}
              onChange={e => { this.setState({ form: { ...this.state.form, cep: e } }); this.onCEPChange(e); }}/>
              <ErrorMessage errors={this.state.formErrors['cep']} />
              <div className='address-info'>
                <p>Rua: <b>{this.state.form.street ? this.state.form.street : '-'}</b></p>
                <p>Bairro: <b>{this.state.form.neighborhood ? this.state.form.neighborhood : '-'}</b></p>
                <p>Cidade: <b>{this.state.form.city ? this.state.form.city : '-'}</b></p>
                <p>UF: <b>{this.state.form.uf ? this.state.form.uf : '-'}</b></p>
              </div>
              <div className='columns'>
                <div className='column'>
                  <Input
                  name='number'
                  label='Número'
                  value={this.state.form.number}
                  onChange={e => this.setState({ form: { ...this.state.form, number: e } })}/>
                  <ErrorMessage errors={this.state.formErrors['number']} />
                </div>
                <div className='column'>
                  <Input
                  name='complement'
                  label='Complemento'
                  value={this.state.form.complement}
                  onChange={e => this.setState({ form: { ...this.state.form, complement: e } })}/>
                </div>
              </div>
            </div>
            <CheckboxInput
            name='id_pcd_offer'
            single={true}
            value={this.state.form.id_pcd_offer}
            options={[
              {
                label: () => 
                <div className='pcd-check'>
                  <p>Vaga para Pessoa com Deficiência (PcD)</p>
                  <img src={pcdImg}/>                  
                </div>,
                value: 'id_pcd_offer'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, id_pcd_offer: e } })}/>
            <Input
            name='qtd'
            type='number'
            label='Quantidade de vagas'
            value={this.state.form.qtd}
            onChange={e => this.setState({ form: { ...this.state.form, qtd: e } })}/>
          </div>
          <div className='column descripiton-column'>
            <Input
            name='description'
            type='textarea'
            label='Descrição'
            value={this.state.form.description}
            onChange={e => this.setState({ form: { ...this.state.form, description: e } })}/>
            <ErrorMessage errors={this.state.formErrors['description']} />
          </div>
        </div>
        <button className='primary' onClick={this.onSubmit}>Confirmar</button>
      </div>
    )
  }
}

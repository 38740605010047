import { Component } from 'react'
import './styles.sass'
import { Input } from '../../../../../components/input';

interface Props {
    name: string,
    label?: string,
    options: any[],
    disabled?: boolean,
    readonly?: boolean,
    single?: boolean,
    value?: [],
    onChange?: (value: any) => any
}

export default class BenefitsList extends Component<Props> {
    state: any = {   
        currentValue: [],
        options: [],
        opened: null
    }

    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.setState({ options: this.props.options });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.value !== this.props.value) {
            let currentValue: any = this.props.value;

            if(this.props.single) {
                currentValue = this.props.value ? [this.props.value] : [];
            };

            this.setState({ currentValue });
        };
    }

    onSelect = (option: any) => {
        let initalCurrentValue = JSON.parse(JSON.stringify([...this.state.currentValue]));

        let currentValue = 
        this.state.currentValue.map((x: any) => x.value).includes(option.value) ?
        initalCurrentValue.filter((x: any) => x.value !== option.value) :
        [...initalCurrentValue, option];

        this.setState({ currentValue }, () => {
            this.props.onChange && this.props.onChange(this.state.currentValue);
        });
    }

    inputing: any = null

    onChangeExtraInput = (option: any, input_index: number, e: any) => {
        clearTimeout(this.inputing);
        
        let selected_option_index = this.state.currentValue.map((x: any) => x.value).indexOf(option.value);
        
        if(selected_option_index < 0) return;

        let initalCurrentValue = JSON.parse(JSON.stringify([...this.state.currentValue]));
        
        initalCurrentValue[selected_option_index].inputs[input_index].value = e;

        this.inputing = setTimeout(() => {
            this.setState({ currentValue: initalCurrentValue }, () => {
                this.props.onChange && this.props.onChange(this.state.currentValue);
            });   
        }, 500);
    }

    getCurrentIndexValue = (option: any, input_index: number) => {
        const selectedOption = this.state.currentValue.filter((x: any) => x.value === option.value)[0];
        if(!selectedOption) {
            return option.inputs[input_index].value;
        };

        return selectedOption.inputs[input_index].value;
    }

    render() {
        return (
            <div className={'benefits-list '+(this.props.disabled ? 'disabled' : '')}>
                {this.props.label && <label>{this.props.label}</label>}
                <div className='options'>
                    {
                        this.props.options && this.props.options.map((x: any, i: number) => 
                        <div key={'radio_option_'+i+(new Date().getTime())} className='option'>
                            <div className='input-container' onClick={() => this.onSelect(x)}>
                                <input
                                name={this.props.name+' '+x.value}
                                type='checkbox'
                                value={x.value}
                                disabled={this.props.disabled}
                                checked={this.state.currentValue.map((v: any) => v.value).includes(x.value)}
                                readOnly />
                                {typeof x.label == 'string' ? <span>{x.label}</span> : x.label()}
                            </div>
                            {x.inputs && 
                            <div className='extra-inputs'>
                                {
                                    x.inputs.map((input: any, input_index: number) =>
                                    <Input
                                    key={x.value+'_input_'+input_index}
                                    name={x.value+'_input_'+input_index}
                                    label={input.label}
                                    type={input.type}
                                    value={this.getCurrentIndexValue(x, input_index)}
                                    disabled={!this.state.currentValue.map((v: any) => v.value).includes(x.value)}
                                    onChange={e => this.onChangeExtraInput(x, input_index, e)}
                                    />)
                                }
                            </div>}
                        </div>)
                    }
                </div>
            </div>
        )
    }
}

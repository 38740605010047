import { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from '../../../../insfrastructure/utils/with-router-component'
import searchIcon from '../../../../assets/icons/search.png'
import filterIcon from '../../../../assets/icons/filter.png'
import infoIcon from '../../../../assets/icons/info.png'
import storageIcon from '../../../../assets/icons/storage.png'
import checkedIcon from '../../../../assets/icons/checked.png'
import calendarBlueIcon from '../../../../assets/icons/calendar-blue.png'
import calendarWhiteIcon from '../../../../assets/icons/calendar-white.png'
import deleteIcon from '../../../../assets/icons/delete.png'
import './styles.sass'

class AllCandidates extends Component<any> {
  state: any = {
    offers: {
      data: [],
      count: 0
    },
    loading: false,
    search: ''
  }

  componentDidMount() {
    this.listOffers({});
  }

  listOffers = async (params: Record<string, any>) => {
    try {
      this.setState({ loading: true });

      const candidates = {
        data: [
          {
            id: 1,
            name: 'Mecânico de Linha Pesada',
            status: '1'
          },
          {
            id: 2,
            name: 'Mecânico de Linha Pesada',
            status: '2'
          }
        ],
        count: 75
      };

      const offers = {
        data: [
          {
            name: 'Vaga 1',
            candidates: candidates,
          },
          {
            name: 'Vaga 2',
            candidates: candidates,
          }
        ],
        count: 10
      };

      this.setState({ offers });
      
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 500);
    }
  }

  onSearch = async () => {
    this.listOffers({ search: this.state.search });
  }

  render() {
    return (
      <div className='all-candidates company-page'>
        <div className='block'>
            <div className='block-header'>
              <div className='title'>
                <img src={storageIcon} />
                <h3>Todos Candidatos</h3>
              </div>
            </div>
            <div className='general-vision'>
              <div className='data'>
                <h3>Visão Geral</h3>
                <h4>Candidatos: <b>2</b></h4>
                <h4>Atendidos: <b>0</b></h4>
              </div>
              <div className='graph-container'>
                <CircularChart percentage={!this.state.loading ? this.state.offers.count : 0} />
                <h4>Atendidos</h4>
              </div>
            </div>
            <div className='searcher'>
              <img className='search-icon' src={searchIcon} onClick={this.onSearch} />
              <input type='text' placeholder='Pesquisar vaga' onChange={e => this.setState({ search: e.target.value })} />
              <img className='filter-icon' src={filterIcon} />
            </div>
            <div className='candidates'>
              {this.state.loading && <p>Carregando...</p>}
              {!this.state.loading && this.state.offers.data.length === 0 && <p>Nenhuma vaga encontrada</p>}
              {!this.state.loading && this.state.offers.data.length > 0 &&
              <>
                <div className='results'>
                  <table cellSpacing={0}>
                    <thead>
                      <tr>
                        <th className='name'>Nome do candidato:</th>
                        <th className='basic-info'>Dados básicos:</th>
                        <th className='pcd'>PcD?</th>
                        <th className='status'>Status:</th>
                        <th className='status'>Adesão:</th>
                        <th className='actions'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.offers.data.map((offer: any, index: number) =>
                        <>
                        <tr className='offer-row'>
                          <td colSpan={6}>
                            <div>
                              <span className='name'>{offer.name}</span>
                              <span>{offer.candidates.count} candidato{offer.candidates.count > 1 ? 's' : ''}</span>
                            </div>
                          </td>
                        </tr>
                          {
                            offer.candidates.data.map((candidate: any, index: number) =>
                            <tr>
                              <td>Fernado Alvez</td>
                              <td><p>Solteiro, 23 anos</p><p>Belo Horizonte, MG</p></td>
                              <td>Não</td>
                              <td>
                                <span>Se candidatou:<br/> 14/10/2022</span>
                              </td>
                              <td>
                                <span>68%</span>
                              </td>
                              <td>
                                <div className='actions'>
                                  {['1', '2', '3', '4'].includes(candidate.status) &&
                                  <Link to={'/candidates/'+candidate.id}>
                                    <button className='access'>
                                      <img src={infoIcon} />
                                      <span>Acessar</span>
                                    </button>
                                  </Link>}
                                  {['1', '2', '3'].includes(candidate.status) &&
                                  <button className='remove'>
                                    <img src={deleteIcon} />
                                    <span>Remover</span>
                                  </button>}
                                  {['1'].includes(candidate.status) &&
                                  <button className='call primary'>
                                    <img src={calendarWhiteIcon} />
                                    <span>Chamar para entrevista</span>
                                  </button>}
                                  {['2'].includes(candidate.status) &&
                                  <button className='call-again'>
                                    <img src={calendarBlueIcon} />
                                    <span>Chamar novamente</span>
                                  </button>}
                                  {['3'].includes(candidate.status) &&
                                  <button className='hire'>
                                    <img src={checkedIcon} />
                                    <span>Contratar</span>
                                  </button>}
                                  {['4'].includes(candidate.status) &&
                                  <span className='hired'>
                                    <span>Contratado</span>
                                  </span>}
                                </div>
                              </td>
                            </tr>
                            )
                          }
                        </>)
                      }
                    </tbody>
                  </table>
                </div>
              </>}
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AllCandidates)

export const CircularChart = ({ percentage }: { percentage: number }) => {
  return (
    <svg width={100} height={100} viewBox="0 0 36 36">
      <path className="circle-bg" style={{
         fill: 'none',
         stroke: '#E6E6E6',
         strokeWidth: 2.5
      }}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#9FD67B"
        stroke-width="2.5"
        stroke-dasharray={`${percentage}, 100`}
        style={{ transition: '0.5s' }}
      />
      <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="8px" dy=".3em">{percentage}%</text>
    </svg>
  )
}

import { Component } from 'react'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../../insfrastructure/utils/form-validators'
import { Input } from '../../../../components/input'
import addImg from '../../../../../assets/icons/add.png'
import './styles.sass'
import { Select } from '../../../../components/select'
import { MultiSelect } from '../../../../components/multi-select'

interface Props {
  inital_data?: any,
  onSubmit: (data: any) => void
}

export default class Requirements extends Component<Props> {
  state: any = {
    form: {
      schoolarship: null,
      courses: [],
      experience: null,
      habilities: []
    },
    formErrors: {},
    schoolarship_options: [
      {
        label: 'Ensino fundamental completo',
        value: 'Ensino fundamental completo'
      },
      {
        label: 'Ensino médio completo',
        value: 'Ensino médio completo'
      },
      {
        label: 'Curso técnico',
        value: 'Curso técnico'
      }
    ], 
    courses_options: [], 
    experiences_options: [
      {
        label: 'Sem experiência',
        value: 'Sem experiência'
      },
      {
        label: 'Experiência de até 1 ano',
        value: 'Experiência de até 1 ano'
      },
      {
        label: 'Experiência entre 1 e 2 anos',
        value: 'Experiência entre 1 e 2 anos'
      },
      {
        label: 'Experiência entre 2 e 3 anos',
        value: 'Experiência entre 2 e 3 anos'
      },
      {
        label: 'Experiência de mais de 3 anos',
        value: 'Experiência de mais de 3 anos'
      }
    ], 
    habilities_options: [], 
    show_new_course_form: false,
    new_course: {},
    new_course_form_errors: {},
    show_new_hability_form: false,
    new_hability: {},
    new_hability_form_errors: {}
  }

  componentDidMount() {
    if(this.props.inital_data) {
      this.setState({ form: this.props.inital_data });
    };
  }

  toggleCourseForm = () => {
    this.setState({ show_new_course_form: !this.state.show_new_course_form, new_course: {} });
  }

  onSubmitCourse = () => {
    const errors = formatErrors({
      name: [emptyField(this.state.new_course.name, 'string')]
    });

    this.setState({ new_course_form_errors: errors });

    if(errorsLength(errors) > 0) return;

    this.setState({ 
      courses_options: [ ...this.state.courses_options, { label: this.state.new_course.name, value: this.state.new_course.name } ], 
      show_new_course_form: false,
      new_course: null
    });
  }

  toggleHabilityForm = () => {
    this.setState({ show_new_hability_form: !this.state.show_new_hability_form, new_hability: {} });
  }

  onSubmitHability = () => {
    const errors = formatErrors({
      name: [emptyField(this.state.new_hability.name, 'string')]
    });

    this.setState({ new_hability_form_errors: errors });

    if(errorsLength(errors) > 0) return;

    this.setState({ 
      habilities_options: [ ...this.state.habilities_options, { label: this.state.new_hability.name, value: this.state.new_hability.name } ], 
      show_new_hability_form: false,
      new_hability: null
    });
  }

  onSubmit = () => {
    // const errors = formatErrors({
    // });

    // this.setState({ formErrors: errors });

    // if(errorsLength(errors) > 0) return;

    this.props.onSubmit(this.state.form);
  }

  render() {
    return (
      <div className='requirements'>
        <div className='columns'>
          <div className='column'>
            <div>
              <Select
              name='schoolarship'
              label='Escolaridade'
              value={this.state.form.schoolarship}
              options={this.state.schoolarship_options}
              onChange={e => this.setState({ form: { ...this.state.form, schoolarship: e } })}/>
            </div>
            <div className='requirement-section'>
              <MultiSelect
              name='courses'
              label='Cursos'
              value={this.state.form.courses}
              options={this.state.courses_options}
              onChange={e => this.setState({ form: { ...this.state.form, courses: e } })}/>
              {this.state.show_new_course_form &&
              <div className='course-form'>
                <Input
                name='name'
                label='Nome'
                onChange={e => this.setState({ new_course: { ...this.state.new_course, name: e } })}/>
                <ErrorMessage errors={this.state.new_course_form_errors['name']} />
                <div className='columns'>
                  <button className='secondary' onClick={this.onSubmitCourse}>Salvar</button>
                  <button className='cancel' onClick={this.toggleCourseForm}>Cancelar</button>
                </div>
              </div>}
              {!this.state.show_new_course_form &&
              <button className='secondary toggle-course-form' onClick={this.toggleCourseForm}>
                <img src={addImg} />
                <span>Adicionar Curso</span>
              </button>}
            </div>
            <div>
              <Select
              name='experience'
              label='Experiência'
              value={this.state.form.experience}
              options={this.state.experiences_options}
              onChange={e => this.setState({ form: { ...this.state.form, experience: e } })}/>
            </div>
          </div>
          <div className='column'>
            <div className='requirement-section'>
              <MultiSelect
              name='habilities'
              label='Habilidades'
              value={this.state.form.habilities}
              options={this.state.habilities_options}
              onChange={e => this.setState({ form: { ...this.state.form, habilities: e } })}/>
              {this.state.show_new_hability_form &&
              <div className='course-form'>
                <Input
                name='name'
                label='Nome'
                onChange={e => this.setState({ new_hability: { ...this.state.new_hability, name: e } })}/>
                <ErrorMessage errors={this.state.new_hability_form_errors['name']} />
                <div className='columns'>
                  <button className='secondary' onClick={this.onSubmitHability}>Salvar</button>
                  <button className='cancel' onClick={this.toggleHabilityForm}>Cancelar</button>
                </div>
              </div>}
              {!this.state.show_new_hability_form &&
              <button className='secondary toggle-course-form' onClick={this.toggleHabilityForm}>
                <img src={addImg} />
                <span>Adicionar Habilidade</span>
              </button>}
            </div>
          </div>
        </div>
        <button className='primary' onClick={this.onSubmit}>Confirmar</button>
      </div>
    )
  }
}

const RequirementSelector = () => {
  return (
    <div>RequirementSelector</div>
  )
}

import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../../assets/images/logo.svg'
import SearchInput from '../../search-input'
import './styles.sass'
import { CandidateService } from '../../../../insfrastructure/services/candidates'
import { parseJwt } from '../../../../insfrastructure/utils/JWTParser'


export const MobileHeader = () => {
    const [openedMenu, setOpenedMenu] = useState<boolean>(false)
  
    useEffect(() => {
      document.addEventListener('click', e => {
        const target = e.target as HTMLElement;
        const menuBtn = document.querySelector('.menu-btn') as HTMLElement;
        const searchInput = document.querySelector('.oportunities-searcher') as HTMLElement;
  
        if(searchInput && searchInput.contains(target) && target.className !== 'search-icon') return;
  
        if(menuBtn && menuBtn.contains(target)) {
          setOpenedMenu(!menuBtn.classList.contains('opened'));
        } else {
          setOpenedMenu(false);
        };
      });
    }, [])
  
    return (
      <header className='mobile'>
        <div className={'menu-btn '+(openedMenu ? 'opened' : '')}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <Link to=''><img className='logo' src={logo} /></Link>
        <Menu openedMenu={openedMenu} />
      </header>
    )
}
  
const Menu = ({ openedMenu }: { openedMenu: boolean }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [userData, setUserData] = useState<any>(null)
    const [userType, setUserType] = useState<any>(null)
    
    const usertypes: any = {
      'candidate': 'Candidato',
      'company': 'Empresa'
    }

    useEffect(() => {
      getUserdata();
    }, [location])

    const getUserdata = async () => {
      const auth_token = localStorage.getItem('auth_token');
      if(!auth_token) return;

      try {
        const candidateService = new CandidateService();
              
        const candidate = await candidateService.retrieve();
  
        setUserData(candidate);
        setUserType(parseJwt(auth_token).profiles[0]);
      } catch (error) {}
    }
  
    const onLogout = () => {
      localStorage.clear();
      sessionStorage.clear();
      navigate('login');
    }
  
    return (
      <div className={'menu '+(openedMenu ? 'opened' : '')}>
        {
          userData &&
          <div className='user-data'>
            <div>
              <h4>Olá, <b>{userData.email}</b></h4>
              <p>{usertypes[userData.usertype]}</p>
            </div>
            <div className='profile-picture-container'>
              {userData.picture && <img src={userData.picture} />}
            </div>
          </div>
        }
        {!userData &&
        <ul className='unlogged-links'>
          <Link to='sign-up'><li>Cadastro</li></Link>
          <Link to='login'><li>Entre</li></Link>
        </ul>}
        {userType === 'candidate' &&
        <SearchInput />}
        <ul className='home-links'>
          <Link to='how-to-anounce'>
            <li>Como anunciar</li>
          </Link>
          <Link to='about-us'>
            <li>Sobre nós</li>
          </Link>
          <Link to='success-stories'>
            <li>Histórias</li>
          </Link>
        </ul>
        {userType === 'candidate' &&
        <ul className='offer-links'>
          <Link to='profile/candidate/subscriptions'>
            <li>
              <span>Vagas salvas</span>
            </li>
          </Link>
          <Link to='job-subscriptions'>
            <li>
              <span>Candidaturas</span>
            </li>
          </Link>
        </ul>}
        {userType === 'company' &&
        <ul className='offer-links'>
          <Link to='offers/create'>
            <li>
              <span>Cadastrar vagas</span>
            </li>
          </Link>
          <Link to='offers'>
            <li>
              <span>Minhas vagas</span>
            </li>
          </Link>
        </ul>}
        {
          userData &&
          <ul>
            <Link to='profile/candidate/person'><li>Meu perfil</li></Link>
            <li onClick={onLogout}>Sair</li>
          </ul>
        }
      </div>
    )
}
  
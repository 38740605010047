import { Component } from 'react'
import BasicInfo from './basic-info'
import Benefits from './benefits'
import Requirements from './requirements'
import Summary from './summary'
import Slider from './slider'
import './styles.sass'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'

class JobOffer extends Component<RouterProps> {

  state: any = {
    current_slide: 1
  }

  onChangeStep = (current_slide: number, form_name: string, form_data: Record<string, any>) => {
    this.setState({ current_slide: this.state.in_summary ? 4 : current_slide, [form_name]: form_data });
  }

  changeSection = (current_slide: number) => {
    this.setState({ current_slide, in_summary: true });
  }

  onSubmit = () => {
    try {
      this.setState({ loading: true });
        setTimeout(() => {
          this.setState({ loading: false });
        }, 200);
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='job-offer company-page'>
        <div className='block'>
            <h3>{!this.props.params?.offer_id ? 'Adicionar Nova Vaga' : 'Editar Vaga'}</h3>
            <Slider currentSlide={this.state.current_slide} />
            { this.state.current_slide === 1 && <BasicInfo inital_data={this.state.basic_info} onSubmit={(basic_info) => this.onChangeStep(2, 'basic_info', basic_info)} /> }
            { this.state.current_slide === 2 && <Benefits inital_data={this.state.benefits} onSubmit={(benefits) => this.onChangeStep(3, 'benefits', benefits)} /> }
            { this.state.current_slide === 3 && <Requirements inital_data={this.state.requirements} onSubmit={(requirements) => this.onChangeStep(4, 'requirements', requirements)} /> }
            { this.state.current_slide === 4 &&
            <Summary
            basic_info={this.state.basic_info}
            benefits={this.state.benefits}
            requirements={this.state.requirements}
            changeSection={index => this.changeSection(index)}
            onSubmit={this.onSubmit}/> }
        </div>
      </div>
    )
  }
}

export default withRouter(JobOffer)
import { Component } from 'react'
import { Input } from '../../../../components/input'
import { SubmitBtn } from '../../../../components/submit'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../../insfrastructure/utils/form-validators'
import { Select } from '../../../../components/select'
import './styles.sass'
import ConfirmationModal from '../../../../components/confimation-modal'

export default class JobTitleInterestsForm extends Component {

  state: any = {
    experience_items: [
      {
        item: {
          id: 1,
          email: 'aaaaaa'
        },
        editing: false
      }
    ]
  }

  editExperience = (id: number) => {
    const items = [...this.state.experience_items].map(x => {
      if(x.item.id === id) {
        x.editing = true;
      };
    
      return x;
    });

    this.setState({ experience_items: items });
  }

  addExperience = () => {
    const items = [...this.state.experience_items];
    items.push({
      item: {
        id: this.state.experience_items.length+1
      },
      editing: true
    });

    this.setState({ experience_items: items });
  }

  onSubmitExperience = (x: any) => {
    console.log(x, this.state.experience_items)
    const items = [...this.state.experience_items].map(y => {
      if(y.item.id === x.id) {
        y.item = x;
        y.editing = false;
      };
    
      return y;
    });

    this.setState({ experience_items: items });
  }

  removeExperience = (id: number) => {
    const items = this.state.experience_items.filter((x: any) => x.item.id !== id);

    this.setState({ experience_items: items });
  }

  render() {
    return (
      <div className='job-title-interests-form'>
        <h1>Cargos de Interesse</h1>
        <div className='job-titles'>
          {
            this.state.experience_items.length > 0 ?
            this.state.experience_items.map((x: any) =>
            <JobTitleForm
            formData={x.item}
            readonly={!x.editing}
            onAdd={this.onSubmitExperience}
            onUpdate={this.editExperience}
            onRemove={i => this.removeExperience(i)} />) :
            <div className='empty-list'>
              <p>Nenhuma cargo de interesse adicionado</p>
            </div>
          }
        </div>
        <SubmitBtn className='secondary' text='Adicionar Interesse de Vaga' onSubmit={this.addExperience} />
      </div>
    )
  }
}

class JobTitleForm extends Component<{formData: any, onAdd: (x: any) => any, onUpdate: (x: any) => any, onRemove: (i: number) => void, readonly: boolean}> {
  state: any = {
    form: {
      email: '',
      password:  ''
    },
    formErrors: {},
    loading: false
  }

  componentDidMount() {
    if(this.props.formData) {
      this.setState({ form: this.props.formData });
    };
  }

  onSubmit = async () => {
    const errors = formatErrors({
      job_title: [emptyField(this.state.form.job_title, 'job_title')]
    });

    this.setState({ formErrors: errors });
  
    if(errorsLength(errors) > 0) return;

    this.setState({ loading: true });

    try {
        this.props.onAdd(this.state.form);
        
        setTimeout(() => {
            this.setState({ loading: false });
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  onRemoveInterest = async () => {
    this.setState({ loading: true });
    this.setState({ selectedItemToDelete: null });

    try {
        
        setTimeout(() => {
          this.setState({ loading: false });
          this.props.onRemove(this.state.form.id as number);
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='form'>
        <div className='columns-group'>
          <div className='full-width-column'>
            <Input
            name='job_title'
            label='Cargo Desejado'
            placeholder='Cargo Desejado'
            value={this.state.form['job_title']}
            readonly={this.props.readonly}
            onChange={e => this.setState({ form: { ...this.state.form, job_title: e } })}/>
            <ErrorMessage errors={this.state.formErrors['job_title']} />
          </div>
          <div className='full-width-column'>
            <Input
            name='name'
            type='textarea'
            label='Descrição'
            value={this.state.form['description']}
            placeholder='Descrição'
            readonly={this.props.readonly}
            onChange={e => this.setState({ form: { ...this.state.form, description: e } })}/>
          </div>
        </div>
        <div className='btns'>
          {!this.props.readonly &&
          <SubmitBtn className='secondary' text='Salvar Alterações' loadingText='Salvando...' loading={this.state.loading} onSubmit={this.onSubmit} />}
          {this.props.readonly &&
          <SubmitBtn className='secondary' text='Editar' onSubmit={() => this.props.onUpdate(this.state.form['id'])} />}
          {this.props.readonly &&
          <SubmitBtn className='secondary' text='Remover' loadingText='Removendo...' loading={this.state.loading} onSubmit={() => this.setState({ selectedItemToDelete: true })} />}
        </div>
        <ConfirmationModal
        title='Deletar esse cargo de interesse?'
        text='Tem certeza que deseja deletar esse cargo de interesse? Nós não mostraremos ele novamente para você.'
        render={this.state.selectedItemToDelete}
        onConfirm={this.onRemoveInterest}
        onClose={() => this.setState({ selectedItemToDelete: null })}/>
      </div>
    )
  }
}


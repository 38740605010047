import './styles.sass'

const Slider = ({ currentSlide }: { currentSlide: number }) => {

  const slideClass = (slideIndex: number) => {
    let className = 'slide';

    if (slideIndex === currentSlide) {
      className += ' current';
    } else if (slideIndex < currentSlide) {
      className += ' checked';
    };

    return className;
  }

  return (
    <div className='slider'>
      <div className={slideClass(1)}>
        <span className='number'>1</span>
        <span className='slide-name'>Dados básicos</span>
      </div>
      <span className={'spacer '+(currentSlide > 1 ? 'checked' : '')}></span>
      <div className={slideClass(2)}>
        <span className='number'>2</span>
        <span className='slide-name'>Salário e benfícios</span>
      </div>
      <span className={'spacer '+(currentSlide > 2 ? 'checked' : '')}></span>
      <div className={slideClass(3)}>
        <span className='number'>3</span>
        <span className='slide-name'>Requisitos</span>
      </div>
      <span className={'spacer '+(currentSlide > 3 ? 'checked' : '')}></span>
      <div className={slideClass(4)}>
        <span className='number'>4</span>
        <span className='slide-name'>Resumo</span>
      </div>
    </div>
  )
}

export default Slider;
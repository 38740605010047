import { Component } from 'react'
import { Input } from '../../../../components/input'
import { Select } from '../../../../components/select'
import { SubmitBtn } from '../../../../components/submit'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../../insfrastructure/utils/form-validators'
import ConfirmationModal from '../../../../components/confimation-modal'
import CheckboxInput from '../../../../components/checkbox'
import { Course, createCourse, deleteCourse, listCourses, updateCourse } from '../../../../../insfrastructure/services/courses'
import { StaticValues } from '../../../../../insfrastructure/services/static_values'
import './styles.sass'
import ResponseModal from '../../../../components/response-modal'

export default class Scholarships extends Component {

  state: any = {
    scholarship_items: [],
    em_units: []
  }

  async componentDidMount() {
    try {
      const courses = await listCourses(localStorage.getItem('candidate_id'));

      const scholarship_items = courses.results.map(item => {    
        return {
          item,
          editing: false
        };
      });

      this.setState({ scholarship_items });
    } catch (error) {}

    this.getStaticValues();
  }

  getStaticValues = async () => {
    try {  
      const staticValues = JSON.parse(localStorage.getItem('static_values')+'') as StaticValues;
  
      const em_units = Object.values(staticValues.em_unit).map((x: any) => ({ value: x.branch_number, label: x.name }));
  
      this.setState({ em_units });
    } catch (error) {}
  }

  editScholarship = (id: number) => {
    const items = [...this.state.scholarship_items].map(x => {
      if(x.item.id === id) {
        x.editing = true;
      };
    
      return x;
    });

    this.setState({ scholarship_items: items });
  }

  addScholarship = () => {
    const items = [...this.state.scholarship_items];
    items.push({
      item: {
        id: 0
      },
      editing: true
    });

    this.setState({ scholarship_items: items });
  }

  onSubmitScholarship = async (x: any, index: number) => {
    try {
      if (typeof x.start_date === 'object') {
        const offset = x.start_date.getTimezoneOffset()
        x.start_date = new Date(x.start_date.getTime() - (offset*60*1000))
        x.start_date = x.start_date.toISOString().split('T')[0]
      };
  
      if (typeof x.end_date === 'object') {
        const offset = x.end_date.getTimezoneOffset()
        x.end_date = new Date(x.end_date.getTime() - (offset*60*1000))
        x.end_date = x.end_date.toISOString().split('T')[0]
      };
  
      const newItem = x.id === 0 ? await createCourse(x) : await updateCourse(x.id, x);
  
      const items = [...this.state.scholarship_items].map((y, i) => {
        if(i === index) {
          y.item = newItem;
          y.editing = false;
        };
      
        return y;
      });

      let responseData = {
        type: 'success',
        title: 'Dados salvos com sucesso!',
        text: () => <></>
      };

      setTimeout(() => {
        this.setState({ loading: false, scholarship_items: items, responseData });
      });
    } catch (error) {
      let responseData = {
        type: 'error',
        title: 'Ocorreu um erro inesperado',
        text: () => <></>
      };

      this.setState({ loading: false, responseData });
    }
  }

  removeScholarship = async (id: number) => {
    try {
      await deleteCourse(id);

      const items = this.state.scholarship_items.filter((x: any) => x.item.id !== id);

      let responseData = {
        type: 'success',
        title: 'Formação removida com sucesso!',
        text: () => <></>
      };

      setTimeout(() => {
        this.setState({ loading: false, scholarship_items: items, responseData });
      });
    } catch (error) {
      let responseData = {
        type: 'error',
        title: 'Ocorreu um erro inesperado',
        text: () => <></>
      };

      this.setState({ loading: false, responseData });
    }
  }

  render() {
    return (
      <div className='scholarship-form'>
        <h1>Formação</h1>
        <div className='scholarships'>
          {
            this.state.scholarship_items.length > 0 ?
            this.state.scholarship_items.map((x: any, i: number) =>
            <ScholarshipForm
            formData={x.item}
            readonly={!x.editing}
            em_units={this.state.em_units}
            onSubmit={(e) => this.onSubmitScholarship(e, i)}
            onUpdate={(e) => this.editScholarship(e)}
            onRemove={i => this.removeScholarship(i)} />) :
            <div className='empty-list'>
              <p>Nenhuma formação adicionada</p>
            </div>
          }
        </div>
        <SubmitBtn className='secondary' text='Adicionar Formação' onSubmit={this.addScholarship} />
        <ResponseModal
        render={this.state.responseData}
        type={this.state.responseData?.type}
        title={this.state.responseData?.title}
        text={this.state.responseData?.text}
        onClose={() => this.setState({ responseData: null })} />
      </div>
    )
  }
}

class ScholarshipForm extends Component<{formData: Course, em_units: any[], onSubmit: (x: any) => any, onUpdate: (x: any) => any, onRemove: (i: number) => void, readonly: boolean}> {
  state: any = {
    form: {},
    formErrors: {},
    loading: false
  }

  componentDidMount() {
    if(this.props.formData) {
      this.setState({ form: this.props.formData });
    };
  }

  componentDidUpdate(prevProps: Readonly<{ formData: Course; em_units: any[]; onSubmit: (x: any) => any; onUpdate: (x: any) => any; onRemove: (i: number) => void; readonly: boolean }>, prevState: Readonly<{}>, snapshot?: any): void {
    if(prevProps.formData && this.props.formData.id !== prevProps.formData.id) {
      this.setState({ form: this.props.formData });
    };
  }

  onSubmit = async () => {
    const errors = formatErrors({
      course_name: [emptyField(this.state.form.course_name, 'string')]
    });

    this.setState({ formErrors: errors });
  
    if(errorsLength(errors) > 0) return;

    this.setState({ loading: true });

    try {
        this.props.onSubmit(this.state.form);
        
        setTimeout(() => {
            this.setState({ loading: false });
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  onRemoveScholarship = async () => {
    this.setState({ loading: true });
    this.setState({ selectedItemToDelete: null });

    try {
        
        setTimeout(() => {
          this.setState({ loading: false });
          this.props.onRemove(this.state.form.id as number);
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='form'>
        <div className='columns-group'>
          <div className='columns'>
            <div className='column'>
              <CheckboxInput
              name='is_studant'
              single={true}
              value={this.state.form['is_studant']}
              disabled={this.props.readonly}
              options={[
                {
                  label: 'Escola do Mecânico',
                  value: 'is_studant'
                }
              ]}
              onChange={e => this.setState({ form: { ...this.state.form, is_studant: e, institution: null, unit: null } })}/>
              <ErrorMessage errors={this.state.formErrors['is_studant']} />
              {!this.state.form.is_studant ?
              <>
                <Input
                name='institution'
                label='Instituição de Ensino'
                placeholder='Instituição de Ensino'
                value={this.state.form['institution']}
                readonly={this.props.readonly}
                onChange={e => this.setState({ form: { ...this.state.form, institution: e } })}/>
                <ErrorMessage errors={this.state.formErrors['institution']} />
              </>:
              <>
                <Select
                name='unit'
                label='Unidade da Escola do Mecânico'
                placeholder='Unidade da Escola do Mecânico'
                value={this.state.form['unit']}
                readonly={this.props.readonly}
                options={this.props.em_units}
                onChange={e => this.setState({ form: { ...this.state.form, unit: e } })}/>
                <ErrorMessage errors={this.state.formErrors['unit']} />
              </>}
              <Input
              name='course_name'
              label='Nome do Curso'
              placeholder='Nome do Curso'
              value={this.state.form['course_name']}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, course_name: e } })}/>
              <ErrorMessage errors={this.state.formErrors['course_name']} />
            </div>
            <div className='column'>
              <Input
              name='start_date'
              label='Data de Início'
              placeholder='Data de Início'
              type='date'
              dateFormat='MM/yyyy'
              value={this.state.form['start_date']}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, start_date: e } })}/>
              <ErrorMessage errors={this.state.formErrors['start_date']} />
              <Input
              name='end_date'
              label='Data de Conclusão'
              placeholder='Data de Conclusão'
              type='date'
              dateFormat='MM/yyyy'
              value={this.state.form['end_date']}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, end_date: e } })}/>
              <ErrorMessage errors={this.state.formErrors['end_date']} />
            </div>
          </div>
          <div className='full-width-column'>
            <Input
            name='name'
            type='textarea'
            label='Descrição do Curso'
            value={this.state.form['description']}
            placeholder='Descrição do Curso'
            readonly={this.props.readonly}
            onChange={e => this.setState({ form: { ...this.state.form, description: e } })}/>
          </div>
        </div>
        <div className='btns'>
          {!this.props.readonly &&
          <SubmitBtn className='secondary' text='Salvar Alterações' loadingText='Salvando...' loading={this.state.loading} onSubmit={this.onSubmit} />}
          {this.props.readonly &&
          <SubmitBtn className='secondary' text='Editar' onSubmit={() => this.props.onUpdate(this.state.form['id'])} />}
          {this.props.readonly &&
          <SubmitBtn className='secondary' text='Remover' loadingText='Removendo...' loading={this.state.loading} onSubmit={() => this.setState({ selectedItemToDelete: true })} />}
        </div>
        <ConfirmationModal
        title='Deletar esse cargo de interesse?'
        text='Tem certeza que deseja deletar esse cargo de interesse? Nós não mostraremos ele novamente para você.'
        render={this.state.selectedItemToDelete}
        onConfirm={this.onRemoveScholarship}
        onClose={() => this.setState({ selectedItemToDelete: null })}/>
      </div>
    )
  }
}


import { useEffect, useState } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { CurrencyInput } from 'react-currency-mask'
import InputMask from 'react-input-mask'
import { ptBR } from 'date-fns/locale/pt-BR'
import "react-datepicker/dist/react-datepicker.css"
import './styles.sass'
registerLocale('ptBR', ptBR)

interface InputOptions { 
  name: string,
  label?: string,
  placeholder?: string,
  type?: string,
  dateFormat?: string,
  showTimeSelect?: boolean,
  value?: any,
  mask?: string,
  readonly?: boolean,
  disabled?: boolean,
  icon?: any,
  onChange?: (value: any) => any
}

export const Input = ({ name, label, placeholder, type, dateFormat, showTimeSelect, value, icon, mask, readonly, disabled, onChange }: InputOptions) => {
  const[currentValue, setCurrentValue] = useState(value)

  const onInputChange = (e: any) => {
    onChange && onChange(e.target.value);
    setCurrentValue(e.target.value);
  }

  useEffect(() => {
    setCurrentValue(value);
  }, [value])

  return (
    <div className={'input-container '+(disabled ? 'disabled' : '')+(icon ? 'with-icon' : '')}>
      {label && <label>{label}</label>}
      <div className='input'>
        {icon && <img className='icon' src={icon} />}
        {type === 'date' &&
          <DatePicker
          locale='ptBR'
          readOnly={readonly}
          selected={value}
          showTimeSelect={showTimeSelect}
          dateFormat={dateFormat ? dateFormat : 'dd/MM/YYYY'}
          onChange={(date) => onInputChange({ target: { value: date } })} />
        }
        {
          type === 'money' &&
          <CurrencyInput
            value={currentValue}
            InputElement={
              <input
              name={name}
              placeholder={placeholder}
              value={currentValue}
              disabled={disabled}
              readOnly={readonly} />
            }
            onChangeValue={(event, originalValue, maskedValue) => {
              onInputChange({ target: { value: maskedValue } });
            }}
          />
        }
        {
          type === 'textarea' &&
          <textarea
          name={name}
          placeholder={placeholder}
          value={currentValue}
          disabled={disabled}
          readOnly={readonly}
          onChange={onInputChange}>
          </textarea>
        }
        {
          (!type || !['date', 'money', 'textarea'].includes(type)) && !mask &&
          <input
          name={name}
          type={type ? type : 'text'}
          placeholder={placeholder}
          value={currentValue}
          disabled={disabled}
          readOnly={readonly}
          onChange={onInputChange} />
        }
        {
          (!type || !['date', 'money', 'textarea'].includes(type)) && mask &&
          <InputMask
          name={name}
          placeholder={placeholder}
          value={currentValue}
          disabled={disabled}
          mask={mask}
          onChange={onInputChange} />
        }
      </div>
    </div>
  )
}
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'
import searchIcon from '../../../../assets/icons/search.png'
import filterIcon from '../../../../assets/icons/filter.png'
import storageIcon from '../../../../assets/icons/storage.png'
import plusIcon from '../../../../assets/icons/plus.png'
import editIcon from '../../../../assets/icons/edit.png'
import peopleIcon from '../../../../assets/icons/people.png'
import duplicateIcon from '../../../../assets/icons/duplicate.png'
import { CandidateService } from '../../../../insfrastructure/services/candidates'
import { parseJwt } from '../../../../insfrastructure/utils/JWTParser'
import { Job, listJobs } from '../../../../insfrastructure/services/jobs'
import { ListResponse } from '../../../../insfrastructure/services/utils'
import { formatDate } from '../../../../insfrastructure/utils/date-formatter'
import './styles.sass'
import Table from '../../../components/paginator'

interface State {
  user_data: Record<string, any>,
  usertype: string,
  offers: ListResponse<Job>,
  loading: boolean,
  offset: number,
  search: string
}

class JobOffers extends Component<RouterProps> {
  state: State = {
    user_data: {
    },
    usertype: '',
    offers: {
      results: [],
      count: 0
    },
    offset: 0,
    loading: false,
    search: ''
  }

  componentDidMount() {
    this.getUserData();
    this.listOffers({ offset: 0 });
  }

  async getUserData() {
    try {
      const auth_token = localStorage.getItem('auth_token');
      if(!auth_token) return;

      const candidateService = new CandidateService();
              
      const candidate = await candidateService.retrieve();

      this.setState({ user_data: candidate, usertype: parseJwt(auth_token).profiles[0] });
    } catch (error) {}
  }

  listOffers = async ({offset}: Record<string, any>) => {
    try {
      this.setState({ loading: true, offers: [] });

      const offers: any = (await listJobs({ offset, limit: 10 }));
      offers.results = offers.results.map((x: any) => x.node);

      console.log(offers);
      
      this.setState({ offers, offset });
      
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 500);
    }
  }

  onSearch = async () => {
    this.listOffers({ search: this.state.search, offset: 0 });
  }

  render() {
    return (
      <div className='job-offers company-page'>
        <div className='block'>
            <div className='block-header'>
              <div className='title'>
                <img src={storageIcon} alt='offers' />
                <h3>Vagas</h3>
              </div>
              {this.state.usertype === 'company' &&
              <Link to='/offers/create'>
                <button className='primary add-btn'>
                  <img src={plusIcon} alt='add-offer' />
                  <span>Criar vaga</span>
                </button>
              </Link>}
            </div>
            <div className='oportunities-searcher'>
              <img className='search-icon' src={searchIcon} alt='search' onClick={this.onSearch} />
              <input type='text' placeholder='Pesquisar vaga' onChange={e => this.setState({ search: e.target.value })} />
              <img className='filter-icon' src={filterIcon} alt='filter' />
            </div>
            <div className='offers'>
              {this.state.loading && <p>Carregando...</p>}
              {!this.state.loading && this.state.offers.results.length === 0 && <p>Nenhuma vaga encontrada</p>}
              {!this.state.loading && this.state.offers.results.length > 0 &&
              <>
                <div className='job-results'>
                  <Table
                  data={this.state.offers.results}
                  total={this.state.offers.count}
                  limit={10}
                  offset={this.state.offset}
                  onNavigate={(params) => {
                    this.listOffers({ offset: params.offset });
                  }}
                  header={
                  <thead>
                    <tr>
                      <th className='name'>Nome da vaga:</th>
                      <th>Data de publicação:</th>
                      <th>Status:</th>
                      {this.state.usertype === 'company' && <th>Candidatos:</th>}
                      {this.state.usertype === 'company' && <th className='see-candidates'>Ver candidatos</th>}
                      <th className='actions'>{this.state.usertype === 'company' ? 'Editar vaga' : 'Acessar vaga'}</th>
                      {this.state.usertype === 'company' && <th>Duplicar:</th>}
                    </tr>
                  </thead>}
                  body={
                    (offers) => {
                      return offers.map((offer, index) =>
                      <tr>
                        <td>{offer.jobTitle['name']}</td>
                        <td>{formatDate(offer.updatedAt)}</td>
                        <td>
                          <span className='status open'>Aberta</span>
                        </td>
                        {this.state.usertype === 'company' && <td>3</td>}
                        {this.state.usertype === 'company' &&
                        <td>
                          <Link to={'/offers/'+offer.id+'/candidates'}>
                            <img className='people-icon' alt='candidates' src={peopleIcon} />
                          </Link>
                        </td>}
                        <td>
                          {this.state.usertype === 'candidate' && 
                          <Link to={'/offers/'+offer.id+'/details'}>
                            <img className='edit-icon' alt='edit' src={editIcon} />
                          </Link>}
                          {this.state.usertype === 'company' && 
                          <Link to={'/offers/'+offer.id}>
                            <img className='edit-icon' alt='edit' src={editIcon} />
                          </Link>}
                        </td>
                        {this.state.usertype === 'company' && 
                        <td>
                          <img className='duplicate-icon' alt='duplicate' src={duplicateIcon} />
                        </td>}
                      </tr>
                      )
                    }
                  }/>
                </div>
              </>}
            </div>
        </div>
      </div>
    )
  }
}

export default withRouter(JobOffers)

export const CircularChart = ({ percentage }: { percentage: number }) => {
  return (
    <svg width={100} height={100} viewBox="0 0 36 36">
      <path className="circle-bg" style={{
         fill: 'none',
         stroke: '#E6E6E6',
         strokeWidth: 2.5
      }}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#9FD67B"
        stroke-width="2.5"
        stroke-dasharray={`${percentage}, 100`}
        style={{ transition: '0.5s' }}
      />
      <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="8px" dy=".3em">{percentage}%</text>
    </svg>
  )
}

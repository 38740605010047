
import { Component } from 'react'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'
import infoIcon from '../../../../assets/icons/info.png'
import houseIcon from '../../../../assets/icons/house.png'
import emailIcon from '../../../../assets/icons/email.png'
import phoneIcon from '../../../../assets/icons/phone.png'
import repproveIcon from '../../../../assets/icons/delete-white.png'
import calendarIcon from '../../../../assets/icons/calendar-white.png'
import './styles.sass'
import { Link } from 'react-router-dom'
import IntervalModal from './interview-modal'
import RepproveModal from './repprove-modal'

class Candidate extends Component<RouterProps> {
    state = {
        candidate: {},
        updateCandidateLoading: false,
        renderCallForInterviewModal: false,
        renderRepproveCandidateModal: false
    }

    componentDidMount() {
        console.log(this.props.params?.candidate_id);
    }

    toggleCallForInterviewModal = () => {
        this.setState({ renderCallForInterviewModal: !this.state.renderCallForInterviewModal });
    }

    toggleRepproveCandidateModal = () => {
        this.setState({ renderRepproveCandidateModal: !this.state.renderRepproveCandidateModal });
    }
    onCandidateRepproved = async () => {
        this.toggleRepproveCandidateModal();
    }

    onCandidateCalledForInterview = async () => {
        this.toggleCallForInterviewModal();
    }

    render() {
        return (
            <div className='candidate company-page'>
                <div className='block'>
                    <div className='block-header'>
                        <div className='title'>
                            <img src={infoIcon} />
                            <h3><Link to={'/offers'}>Vagas {'>'}</Link> <Link to={'/offers/1/candidates'}>Mecânico de Linha Pesada - Candidatos {'>'}</Link> Candidato</h3>
                        </div>
                        <div className='actions'>
                            <button className='not-apt-btn' onClick={this.toggleRepproveCandidateModal}>
                                <img src={repproveIcon} />
                                <span>Não apto</span>
                            </button>
                            <button className='interview-btn' onClick={this.toggleCallForInterviewModal}>
                                <img src={calendarIcon} />
                                <span>Chamar para entrevista</span>
                            </button>
                        </div>
                    </div>
                    <div className='info'>
                        <div className='columns'>
                            <div className='column'>
                                <div className='main'>
                                    <div className='profile-picture-container'>
                                        <img className='profile-picture' src='https://pbs.twimg.com/profile_images/1271894206701846535/KwEJWdzm_400x400.jpg' />
                                    </div>
                                    <h3>Carlos Alberto Silva</h3>
                                    <div>
                                        <p>Solteiro, 43 anos</p>
                                        <p>Belo Horizonte, MG</p>
                                    </div>
                                </div>
                                <div className='experiences'>
                                    <h3>Minhas experiências</h3>
                                    <div className='list'>
                                        <div className='experience'>
                                            <b>Serviços Gerais</b>
                                            <p>CTA Construção</p>
                                            <p>2010 - Atualmente (12 anos)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='courses'>
                                    <h3>Meus cursos</h3>
                                    <div className='list'>
                                        <div className='course'>
                                            <b>Mecânica automotiva - Linha Leve</b>
                                            <p>Escola do Mecânico</p>
                                            <p>Campinas, SP</p>
                                            <span className='status finished'>Concluído</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='knowledges'>
                                    <h3>Conhecimentos:</h3>
                                    <div className='list'>
                                        <span className='knowledge'>Estudioso</span>
                                    </div>
                                </div>
                                <div className='abilities'>
                                    <h3>Conhecimentos:</h3>
                                    <div className='list'>
                                        <span className='ability'>Estudioso</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='basic-info columns'>
                            <div className='column'>
                                <div className='basic-info-intro'>
                                    <h3>Dados básicos:</h3>
                                    <div className='info-line'>
                                        <img src={phoneIcon} />
                                        <span>(11) 99999 - 9999</span>
                                    </div>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='info-line'>
                                    <img src={emailIcon} />
                                    <span>alma.lawson@example.com</span>
                                </div>
                            </div>
                            <div className='column'>
                                <div className='info-line'>
                                    <img src={houseIcon} />
                                    <span>Rua Madeleine Peixoto, 340, Belo Horizonte, MG - 30280-180</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <IntervalModal
                render={this.state.renderCallForInterviewModal}
                candidate={{ name: 'Carlos Alberto Silva', email: 'calos@gmail.com', phone: '11948549777' }}
                onClose={this.toggleCallForInterviewModal}
                onSubmitted={this.onCandidateCalledForInterview} />
                <RepproveModal
                render={this.state.renderRepproveCandidateModal}
                candidate={{ name: 'Carlos Alberto Silva' }}
                onClose={this.toggleRepproveCandidateModal}
                onSubmitted={this.onCandidateRepproved} />
            </div>
        )
    }
}

export default withRouter(Candidate)

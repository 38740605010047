import axios from "axios"
import { environment } from "../../environments/environment";
import { parseJwt } from "../utils/JWTParser";

interface LoginData {
    username: string,
    password: string
};

interface LoginResponse {
    access: string
};

export const login = async (login_data: LoginData) => {
    const response = await axios.post<LoginResponse>(environment.apiUrl+'/api/token/', login_data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    localStorage.setItem('auth_token', response.data.access);
    localStorage.setItem('candidate_id', parseJwt(response.data.access).candidate_id);
    localStorage.setItem('user_id', parseJwt(response.data.access).user_id);
};
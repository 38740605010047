import { useEffect, useState } from 'react'
import { DeskHeader } from './desk-header';
import { MobileHeader } from './mobile-header';

export const Header = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => setInnerWidth(window.innerWidth));
  }, [])

  return innerWidth > 1000 ? <DeskHeader /> : <MobileHeader />
}

import { Component } from 'react'
import editImg from '../../../../../assets/icons/edit.png'
import locationImg from '../../../../../assets/icons/location.png'
import './styles.sass'

interface Props {
  basic_info: Record<string, any>,
  benefits: Record<string, any>,
  requirements: Record<string, any>,
  onSubmit: () => void,
  changeSection: (index: number) => void
}

export default class Summary extends Component<Props> {
  componentDidMount() {
    console.log(this.props);
  }

  render() {
    return (
      <div className='summary'>
        <div className='section basic-info-section'>
          <div className='section-header'>
            <h4>Dados básicos:</h4>
            <div className='edit' onClick={() => this.props.changeSection(1)}>
              <img src={editImg} />
              <span>Editar</span>
            </div>
          </div>
          <div className='columns'>
            <div className='column'>
              <p>Nome da vaga:</p>
              <b>{this.props.basic_info.name}</b>
            </div>
            <div className='column'>
              <p>Quantidade de vagas:</p>
              <b>{this.props.basic_info.qtd} vaga{this.props.basic_info.qtd > 1 ? 's' : ''}</b>
            </div>
            <div className='column'>
              <p>Nome de contratação:</p>
              <b>{this.props.basic_info.type}</b>
            </div>
          </div>
          <div className='location-section'>
              <div className='location-title'>
                <img src={locationImg}/>
                <p>Localização</p>
              </div>
              <b>{this.props.basic_info.street},{this.props.basic_info.number} - {this.props.basic_info.neighborhood}, {this.props.basic_info.city}, {this.props.basic_info.uf} - {this.props.basic_info.cep}</b>
          </div>
          <div className='about-section'>
            <p>Sobre a vaga:</p>
            <p>{this.props.basic_info.description}</p>
          </div>
        </div>
        <div className='section benefits-section'>
          <div className='section-header'>
            <h4>Salário e benefícios:</h4>
            <div className='edit' onClick={() => this.props.changeSection(2)}>
              <img src={editImg} />
              <span>Editar</span>
            </div>
          </div>
          <div className='columns'>
            <div className='column'>
              <p>Salário:</p>
              <b className='salary'>{this.props.benefits.wage_to_resolve === 'yes' ? 'À combinnar' : this.props.benefits.wage}</b>
            </div>
            <div className='column'>
              <p>Benefícios:</p>
              <div className='selections'>
                {this.props.benefits.benefits.map((benefit: string) =>
                <b className='selection'>{benefit}</b>)}
              </div>
            </div>
          </div>
        </div>
        <div className='section requirements-section'>
          <div className='section-header'>
            <h4>Requisitos:</h4>
            <div className='edit' onClick={() => this.props.changeSection(3)}>
              <img src={editImg} />
              <span>Editar</span>
            </div>
          </div>
          <div className='columns'>
            <div className='column'>
              <p>Escolaridade:</p>
              <b>{this.props.requirements.schoolarship}</b>
            </div>
            <div className='column'>
              <p>Experiência:</p>
              <b>{this.props.requirements.experience}</b>
            </div>
          </div>
          <div>
            <p>Habilidades:</p>
            <div className='selections'>
              {this.props.requirements.habilities.map((hability: string) =>
              <b className='selection'>{hability}</b>)}
            </div>
          </div>
          <div className='columns'>
            <div className='column'>
              <p>Cursos recomendados:</p>
              <div className='selections'>
                {this.props.requirements.courses.map((course: string) =>
                <b className='selection'>{course}</b>)}
              </div>
            </div>
          </div>
        </div>
        <button className='primary' onClick={this.props.onSubmit}>Confirmar</button>
      </div>
    )
  }
}

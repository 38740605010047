import { Component } from 'react'
import moment from 'moment'
import { RouterProps, withRouter } from '../../../../../../insfrastructure/utils/with-router-component'
import { Input } from '../../../../../components/input'
import companyImg from '../../../../../../assets/images/company.png'
import { CNPJValidator, CPFValidator, ErrorMessage, emptyField, errorsLength, formatErrors, validateCEP, validatePasswordConfirmation } from '../../../../../../insfrastructure/utils/form-validators';
import { Select } from '../../../../../components/select'
import CheckboxInput from '../../../../../components/checkbox'
import birthdateIcon from '../../../../../../assets/icons/birthdate.png'
import companySegmentIcon from '../../../../../../assets/icons/company_segment.png'
import documentIcon from '../../../../../../assets/icons/document.png'
import emailIcon from '../../../../../../assets/icons/email.png'
import eyeIcon from '../../../../../../assets/icons/eye.png'
import filterIcon from '../../../../../../assets/icons/filter.png'
import fullPersonIcon from '../../../../../../assets/icons/full_person.png'
import infoIcon from '../../../../../../assets/icons/info.png'
import linkIcon from '../../../../../../assets/icons/link.png'
import locationIcon from '../../../../../../assets/icons/location.png'
import passwordIcon from '../../../../../../assets/icons/password.png'
import phoneIcon from '../../../../../../assets/icons/phone.png'
import profileIcon from '../../../../../../assets/icons/profile.png'
import './styles.sass'
import './company-data.sass'
import './about-company.sass'
import './company-user.sass'
import './resume.sass'
import { PostcodeService } from '../../../../../../insfrastructure/services/cep'
import { listCities } from '../../../../../../insfrastructure/services/cities'

class CompanyForm extends Component<RouterProps> {
  constructor(props: any) {
    super(props);
  }

  state = {
    current_slide: 1,
    company_data: {},
    about_company: {},
    company_user_data: {}
  }

  onChangeStep = (current_slide: number, form_name: string, form_data: Record<string, any>) => {
    this.setState({ current_slide, [form_name]: form_data });
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className='company-sign-up-form'>
        <h3>Dados da Empresa</h3>
        <Slider currentSlide={this.state.current_slide} />
        { this.state.current_slide === 1 && <CompanyData onSubmit={(company_data) => this.onChangeStep(2, 'company_data', company_data)} /> }
        { this.state.current_slide === 2 && <AboutCompany onSubmit={(about_company) => this.onChangeStep(3, 'about_company', about_company)} /> }
        { this.state.current_slide === 3 && <CompanyUserData onSubmit={(company_user_data) => this.onChangeStep(4, 'company_user_data', company_user_data)} /> }
        { this.state.current_slide === 4 && 
        <ResumeWithRouter
        company_data={this.state.company_data}
        about_company={this.state.about_company}
        company_user_data={this.state.company_user_data} /> }
      </div>
    )
  }
}

export default withRouter(CompanyForm);

const Slider = ({ currentSlide }: { currentSlide: number }) => {

  const slideClass = (slideIndex: number) => {
    let className = 'slide';

    if (slideIndex === currentSlide) {
      className += ' current';
    } else if (slideIndex < currentSlide) {
      className += ' checked';
    };

    return className;
  }

  return (
    <div className='slider'>
      <div className={slideClass(1)}>
        <span className='number'>1</span>
        <span className='slide-name'>Dados da Empresa</span>
      </div>
      <span className={'spacer '+(currentSlide > 1 ? 'checked' : '')}></span>
      <div className={slideClass(2)}>
        <span className='number'>2</span>
        <span className='slide-name'>Sobre a Empresa</span>
      </div>
      <span className={'spacer '+(currentSlide > 2 ? 'checked' : '')}></span>
      <div className={slideClass(3)}>
        <span className='number'>3</span>
        <span className='slide-name'>Dados do Responsável</span>
      </div>
      <span className={'spacer '+(currentSlide > 3 ? 'checked' : '')}></span>
      <div className={slideClass(4)}>
        <span className='number'>4</span>
        <span className='slide-name'>Confirmação</span>
      </div>
    </div>
  )
}

class CompanyData extends Component<{onSubmit: (form: Record<string, any>) => void}> {

  state: any = {
    form: {

    },
    formErrors: {},
    cityOptions: []
  }
  timeout: any = null

  onCityNameChange = async (e: any) => {
    const cityOptions = (await listCities(e)).map(x => ({ value: x.id, label: x.name }));

    this.setState({ cityOptions });

    return cityOptions;
  }

  onCEPChange = async (value: any) => {
    clearTimeout(this.timeout);
    
    this.timeout = setTimeout(async () => {
      let street = '';
      let neighborhood = '';
      let city_id = '';
      let city = '';
      let uf = '';

      if(validateCEP(value) === '') {
        const postcodeService = new PostcodeService();
        const result = await postcodeService.retrieve(value);

        const cityOptions = await this.onCityNameChange(result.localidade);

        street = result.logradouro;
        neighborhood = result.bairro;
        city_id = result.city_id + '';        
        city = cityOptions.filter(x => x.value === city_id)[0].label;
        uf = result.uf;
      };
  
      this.setState({
        form: {
          ...this.state.form,
          street,
          neighborhood,
          city_id,
          city,
          uf,
          address: street+','+neighborhood+','+city+','+uf
        }
      });
    }, 1000);
  }

  onSubmit = () => {
    // const errors = formatErrors({
    //   name: [emptyField(this.state.form.name, 'string')],
    //   ie: [emptyField(this.state.form.ie, 'string')],
    //   cnpj: [CNPJValidator(this.state.form.cnpj)],
    //   phone: [emptyField(this.state.form.phone, 'string')]
    // });

    // this.setState({ formErrors: errors });

    // if(errorsLength(errors) > 0) return;

    try {
      setTimeout(() => {
        this.setState({ sended: true });
        this.props.onSubmit(this.state.form);
      });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='form company-data'>
        <div className='form-container'>
          <div className='columns'>
            <div className='column'>
              <Input
              name='fantasy_name'
              label='Nome Fantasia'
              onChange={e => this.setState({ form: { ...this.state.form, fantasy_name: e } })}/>
              <ErrorMessage errors={this.state.formErrors['fantasy_name']} />
              <div className='row'>
                <div className='flex-1'>
                  <Input
                  name='ie'
                  label='IE Empresa'
                  onChange={e => this.setState({ form: { ...this.state.form, ie: e } })}/>
                  <ErrorMessage errors={this.state.formErrors['ie']} />
                </div>
                <CheckboxInput
                name='terms_and_conditions'
                single={true}
                value={this.state.form.terms_and_conditions}
                options={[
                  {
                    label: 'Isento',
                    value: 'terms_and_conditions'
                  }
                ]}
                onChange={e => this.setState({ form: { ...this.state.form, terms_and_conditions: e } })}/>
              </div>
              <div className='location row'>
                <img src={locationIcon} alt='location' />
                <p>Localização</p>
              </div>
              <div className='block'>
                  <Input
                  name='cep'
                  label='CEP da empresa'
                  onChange={e => {this.setState({ form: { ...this.state.form, postcode: e } }); this.onCEPChange(e)}}/>
                  <ErrorMessage errors={this.state.formErrors['postcode']} />
                  <div className='address-part'>
                    <p>Rua: <p>{ this.state.form.street ? this.state.form.street : '-' }</p></p>
                  </div>
                  <div className='address-part'>
                    <p>Bairro: <p>{ this.state.form.neighborhood ? this.state.form.neighborhood : '-' }</p></p>
                  </div>
                  <div className='address-part'>
                    <p>Cidade / UF: <p>{ this.state.form.city ? this.state.form.city+' / '+this.state.form.uf : '-' }</p></p>
                  </div>
                  <div className='row address-row'>
                    <div className='street-number-input-container'>
                      <Input
                      name='address_number'
                      label='N°'
                      onChange={e => this.setState({ form: { ...this.state.form, address_number: e } })}/>
                      <ErrorMessage errors={this.state.formErrors['address_number']} />
                    </div>
                    <div className='flex-1'>
                      <Input
                      name='cep'
                      label='Complemento'
                      onChange={e => this.setState({ form: { ...this.state.form, cep: e } })}/>
                      <ErrorMessage errors={this.state.formErrors['cep']} />
                    </div>
                  </div>
              </div>
            </div>
            <div className='column'>
              <Input
              name='cnpj'
              label='CNPJ da Empresa'
              mask='99.999.999/9999-99'
              onChange={e => this.setState({ form: { ...this.state.form, cnpj: e } })}/>
              <ErrorMessage errors={this.state.formErrors['cnpj']} />
              <Select
              name='phone'
              label='Segmento da empresa'
              icon={companySegmentIcon}
              options={[]}
              onChange={e => this.setState({ form: { ...this.state.form, phone: e } })}/>
              <ErrorMessage errors={this.state.formErrors['phone']} />
              <Input
              name='company_email'
              label='Email'
              icon={emailIcon}
              onChange={e => this.setState({ form: { ...this.state.form, company_email: e } })}/>
              <ErrorMessage errors={this.state.formErrors['company_email']} />
              <Input
              name='phone'
              icon={phoneIcon}
              label='Telefone para contato'
              onChange={e => this.setState({ form: { ...this.state.form, phone: e } })}/>
              <ErrorMessage errors={this.state.formErrors['phone']} />
              <Input
              name='website'
              icon={linkIcon}
              label='Site da empresa'
              onChange={e => this.setState({ form: { ...this.state.form, website: e } })}/>
              <ErrorMessage errors={this.state.formErrors['website']} />
              <Input
              name='cnpj'
              icon={linkIcon}
              label='Rede social da empresa'
              onChange={e => this.setState({ form: { ...this.state.form, cnpj: e } })}/>
              <ErrorMessage errors={this.state.formErrors['cnpj']} />
            </div>
          </div>
          <button className='primary submit' onClick={this.onSubmit}>Confirmar</button>
        </div>
      </div>
    )
  }
}

class AboutCompany extends Component<{onSubmit: (form: Record<string, any>) => void}> {

  state: any = {
    form: {

    },
    formErrors: {}
  }

  onSubmit = () => {
    // const errors = formatErrors({
    //   name: [emptyField(this.state.form.name, 'string')],
    //   ie: [emptyField(this.state.form.ie, 'string')],
    //   cnpj: [CNPJValidator(this.state.form.cnpj)],
    //   phone: [emptyField(this.state.form.phone, 'string')]
    // });

    // this.setState({ formErrors: errors });

    // if(errorsLength(errors) > 0) return;

    try {
      setTimeout(() => {
        this.setState({ sended: true });
        this.props.onSubmit(this.state.form);
      });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='form about-company'>
        <div className='form-container'>
          <div className='text'>
            <h4>Fale um pouco sobre a sua empresa.</h4>
            <p>As informações a serem compartilhadas aqui apareceram nos seus anúncios de vagas.</p>
          </div>
          <div className='columns'>
            <div className='column'>
              <Input
              name='description'
              label='Sobre a empresa:*'
              type='textarea'
              onChange={e => this.setState({ form: { ...this.state.form, description: e } })}/>
              <ErrorMessage errors={this.state.formErrors['description']} />
            </div>
            <div className='column'>
              <Input
              name='motivation'
              label='Por que trabalhar com a gente?*'
              type='textarea'
              onChange={e => this.setState({ form: { ...this.state.form, motivation: e } })}/>
              <ErrorMessage errors={this.state.formErrors['motivation']} />
            </div>
          </div>
          <button className='primary submit' onClick={this.onSubmit}>Confirmar</button>
        </div>
      </div>
    )
  }
}

class CompanyUserData extends Component<{onSubmit: (form: Record<string, any>) => void}> {

  state: any = {
    form: {

    },
    formErrors: {}
  }

  onSubmit = () => {
    // const errors = formatErrors({
    //   fullname: [emptyField(this.state.form.fullname, 'string')],
    //   phone: [emptyField(this.state.form.phone, 'string')],
    //   birthdate: [emptyField(this.state.form.birthdate, 'string')],
    //   uf: [emptyField(this.state.form.uf, 'string')],
    //   cpf: [CPFValidator(this.state.form.cpf)],
    //   email: [emptyField(this.state.form.email, 'string')],
    //   gender: [emptyField(this.state.form.gender, 'string')],
    //   city: [emptyField(this.state.form.city, 'string')],
    //   password: [emptyField(this.state.form.password, 'string')],
    //   password_confirmation: [validatePasswordConfirmation(this.state.form.password, this.state.form.password_confirmation)]
    // });

    // this.setState({ formErrors: errors });

    // if(errorsLength(errors) > 0) return;

    try {
        setTimeout(() => {
            this.setState({ sended: true });
            this.props.onSubmit(this.state.form);
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='form company-user'>
        <div className='form-container'>
          <div className='text'>
            <h4>Toda empresa precisa ter um perfil responsável por atualizá-la.</h4>
            <p>Este perfil administrador poderá deletar e conceder acesso de admin. para outros usuários que entrarem no nome da sua empresa e poderá editar o perfil da empresa.</p>
          </div>
          <div className='columns'>
            <div className='column'>
              <Input
              name='full_name'
              label='Nome Completo'
              icon={profileIcon}
              onChange={e => this.setState({ form: { ...this.state.form, full_name: e } })}/>
              <ErrorMessage errors={this.state.formErrors['full_name']} />
              <Input
              name='manager_email'
              label='E-Mail'
              icon={emailIcon}
              onChange={e => this.setState({ form: { ...this.state.form, manager_email: e } })}/>
              <ErrorMessage errors={this.state.formErrors['manager_email']} />
              <Input
              name='cpf'
              label='CPF'
              icon={documentIcon}
              mask='999.999.999-99'
              onChange={e => this.setState({ form: { ...this.state.form, cpf: e } })}/>
              <ErrorMessage errors={this.state.formErrors['cpf']} />
              <Input
              name='date_of_birth'
              label='Data de Nascimento'
              type='date'
              icon={birthdateIcon}
              value={this.state.form.date_of_birth}
              onChange={e => this.setState({ form: { ...this.state.form, date_of_birth: e } })}/>
              <ErrorMessage errors={this.state.formErrors['date_of_birth']} />
              <Select
              name='gender'
              label='Gênero'
              icon={fullPersonIcon}
              options={[]}
              onChange={e => this.setState({ form: { ...this.state.form, gender: e } })}/>
              <ErrorMessage errors={this.state.formErrors['gender']} />
            </div>
            <div className='column'>
              <Input
              name='password'
              label='Senha'
              type='password'
              icon={passwordIcon}
              onChange={e => this.setState({ form: { ...this.state.form, password: e } })}/>
              <ErrorMessage errors={this.state.formErrors['password']} />
              <Input
              name='password_confirmation'
              label='Confirmar Senha'
              type='password'
              icon={passwordIcon}
              onChange={e => this.setState({ form: { ...this.state.form, password_confirmation: e } })}/>
              <ErrorMessage errors={this.state.formErrors['password_confirmation']} />
              <div className='password-requirements'>
                <b>Requisitos de senha</b>
                <p><span className='status'>check</span> A senha não pode ser igual ao login</p>
                <p><span className='status'>check</span> O tamanho da senha deve ser de 8 a 50 caracteres</p>
                <p><span className='status'>check</span> É necessario ter pelo menos um número</p>
                <p><span className='status'>check</span> É necessário pelo menos uma letra maiúscula</p>
                <p><span className='status'>check</span> É necessário pelo menos uma letra minúscula</p>
              </div>
            </div>
        </div>
        <div className='submit-block'>
          <div>
            <p>
              Não se preocupe, suas informações estão seguras conosco. Seu CPF será usado para assegurar seu cadastro único e seus dados não serão compartilhado com os candidatos.
            </p>
          </div>
          <button className='primary' onClick={this.onSubmit}>Confirmar</button>
        </div>
        </div>
      </div>
    )
  }
}

class Resume extends Component<{ company_data: Record<string, any>, about_company: Record<string, any>, company_user_data: Record<string, any>, navigate: any }> {

  onSubmit = () => {
    this.props.navigate('/sign-up/company/confirmation/request', { state: this.props.company_user_data });
  }

  render() {
    return (
      <div className='resume'>
        <div className='form-container'>
          <div className='columns'>
            <div className='column'>
              <div className='data-block'>
                <h4>Dados Empresa</h4>
                <div>
                  <p>Nome de Empresa: <b>{this.props.company_data.fantasy_name}</b></p>
                  <p>CNPJ: <b>{this.props.company_data.cnpj}</b></p>
                  <p>IE: <b>{this.props.company_data.ie}</b></p>
                  <p>Telefone: <b>{this.props.company_data.phone}</b></p>
                  <p>Endereço: Rua Campo dos Goitacazes, 243</p>
                  <p>Cidade: <b>{this.props.company_data.city}</b></p>
                  <p>Estado: <b>{this.props.company_data.uf}</b></p>
                  <p>Tipo da Vaga: Efetivo</p>
                </div>
              </div>
              <div className='text-block'>
                <h4>Sobre a Empresa</h4>
                <p>{this.props.about_company.description}</p>
              </div>
            </div>
            <div className='column'>
              <div className='data-block'>
                <h4>Dados do Responsável</h4>
                <div>
                  <p>Nome Completo: <b>{this.props.company_user_data.full_name}</b></p>
                  <p>CPF: <b>{this.props.company_user_data.cpf}</b></p>
                  <p>Celular: <b>{this.props.company_user_data.phone}</b></p>
                  <p>E-mail: <b>{this.props.company_user_data.manager_email}</b></p>
                  <p>Data de nascimento: <b>{moment(this.props.company_user_data.date_of_birth).format('DD/MM/yyyy')}</b></p>
                  <p>Gênero: <b>{this.props.company_user_data.gender}</b></p>
                  <p>Estado: <b>{this.props.company_user_data.uf}</b></p>
                  <p>Cidade: <b>{this.props.company_user_data.city}</b></p>
                </div>
              </div>
              <div className='text-block'>
                <h4>Sobre a Empresa</h4>
                <p>{this.props.about_company.motivation}</p>
              </div>
            </div>
            <div className='column'>
              <img src={companyImg} alt='company' />
            </div>
          </div>
          <div className='submit-block'>
            <div>
              <h4>Aviso Importante</h4>
              <p>Ao confirmar esta ação, você declara e concorda que assume total responsabilidade pela precisão e veracidade das informações fornecidas em nome da empresa. Além disso, você se compromete a responder legalmente por qualquer consequência que possa advir de informações incorretas ou imprecisas, sendo a principal responsável pela credibilidade dos dados apresentados. Certifique-se de revisar todas as informações com atenção antes de prosseguir. A sua confirmação implica na aceitação dos termos acima e na responsabilidade legal associada.</p>
            </div>
            <button className='primary' onClick={this.onSubmit}>Confirmar</button>
          </div>
        </div>
      </div>
    )
  }
}

const ResumeWithRouter = withRouter(Resume);

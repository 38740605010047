import { Component } from 'react'
import Modal from '../../../../components/modal'
import { SubmitBtn } from '../../../../components/submit'
import repproveIcon from '../../../../../assets/icons/close-red.png'
import './styles.sass'
import { Select } from '../../../../components/select'

interface ModalProps {
    render: boolean,
    candidate?: any,
    onClose: () => void,
    onSubmitted: () => void
}

export default class RepproveModal extends Component<ModalProps> {
    state: any = {
        form: {},
        formErrors: {},
        loading: false
    }

    onSubmit = async () => {
        if(this.state.loading) return;

        try {
            this.setState({ loading: true });

        } catch (error) {
            
        };

        setTimeout(() => {
            this.setState({ loading: false });
            this.props.onSubmitted();
        }, 500);
    }

    render() {
        return (
        <Modal render={this.props.render} onClose={() => this.props.onClose()}>
            <div className='repprove-modal'>
                <div className='modal-header'>
                    <img src={repproveIcon} />
                    <h4>Marcar <b>{this.props.candidate && this.props.candidate.name}</b> como não apto</h4>
                </div>
                <form>
                    <Select
                    name='repprove_reason'
                    label='Selecione um motivo:'
                    value={this.state.form.datetime}
                    options={[

                    ]}
                    onChange={(e) => {
                        console.log(e);
                        this.setState({ form: { ...this.state.form, repprove_reason: e }});
                    }}/>
                </form>
                <SubmitBtn
                className='primary'
                text='Confirmar'
                loadingText='Confirmando...'
                onSubmit={this.onSubmit}
                loading={this.state.loading} />
            </div>
        </Modal>
        )
    }
}

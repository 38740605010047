import { Component } from 'react'
import forgotPasswordImg from '../../../../assets/images/forgot_password.png'
import checkImg from '../../../../assets/images/check.png'
import { Link } from 'react-router-dom'
import { Input } from '../../../components/input'
import './styles.sass'
import { ErrorMessage, emptyField, errorsLength, formatErrors, validateEmail, validatePasswordConfirmation } from '../../../../insfrastructure/utils/form-validators'
import { SubmitBtn } from '../../../components/submit'


export default class ResetPassword extends Component {
    state: any = {
        form: {
            new_password: '',
            new_password_confirmation: ''
        },
        formErrors: {},
        loading: false,
        sended: false
    }

    componentDidMount(): void {
    }

    onSubmit = async () => {
        this.setState({ formErrors: {} });
        const errors = formatErrors({
            new_password: [emptyField(this.state.form.new_password, 'string')],
            new_password_confirmation: [validatePasswordConfirmation(this.state.form.new_password, this.state.form.new_password_confirmation)]
        });

        if(errorsLength(errors) > 0) {
            this.setState({ formErrors: errors });

            return;
        };

        this.setState({ loading: true });

        try {

            setTimeout(() => {
                this.setState({ sended: true, loading: false });
            });
        } catch (error) {
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div className='reset-password unlogged-page'>
                <div className='content'>
                    <div className='block'>
                        {window.innerWidth > 1000 &&
                        <div className='img-container'>
                            <img src={forgotPasswordImg} />
                        </div>}
                        {
                            !this.state.sended ?
                            <div className='block-content not-sended'>
                                <div className='text'>
                                    <h3>Olá A*******! A seguir altere sua senha</h3>
                                </div>
                                <div className='form'>
                                    <Input
                                    name='new_password'
                                    label='Nova Senha'
                                    type='password'
                                    placeholder='Insira sua nova senha'
                                    onChange={e => this.setState({ form: {...this.state.form, new_password: e} })}/>
                                    <ErrorMessage errors={this.state.formErrors['new_password']} />
                                    <Input
                                    name='new_password_confirmation'
                                    label='Confirme sua Nova Senha'
                                    type='password'
                                    placeholder='Insira sua nova senha'
                                    onChange={e => this.setState({ form: {...this.state.form, new_password_confirmation: e} })}/>
                                    <ErrorMessage errors={this.state.formErrors['new_password_confirmation']} />
                                </div>
                                <p className='bottom-text'>Ao <b>confirmar</b>, sua <b>senha</b> será alterada.</p>
                                <SubmitBtn className='secondary' text='Confirmar' loadingText='Enviando...' loading={this.state.loading} onSubmit={this.onSubmit} />
                            </div> :
                            <div className='block-content sended'>
                                <div className='text'>
                                    <img className='check' src={checkImg} />
                                    <h3>Senha alterada com sucesso!</h3>
                                </div>
                                <Link to='/login'><SubmitBtn className='secondary' text='OK' /></Link>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
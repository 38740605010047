import { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from '../../../../insfrastructure/utils/with-router-component'
import searchIcon from '../../../../assets/icons/search.png'
import filterIcon from '../../../../assets/icons/filter.png'
import infoIcon from '../../../../assets/icons/info.png'
import storageIcon from '../../../../assets/icons/storage.png'
import checkIcon from '../../../../assets/icons/check.png'
import badgeIcon from '../../../../assets/icons/badge.png'
import deleteIcon from '../../../../assets/icons/delete-white.png'
import './styles.sass'
import ApproveModal from './approve-modal'
import RepproveModal from '../candidate/repprove-modal'

class Candidates extends Component<any> {
  state: any = {
    offer: {},
    candidates: {
      data: [],
      count: 0
    },
    selectedApprovedCandidate: null,
    selectedRepprovedCandidate: null,
    loading: false,
    search: ''
  }

  componentDidMount() {
    console.log(this.props.params.offer_id);
    this.listOffers({});
  }

  listOffers = async (params: Record<string, any>) => {
    try {
      this.setState({ loading: true });

      const offer = {
        name: 'Mecânico de Linha Pesada'
      };

      const candidates = {
        data: [
          {
            id: 1,
            name: 'Mecânico de Linha Pesada',
            status: '1'
          },
          {
            id: 2,
            name: 'Mecânico de Linha Pesada',
            status: '2'
          },
          {
            id: 2,
            name: 'Mecânico de Linha Pesada',
            status: '3'
          },
          {
            id: 2,
            name: 'Mecânico de Linha Pesada',
            status: '4'
          }
        ],
        count: 75
      };

      this.setState({ offer, candidates });
      
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 1000);
    } catch (error) {
      setTimeout(() => {
        this.setState({ loading: false }); 
      }, 500);
    }
  }

  onSearch = async () => {
    this.listOffers({ search: this.state.search });
  }

  toggleApproveCandidateModal = (candidate?: any) => {
    this.setState({ selectedApprovedCandidate: candidate });
  }

  onCandidateApproved = async () => {
    this.toggleApproveCandidateModal();
  }

  toggleRepproveCandidateModal = (candidate?: any) => {
    this.setState({ selectedRepprovedCandidate: candidate });
  }

  onCandidateRepproved = async () => {
      this.toggleRepproveCandidateModal();
  }

  render() {
    return (
      <div className='job-candidates company-page'>
        <div className='block'>
            <div className='block-header'>
              <div className='title'>
                <img src={storageIcon} />
                <h3><Link to={'/offers'}>Vagas {'>'}</Link> {this.state.offer.name} - Candidatos</h3>
              </div>
            </div>
            <div className='general-vision'>
              <div className='data'>
                <h3>Visão Geral</h3>
                <h4>Candidatos: <b>2</b></h4>
                <h4>Atendidos: <b>0</b></h4>
              </div>
              <div className='graph-container'>
                <CircularChart percentage={!this.state.loading ? this.state.candidates.count : 0} />
                <h4>Atendidos</h4>
              </div>
            </div>
            <div className='searcher'>
              <img className='search-icon' src={searchIcon} onClick={this.onSearch} />
              <input type='text' placeholder='Pesquisar vaga' onChange={e => this.setState({ search: e.target.value })} />
              <img className='filter-icon' src={filterIcon} />
            </div>
            <div className='candidates'>
              {this.state.loading && <p>Carregando...</p>}
              {!this.state.loading && this.state.candidates.data.length === 0 && <p>Nenhum candidato encontrado</p>}
              {!this.state.loading && this.state.candidates.data.length > 0 &&
              <>
                <div className='results'>
                  <table cellSpacing={0}>
                    <thead>
                      <tr>
                        <th className='name'>Nome do candidato:</th>
                        <th className='basic-info'>Dados básicos:</th>
                        <th className='pcd'>PcD?</th>
                        <th className='status'>Status:</th>
                        <th className='status'>Adesão:</th>
                        <th className='actions'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.candidates.data.map((candidate: any, index: number) =>
                        <tr key={'candidate_'+index} className={['4'].includes(candidate.status) ? 'not-hired' : ''}>
                          <td>Fernado Alvez</td>
                          <td><p>Solteiro, 23 anos</p><p>Belo Horizonte, MG</p></td>
                          <td>Não</td>
                          <td>
                            <span>Se candidatou:<br/> 14/10/2022</span>
                          </td>
                          <td>
                            <span>68%</span>
                          </td>
                          <td>
                            <div className='actions'>
                              {['1', '2', '3', '4'].includes(candidate.status) &&
                              <Link to={'/candidates/'+candidate.id}>
                                <button className='access'>
                                  <img src={infoIcon} />
                                  <span>Conheça o candidato</span>
                                </button>
                              </Link>}
                              {['2'].includes(candidate.status) &&
                              <>
                                <button className='remove' onClick={() => this.toggleRepproveCandidateModal({ name: 'Carlos Alberto Silva' })}>
                                  <img src={deleteIcon}/>
                                  <span>Não Apto</span>
                                </button>
                                <button className='hire' onClick={() => this.toggleApproveCandidateModal({ name: 'Carlos Alberto Silva' })}>
                                  <img src={checkIcon} />
                                  <span>Contratar</span>
                                </button> 
                              </>}
                              {['3'].includes(candidate.status) &&
                              <span className='status hired'>
                                <img src={badgeIcon} />
                                <span>Contratado</span>
                              </span>}
                              {['4'].includes(candidate.status) &&
                              <span className='status not-hired'>
                                <img src={deleteIcon} />
                                <span>Não Apto</span>
                              </span>}
                            </div>
                          </td>
                        </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div>
              </>}
            </div>
        </div>
        <ApproveModal
        render={this.state.selectedApprovedCandidate}
        candidate={this.state.selectedApprovedCandidate}
        onClose={this.toggleApproveCandidateModal}
        onSubmitted={this.onCandidateApproved} />
        <RepproveModal
        render={this.state.selectedRepprovedCandidate}
        candidate={this.state.selectedRepprovedCandidate}
        onClose={this.toggleRepproveCandidateModal}
        onSubmitted={this.onCandidateRepproved} />
      </div>
    )
  }
}

export default withRouter(Candidates)

export const CircularChart = ({ percentage }: { percentage: number }) => {
  return (
    <svg width={100} height={100} viewBox="0 0 36 36">
      <path className="circle-bg" style={{
         fill: 'none',
         stroke: '#E6E6E6',
         strokeWidth: 2.5
      }}
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path
        d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        fill="none"
        stroke="#9FD67B"
        stroke-width="2.5"
        stroke-dasharray={`${percentage}, 100`}
        style={{ transition: '0.5s' }}
      />
      <text x="50%" y="50%" text-anchor="middle" fill="black" font-size="8px" dy=".3em">{percentage}%</text>
    </svg>
  )
}

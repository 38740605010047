import { Component } from 'react'
import { Link } from 'react-router-dom'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../insfrastructure/utils/form-validators'
import { SubmitBtn } from '../../../components/submit'
import { Input } from '../../../components/input'
import loginImg from '../../../../assets/images/login.png'
import logoImg from '../../../../assets/images/logo-white.png'
import { login } from '../../../../insfrastructure/services/auth'
import ResponseModal from '../../../components/response-modal'
import './styles.sass'


class Login extends Component<RouterProps> {
    state: any = {
        form: {
            username: '',
            password:  '',
            remember_me: false
        },
        formErrors: {},
        loading: false,
        sended: false
    }

    onSubmit = async () => {
        const errors = formatErrors({
            username: [emptyField(this.state.form.username, 'string')],
            password: [emptyField(this.state.form.password, 'string')]
        });

        this.setState({ formErrors: errors });

        if(errorsLength(errors) > 0) return;

        this.setState({ loading: true });

        try {
            await login(this.state.form);
            
            setTimeout(() => {
                this.setState({ sended: true, loading: false });
                this.props.navigate('/profile/candidate/person');
            });
        } catch (error) {
            let responseData = {
                type: 'error',
                title: 'Login inválido',
                text: () => <></>
            };
        
            this.setState({ loading: false, responseData });
        }
    }


    render() {
        return (
            <div className='login unlogged-page'>
                <div className='content'>
                    <div className='block'>
                        {window.innerWidth > 1000 &&
                        <div className='img-container'>
                            <img src={loginImg} />
                        </div>}
                        <div className='block-content'>
                            <div className='text'>
                                <h1>Bem vindo de volta!</h1>
                                <div>
                                    <p>Entre para continuar no</p>
                                    <img src={logoImg} />
                                </div>
                            </div>
                            <div className='form'>
                                <Input
                                name='username'
                                label='Email'
                                placeholder='Insira seu email'
                                onChange={e => this.setState({ form: { ...this.state.form, username: e } })}/>
                                <ErrorMessage errors={this.state.formErrors['username']} />
                                <Input
                                name='password'
                                label='Senha'
                                type='password'
                                placeholder='Insira sua senha'
                                onChange={e => this.setState({ form: { ...this.state.form, password: e } })}/>
                                <ErrorMessage errors={this.state.formErrors['password']} />
                                <div className='extra-questions'>
                                    <div className='remember-me' onClick={() => this.setState({ form: { ...this.state.form, remember_me: !this.state.form.remember_me } })}>
                                        <input type='checkbox' checked={this.state.form.remember_me} />
                                        <p>Lembrar me</p>
                                    </div>
                                    <Link to='/forgot-password'><p>Esqueceu a senha?</p></Link>
                                </div>
                            </div>
                            <SubmitBtn className='secondary' text='Confirmar' loadingText='Enviando...' loading={this.state.loading} onSubmit={this.onSubmit} />
                            <div className='bottom-text'>
                                <p>Ainda não possui uma conta?</p>
                                <Link to='/sign-up'><b>Cadastrar-se</b></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <ResponseModal
                render={this.state.responseData}
                type={this.state.responseData?.type}
                title={this.state.responseData?.title}
                text={this.state.responseData?.text}
                onClose={() => this.setState({ responseData: null })} />
            </div>
        )
    }
}

export default withRouter(Login);
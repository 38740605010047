import { Component } from 'react'
import { Link } from 'react-router-dom';
import { Input } from '../../../../../components/input';
import { Select } from '../../../../../components/select';
import RadioInput from '../../../../../components/radio';
import { RouterProps, withRouter } from '../../../../../../insfrastructure/utils/with-router-component';
import { ErrorMessage, emptyField, errorsLength, formatErrors, validatePasswordConfirmation, validateRegex } from '../../../../../../insfrastructure/utils/form-validators';
import CheckboxInput from '../../../../../components/checkbox';
import { listCities } from '../../../../../../insfrastructure/services/cities';
import candidateImg from '../../../../../../assets/images/candidate.png'
import { StaticValues } from '../../../../../../insfrastructure/services/static_values';
import { createCandidate } from '../../../../../../insfrastructure/services/candidates';
import { SubmitBtn } from '../../../../../components/submit';
import ResponseModal from '../../../../../components/response-modal';
import './styles.sass'

class CandidateForm extends Component<RouterProps> {
  state: any = {
    form: {
      "username": "New User da Silva",
      "cpf": "99999999",
      "email": "ale.mesias+reg_mech@gmail.com",
      "phone": "1231231",
      "date_of_birth": "12/12/2000",
      "password": "supersafepass",
      "gender": "male",
      "marital_status": "single",
      "salary_expectation": '0',
      "city_id": 0,
      "escola_do_mecanico_id": 0
    },
    formErrors: {},
    loading: false,
    cityOptions: [],
    em_units: [],
    cities: []
  }
  citiesSearchTimeout: any = null

  componentDidMount() {
    this.getStaticValues();
  }

  getStaticValues = async () => {
    try {  
      const staticValues = JSON.parse(localStorage.getItem('static_values')+'') as StaticValues;
  
      const em_units = Object.values(staticValues.em_unit).map((x: any) => ({ value: x.branch_number, label: x.name }));
  
      this.setState({ em_units });
    } catch (error) {}
  }

  onCityNameSearch = async (e: any) => {
    clearTimeout(this.citiesSearchTimeout);
    
    this.citiesSearchTimeout = setTimeout(async () => {
      if (e.length < 5) {
        return;
      };

      const cities = (await listCities(e));
      const cityOptions = cities.map(x => ({ value: parseInt(x.id+''), label: x.displayName }));

      this.setState({ cities, cityOptions });
    }, 1000);
  }

  onSelectCity = async (city_id: any) => {
    const city = this.state.cities.filter((x: any) => x.id === city_id+'')[0];

    console.log(city);

    this.setState({
      form: {
        ...this.state.form,
        city_id,
        city_uf: city ? city.uf : ''
      }
    });
  }

  onSubmit = async () => {
    const errors = formatErrors({
      username: [emptyField(this.state.form.username, 'string'), validateRegex(this.state.form.username, /^[A-Za-z0-9@/./+/-/\-_]+$/, 'Nome do usuário inválido')], 
      cpf: [emptyField(this.state.form.cpf, 'string')], 
      email: [emptyField(this.state.form.email, 'string')], 
      phone: [emptyField(this.state.form.phone, 'string')], 
      date_of_birth: [emptyField(this.state.form.date_of_birth, 'string')], 
      password: [emptyField(this.state.form.password, 'string')], 
      password_confirmation: [emptyField(this.state.form.username, 'string'), validatePasswordConfirmation(this.state.form.password, this.state.form.password_confirmation)], 
      gender: [emptyField(this.state.form.gender, 'string')], 
      marital_status: [emptyField(this.state.form.marital_status, 'string')], 
      salary_expectation: [emptyField(parseFloat(this.state.form.salary_expectation.replace('R$ ', '').replace(',', '.'))+'', 'number')], 
      city_id: [emptyField(this.state.form.city_id, 'number')]
    });

    this.setState({ formErrors: errors });

    if(errorsLength(errors) > 0) return;

    try {
      this.setState({ loading: true });

      await createCandidate(this.state.form);

      setTimeout(() => {  
        this.setState({ loading: false });
        this.props.navigate('/sign-up/candidate/confirmation/request', { state: this.state.form });
      });
    } catch (error: any) {
      console.log(error.response.data);

      let errors: string[] = [];

      if (error.response.data['email']) {
        errors.push('Email já cadastrado');
      };
      
      if (error.response.data['username']) {
        errors.push('Nome de usuário já cadastrado');
      };

      let responseData = {
        type: 'error',
        title: 'Erro ao cadastrar',
        text: () => <>
          { errors.map(error => <p>{error}</p>) }
        </>
      };

      this.setState({ loading: false, responseData });

      console.log(error);
    };
  }

  render() {
    return (
      <div className='candidate-sign-up-form'>
        <h3>Dados Candidato</h3>
        <div className='columns'>
          <div className='column'>
          <Input
            name='username'
            label='Nome de usuário'
            value={this.state.form['username']}
            onChange={e => this.setState({ form: { ...this.state.form, username: e } })}/>
            <ErrorMessage errors={this.state.formErrors['username']} />
            <Input
            name='phone'
            label='Celular'
            value={this.state.form['phone']}
            onChange={e => this.setState({ form: { ...this.state.form, phone: e } })}/>
            <ErrorMessage errors={this.state.formErrors['phone']} />
            <Input
            name='date_of_birth'
            label='Data Nascimento'
            type='date'
            value={this.state.form['date_of_birth']}
            onChange={e => this.setState({ form: { ...this.state.form, date_of_birth: e } })}/>
            <ErrorMessage errors={this.state.formErrors['date_of_birth']} />
            <Select
            name='marital_status'
            label='Estado Civil'
            value={this.state.form['marital_status']}
            options={[
              {
                value: 'single',
                label: 'Solteiro'
              },
              {
                value: 'married',
                label: 'Casado'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, marital_status: e } })}/>
            <ErrorMessage errors={this.state.formErrors['marital_status']} />
            <Select
            name='city_id'
            label='Cidade'
            options={this.state.cityOptions}
            value={this.state.form.city_id}
            hasSearch={true}
            onSearchChange={e => this.onCityNameSearch(e)}
            onChange={e => {this.setState({ form: { ...this.state.form, city_id: e } }); this.onSelectCity(e);}}/>
            <ErrorMessage errors={this.state.formErrors['city_id']} />
            <Input
            name='password'
            label='Senha'
            type='password'
            value={this.state.form.password}
            onChange={e => this.setState({ form: { ...this.state.form, password: e } })}/>
            <ErrorMessage errors={this.state.formErrors['password']} />
            <div className='terms-and-conditions-container'>
              <Link to=''><p>Política de privacidade</p></Link>
              <CheckboxInput
              name='terms_and_conditions'
              single={true}
              value={this.state.form.terms_and_conditions}
              options={[
                {
                  label: 'Aceito a política de privacidade',
                  value: 'terms_and_conditions'
                }
              ]}
              onChange={e => this.setState({ form: { ...this.state.form, terms_and_conditions: e } })}/>
              <ErrorMessage errors={this.state.formErrors['terms_and_conditions']} />
            </div>
          </div>
          <div className='column'>
            <Input
            name='cpf'
            label='CPF'
            value={this.state.form['cpf']}
            onChange={e => this.setState({ form: { ...this.state.form, cpf: e } })}/>
            <ErrorMessage errors={this.state.formErrors['cpf']} />
            <Input
            name='email'
            label='Email'
            value={this.state.form['email']}
            onChange={e => this.setState({ form: { ...this.state.form, email: e } })}/>
            <ErrorMessage errors={this.state.formErrors['email']} />
            <Select
            name='gender'
            label='Gênero'
            value={this.state.form['gender']}
            options={[
              {
                value: 'male',
                label: 'Masculino'
              },
              {
                value: 'female',
                label: 'Feminino'
              },
              {
                value: 'other',
                label: 'Outro'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, gender: e } })}/>
            <ErrorMessage errors={this.state.formErrors['gender']} />
            <Input
            name='salary_expectation'
            label='Pretenção Salarial'
            type='money'
            value={this.state.form['salary_expectation']}
            onChange={e => this.setState({ form: { ...this.state.form, salary_expectation: e } })}/>
            <ErrorMessage errors={this.state.formErrors['salary_expectation']} />
            <Input
            name='city_uf'
            label='Estado'
            value={this.state.form.city_uf}
            readonly={true}
            onChange={e => this.setState({ form: { ...this.state.form, city_uf: e } })}/>
            <ErrorMessage errors={this.state.formErrors['city_uf']} />
            <Input
            name='password_confirmation'
            label='Confirmar Senha'
            type='password'
            onChange={e => this.setState({ form: { ...this.state.form, password_confirmation: e } })}/>
            <ErrorMessage errors={this.state.formErrors['password_confirmation']} />
          </div>
          <div className='column'>
            <img src={candidateImg} alt='candidate' />
            <RadioInput
            name='is_studant'
            label='Aluno da Escola do Mecânico?'
            options={[
              {
                label: 'Sim',
                value: 'yes'
              },
              {
                label: 'Não',
                value: 'no'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, is_studant: e, escola_do_mecanico_id: 0 } })}/>
            {this.state.form.is_studant === 'yes' &&
            <>
              <Select
              name='escola_do_mecanico_id'
              label='Unidade da Escola do Mecânico'
              placeholder='Unidade da Escola do Mecânico'
              value={this.state.form['escola_do_mecanico_id']}
              options={this.state.em_units}
              onChange={e => this.setState({ form: { ...this.state.form, escola_do_mecanico_id: e } })}/>
              <ErrorMessage errors={this.state.formErrors['escola_do_mecanico_id']} />
            </>}
          </div>
        </div>
        <SubmitBtn className='secondary' text='Confirmar' loadingText='Enviando...' loading={this.state.loading} onSubmit={this.onSubmit} />
        <ResponseModal
        render={this.state.responseData}
        type={this.state.responseData?.type}
        title={this.state.responseData?.title}
        text={this.state.responseData?.text}
        onClose={() => this.setState({ responseData: null })} />
      </div>
    )
  }
}

export const CandidateFormWithRouter = withRouter(CandidateForm);
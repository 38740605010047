import { Component } from 'react'
import Modal from '../../../../components/modal'
import { SubmitBtn } from '../../../../components/submit'
import calendarIcon from '../../../../../assets/icons/calendar-blue.png'
import mailIcon from '../../../../../assets/icons/mail.png'
import whatsappIcon from '../../../../../assets/icons/whatsapp.png'
import { Input } from '../../../../components/input'
import './styles.sass'

interface ModalProps {
    render: boolean,
    candidate: any,
    onClose: () => void,
    onSubmitted: () => void
}

export default class IntervalModal extends Component<ModalProps> {
    state: any = {
        form: {},
        formErrors: {},
        loading: false
    }

    onSubmit = async () => {
        if(this.state.loading) return;

        try {
            this.setState({ loading: true });

        } catch (error) {
            
        };

        setTimeout(() => {
            this.setState({ loading: false });
            this.props.onSubmitted();
        }, 500);
    }

    render() {
        return (
        <Modal render={this.props.render} onClose={() => this.props.onClose()}>
            <div className='interview-modal'>
                <div className='modal-header'>
                    <img src={calendarIcon} />
                    <h4>Chamar <b>{this.props.candidate.name}</b> para uma entrevista</h4>
                </div>
                <form>
                    <div className='contact-selector'>
                        <label>Selecione um contato:</label>
                        <span 
                        className={'contact '+(this.state.form.contact === 'mail' ? 'selected' : '')}
                        onClick={() => this.setState({ form: { ...this.state.form, contact: 'mail' } })}>
                            {this.props.candidate.email}
                            <img src={mailIcon} />
                        </span>
                        <span 
                        className={'contact '+(this.state.form.contact === 'phone' ? 'selected' : '')}
                        onClick={() => this.setState({ form: { ...this.state.form, contact: 'phone' } })}>
                            {this.props.candidate.phone}
                            <img src={whatsappIcon} />
                        </span>
                    </div>
                    <Input
                    name='datetime'
                    label='Data e Hora:'
                    showTimeSelect={true}
                    type='date'
                    value={this.state.form.datetime}
                    onChange={(e) => {
                        console.log(e);
                        this.setState({ form: { ...this.state.form, datetime: e }});
                    }}/>
                </form>
                <SubmitBtn
                className='primary'
                text='Agendar entrevista'
                loadingText='Agendando entrevista...'
                onSubmit={this.onSubmit}
                loading={this.state.loading} />
            </div>
        </Modal>
        )
    }
}

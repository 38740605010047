import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import facebook from '../../../assets/images/facebook.png'
import instagram from '../../../assets/images/instagram.png'
import youtube from '../../../assets/images/youtube.png'
import linkedin from '../../../assets/images/linkedin.png'
import facebookBlue from '../../../assets/images/facebook-blue.png'
import instagramBlue from '../../../assets/images/instagram-blue.png'
import youtubeBlue from '../../../assets/images/youtube-blue.png'
import linkedinBlue from '../../../assets/images/linkedin-blue.png'
import './styles.sass'

export const Footer = () => {
  const location = useLocation()
  const[isCompanyLayout, toggleIsCompanyLayout] = useState(false)

  useEffect(() => {
    toggleIsCompanyLayout(document.querySelector('.company-page') ? true : false)
  }, [location])
  

  return (
    <footer className={isCompanyLayout ? 'reserve-bg' : ''}>
      <div className='content'>
        <div className='column'>
          <p>siga o <b>Emprega Mecânico</b><br/> nas redes sociais</p>
          <div className='social-medias'>
            <Link to='' target='_blank'><img src={isCompanyLayout ? facebookBlue: facebook} /></Link>
            <Link to='' target='_blank'><img src={isCompanyLayout ? instagramBlue: instagram} /></Link>
            <Link to='' target='_blank'><img src={isCompanyLayout ? youtubeBlue: youtube} /></Link>
            <Link to='' target='_blank'><img src={isCompanyLayout ? linkedinBlue: linkedin} /></Link>
          </div>
        </div>
        <div className='column'>
          <p><b>Emprega Mecânico ®️</b></p>
          <p>Powered by Cinco2Zero</p>
        </div>
        <div className='column'>
          <h3>Suporte</h3>
          <Link to=''><p>FAQ's</p></Link>
          <Link to=''><p>Política de Privacidade</p></Link>
        </div>
      </div>
    </footer>
  )
}

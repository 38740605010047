import React, { Component } from 'react'
import './styles.sass'

export default class Table extends Component<{
    header: any,
    body: (data: any[]) => JSX.Element[],
    data: any[],
    total: number,
    offset?: number,
    page?: number,
    limit: number,
    onNavigate?: ({ page, offset }: { page: number, offset: number }) => void
}> {

    state: Record<string, any> = {
        page: 1,
        offset: 0,
        allPages: []
    }

    componentDidMount() {
        if (this.props.page) {
            this.setState({ page: this.props.page, offset: this.state.page * this.props.limit });
        };

        if (this.props.offset) {
            this.setState({ offset: this.state.offset, page: (this.state.offset/this.props.limit) + 1 });
        };

        this.setState({ allPages: Array.from(Array(Math.round(this.props.total/this.props.limit)).keys()) });

        // console.log(Array.from(Array(Math.round(this.props.total/this.props.limit)).keys()));

        // setTimeout(() => {
        //     console.log(
        //         this.state.allPages, this.state.allPages.at(-1),
        //         Math.round(this.state.page/this.props.limit) * this.props.limit,
        //         (Math.round(this.state.page/this.props.limit) * this.props.limit) + this.props.limit);
        // }, 1000);
        
    }

    componentDidUpdate(prevProps: any, prevState: Readonly<{}>, snapshot?: any): void {
        if (this.props.page !== prevProps.page) {
            this.setState({ page: this.props.page, offset: this.state.page * this.props.limit });
        };

        if (this.props.offset !== prevProps.offset) {
            this.setState({ offset: this.state.offset, page: (this.state.offset/this.props.limit) + 1 });
        };
    }

    render() {
        return (
            <div className='table'>
                <table cellSpacing={0}>
                    {this.props.header}
                    <tbody>
                        {this.props.body(this.props.data)}
                    </tbody>
                </table>
                <div className='paginator'>
                    {
                        this.state.page > 1 &&
                        <>
                            <span 
                            onClick={() => {
                                this.props.onNavigate && this.props.onNavigate({
                                    page: 1,
                                    offset: 0
                                });
                            }}>{'<<'}</span>
                            <span 
                            onClick={() => {
                                this.props.onNavigate && this.props.onNavigate({
                                    page: this.state.page - 1,
                                    offset: this.state.offset - this.props.limit
                                });
                            }}>{'<'}</span>
                        </>
                    }
                    {
                        this.state.allPages
                        .slice(
                            Math.floor(this.state.page/(this.props.limit + 1)) * this.props.limit,
                            (Math.floor(this.state.page/(this.props.limit + 1)) * this.props.limit) + this.props.limit
                        ).map((index: number) => 
                        <span
                        className={'page-index '+(this.state.page === index + 1 ? 'selected' : '')}
                        style={{ fontWeight: this.state.page === index + 1 ? 'bold' : '' }}
                        onClick={() => {
                            this.props.onNavigate && this.props.onNavigate({
                                page: index +1,
                                offset: index * this.props.limit
                            });
                        }}>{index+1}</span>)
                    }
                    {
                        this.state.page < this.state.allPages.length &&
                        <>
                            <span 
                            onClick={() => {
                                this.props.onNavigate && this.props.onNavigate({
                                    page: this.state.page + 1,
                                    offset: this.state.offset + this.props.limit
                                });
                            }}>{'>'}</span>
                            <span 
                            onClick={() => {
                                this.props.onNavigate && this.props.onNavigate({
                                    page: this.state.allPages.length as number,
                                    offset: (this.state.allPages.length as number) * this.props.limit
                                });
                            }}>{'>>'}</span>
                        </>
                    }
                </div>
            </div>
        )
    }
}

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Header } from './presenters/components/header';
import { Footer } from './presenters/components/footer';
import { router } from './Routes';
import { createContext, useEffect, useState } from 'react';
import { loadStaticValues } from './insfrastructure/services/static_values';
import { retrieveCandidate } from './insfrastructure/services/candidates';
import { parseJwt } from './insfrastructure/utils/JWTParser';
import './styles/main.sass';


const loggedRoutes = [
    '/offers',
    '/job-subscriptions',
    '/candidates',
    '/profile'
];

axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('candidate_id');
      localStorage.removeItem('auth_token');
      sessionStorage.clear();
      if (loggedRoutes.filter(route => window.location.pathname.includes(route)).length > 0) {
        router.navigate('/login');
      };
      
      return Promise.reject(error)
    }

    // const originalRequest = error.config;
    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true
    //   const refreshToken = localStorageService.getRefreshToken()
    //   return axios
    //     .post('/auth/token', {
    //       refresh_token: refreshToken
    //     })
    //     .then(res => {
    //       if (res.status === 201) {
    //         localStorageService.setToken(res.data)
    //         axios.defaults.headers.common['Authorization'] =
    //           'Bearer ' + localStorageService.getAccessToken()
    //         return axios(originalRequest)
    //       }
    //     })
    // }
    return Promise.reject(error)
  }
)

export const UserdataContext = createContext<any>(null);

export default function App() {
  const [staticValues, setStaticValues] = useState<any>(null)
  const [userData, setUserData] = useState<any>(null)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    loadStaticValues().then(staticValues => {
      localStorage.setItem('static_values', JSON.stringify(staticValues));
      setStaticValues(staticValues);
    });
  }, [])

  useEffect(() => {
    checkLogin();
  }, [location])

  const checkLogin = async () => {
      const user_id = localStorage.getItem('user_id');
      const auth_token = localStorage.getItem('auth_token');

      if(!user_id || !auth_token) {
        onLogout();

        return;
      };

      try {
          let userdata = await retrieveCandidate();
          userdata.usertype = parseJwt(auth_token).profiles[0];
          setUserData(userdata);
      } catch (error) {
          onLogout();
      };
  }

  const onLogout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('candidate_id');
    localStorage.removeItem('auth_token');
    sessionStorage.clear();
    setUserData(null);
    if (loggedRoutes.filter(route => location.pathname.includes(route)).length > 0) {
      navigate('/login');  
    };
  }
  
  return (
    <UserdataContext.Provider value={{userData, setUserData}}>
      {staticValues &&
      <div className="page">
        <Header />
        <div className='page-content'>
          <Outlet />
        </div>
        <Footer/>
      </div>}
    </UserdataContext.Provider>
  )
}

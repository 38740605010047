import axios from "axios";
import { environment } from "../../environments/environment";
import { ListResponse } from "./utils";

export interface User {
    username: string,
    email: string,
    first_name: string
}

export interface Candidate {
    user: User,
    email: string,
    gender: string,
    date_of_birth: string,
    marital_status: string,
    education_level: string,
    disability: string,
    salary_expectation: number,
    cpf: string,
    rg: string,
    phone: string,
    mobile: string,
    status: string,
    address: string,
    city: string,
    cnh_category: string,
    usertype?: string
}

export interface CreateCandidate {
    username: string,
    cpf: string,
    email: string,
    phone: string,
    date_of_birth: string,
    password: string,
    gender: string,
    marital_status: string,
    salary_expectation: number,
    city_id: number,
    escola_do_mecanico_id: number
}

// const candidateMock: Candidate = {
//     "user": {
//         "username": "ademiralvessena@gmail.com",
//         "email": "ademiralvessena@gmail.com",
//         "first_name": "Ademir Alvez Sena"
//     },
//     "email": "ademiralvessena@gmail.com",
//     "gender": "male",
//     "date_of_birth": "1970-02-21",
//     "marital_status": "none",
//     "education_level": "none",
//     "disability": "none",
//     "salary_expectation": 0.0,
//     "cpf": "14048106848",
//     "rg": "207502213",
//     "phone": "0",
//     "mobile": "11-9.4050-1684",
//     "status": "ATIVO",
//     "address": "Rua:sergipe, 297 - cidade são jorge",
//     "city": "Santo André, SP",
//     "cnh_category": "none"
// };

export const createCandidate = async (data: CreateCandidate): Promise<any> => {
    const response = await axios.post<any>(environment.apiUrl+'/api/register/', data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status !== 201) {
        throw Error('error');
    };

    return response.data;
};

export const retrieveCandidate = async () => {
    const response = await axios.get<Candidate>(environment.apiUrl+'/api/candidate/'+localStorage.getItem('candidate_id'), {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
};

export class CandidateService {
    auth_token = ''

    constructor() {
        this.auth_token = 'Bearer '+localStorage.getItem('auth_token');
    }

    retrieve = async () => {
        const response = await axios.get<Candidate>(environment.apiUrl+'/api/candidate/'+localStorage.getItem('candidate_id'), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.auth_token
            }
        });
    
        if(response.status !== 200) {
            throw Error('error');
        };
    
        return response.data;
    };

    update = async (id: string, data: Candidate) => {
        const response = await axios.put<Candidate>(environment.apiUrl+'/api/candidate/'+id, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.auth_token
            }
        });
    
        if(response.status !== 200) {
            throw Error('error');
        };
    
        return response.data;
    };

    list = async () => {
        const response = await axios.get<ListResponse<Candidate>>(environment.apiUrl+'/api/candidates/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.auth_token
            }
        });
    
        if(response.status !== 200) {
            throw Error('error');
        };
    
        return response.data;
    };
}

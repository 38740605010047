import { Component } from 'react'
import { Input } from '../../../../components/input'
import { SubmitBtn } from '../../../../components/submit'
import { emptyField, ErrorMessage, errorsLength, formatErrors, validateCEP } from '../../../../../insfrastructure/utils/form-validators'
import { Select } from '../../../../components/select'
import { CandidateService } from '../../../../../insfrastructure/services/candidates'
import { listCities } from '../../../../../insfrastructure/services/cities'
import { PostcodeService } from '../../../../../insfrastructure/services/cep'
import './styles.sass'
import { StaticValues } from '../../../../../insfrastructure/services/static_values'
import ResponseModal from '../../../../components/response-modal'

export default class PersonalForm extends Component {

  state: any = {
    form: {
      email: '',
      password:  ''
    },
    cnh_categories: [],
    formErrors: {},
    loading: false,
    cityOptions: []
  }
  timeout: any = null

  async componentDidMount() {
    try {
      const candidateService = new CandidateService();
            
      const candidate = await candidateService.retrieve();

      this.setState({ form: candidate }, () => {
        this.onCEPChange(this.state.form.postcode);
      });
    } catch (error) {}

    this.getStaticValues();
  }

  getStaticValues = async () => {
    try {  
      setTimeout(() => {
        const staticValues = JSON.parse(localStorage.getItem('static_values')+'') as StaticValues;
        const cnh_categories = Object.keys(staticValues.cnh_category).map((key: any) => ({ value: key, label: staticValues.cnh_category[key] }));
        
        console.log({ cnh_categories });
        this.setState({ cnh_categories });
      }, 1000);
    } catch (error) {}
  }

  onSubmit = async () => {
    const errors = formatErrors({
      full_name: [emptyField(this.state.form.full_name, 'string')],
      phone: [emptyField(this.state.form.phone, 'string')]
    });

    this.setState({ formErrors: errors });
  
    if(errorsLength(errors) > 0) return;

    try {
      this.setState({ loading: true });
  
      let data = {...this.state.form};
  
      if (data.date_of_birth && typeof data.date_of_birth === 'object') {
        const offset = data.date_of_birth.getTimezoneOffset()
        data.date_of_birth = new Date(data.date_of_birth.getTime() - (offset*60*1000))
        data.date_of_birth = data.date_of_birth.toISOString().split('T')[0]
      };
        
      if (data.cnh_validity && typeof data.cnh_validity === 'object') {
        const offset = data.cnh_validity.getTimezoneOffset()
        data.cnh_validity = new Date(data.cnh_validity.getTime() - (offset*60*1000))
        data.cnh_validity = data.cnh_validity.toISOString().split('T')[0]
      };
  
      const candidateService = new CandidateService();
              
      const candidate = await candidateService.update(localStorage.getItem('candidate_id')+'', data);

      let responseData = {
        type: 'success',
        title: 'Dados atualizados com sucesso!',
        text: () => <></>
      };
        
      setTimeout(() => {
        this.setState({ loading: false, form: candidate, responseData });
      });
    } catch (error: any) {
      let errors: string[] = [];

      if (error.response.data['email']) {
        errors.push('Email já cadastrado');
      };
      
      if (error.response.data['username']) {
        errors.push('Nome de usuário já cadastrado');
      };

      let responseData = {
        type: 'error',
        title: 'Erro ao atualizar dados',
        text: () => <>
          { errors.map(error => <p>{error}</p>) }
        </>
      };

      this.setState({ loading: false, responseData });
    }
  }

  onCityNameChange = async (e: any) => {
    const cityOptions = (await listCities(e)).map(x => ({ value: x.id, label: x.displayName }));

    this.setState({ cityOptions });
  }

  onCEPChange = async (value: any) => {
    clearTimeout(this.timeout);
    
    this.timeout = setTimeout(async () => {
      let city_id = '';
      let city_uf = '';

      if(validateCEP(value) === '') {
        const postcodeService = new PostcodeService();
        const result = await postcodeService.retrieve(value);

        this.onCityNameChange(result.localidade);
        city_id = result.city_id + '';
        city_uf = result.uf;
      };
  
      this.setState({
        form: {
          ...this.state.form,
          city_id,
          city_uf
        }
      });
    }, 1000);
  }

  render() {
    return (
      <div className='personal-form'>
        <h1>Dados Pessoais</h1>
        <div className='columns'>
          <div className='column'>
            <Input
            name='full_name'
            label='Nome Completo'
            value={this.state.form['full_name']}
            onChange={e => this.setState({ form: { ...this.state.form, full_name: e } })}/>
            <ErrorMessage errors={this.state.formErrors['full_name']} />
            <Input
            name='phone'
            label='Celular'
            value={this.state.form['phone']}
            onChange={e => this.setState({ form: { ...this.state.form, phone: e } })}/>
            <ErrorMessage errors={this.state.formErrors['phone']} />
            <Input
            name='date_of_birth'
            label='Data Nascimento'
            type='date'
            value={this.state.form['date_of_birth']}
            onChange={e => this.setState({ form: { ...this.state.form, date_of_birth: e } })}/>
            <ErrorMessage errors={this.state.formErrors['date_of_birth']} />
            <Select
            name='gender'
            label='Gênero'
            value={this.state.form['gender']}
            options={[
              {
                value: 'male',
                label: 'Masculino'
              },
              {
                value: 'female',
                label: 'Feminino'
              },
              {
                value: 'other',
                label: 'Outro'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, gender: e } })}/>
            <ErrorMessage errors={this.state.formErrors['gender']} />
          </div>
          <div className='column'>
            <Input
            name='salary_expectation'
            label='Pretenção Salarial'
            value={this.state.form['salary_expectation']}
            onChange={e => this.setState({ form: { ...this.state.form, salary_expectation: e } })}/>
            <ErrorMessage errors={this.state.formErrors['salary_expectation']} />
            <Select
            name='marital_status'
            label='Estado Civil'
            value={this.state.form['marital_status']}
            options={[
              {
                value: 'single',
                label: 'Solteiro'
              },
              {
                value: 'married',
                label: 'Casado'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, marital_status: e } })}/>
            <ErrorMessage errors={this.state.formErrors['marital_status']} />
            <Select
              name='education_level'
              label='Escolaridade'
              placeholder='Escolaridade'
              value={this.state.form['education_level']}
              options={[
                { value: "alfabet", label: "alfabetizado"},
                { value: "non_alfabet", label: "não alfabetizado"},
                { value: "primary", label: "ensino fundamental"},
                { value: "under_primary", label: "ensino fundamental incompleto"},
                { value: "secondary", label: "ensino médio"},
                { value: "under_secondary", label: "ensino médio incompleto"},
                { value: "graduate", label: "ensino superior completo"},
                { value: "under_graduate", label: "ensino superior incompleto"},
                { value: "post_graduate", label: "Pós-Graduação"},
                { value: "technical", label: "Ensino Técnico"},
                { value: "doctorate", label: "doutorado"},
                { value: "masters", label: "mestrado"},
                { value: "none", label: "não informado"},
                { value: "other", label: "outro"}
              ]}
              onChange={e => this.setState({ form: { ...this.state.form, education_level: e } })}/>
              <ErrorMessage errors={this.state.formErrors['education_level']} />
            <Select
            name='disability'
            label='Deficiência'
            value={this.state.form['disability']}
            options={[
              {
                label: 'Deficiência física',
                value: 'Deficiência física'
              },
              {
                label: 'Deficiência auditiva e surdez',
                value: 'Deficiência auditiva e surdez'
              },
              {
                label: 'Deficiência visual',
                value: 'Deficiência visual'
              },
              {
                label: 'Deficiência intelectual/mental',
                value: 'Deficiência intelectual/mental'
              },
              {
                label: 'Deficiência múltipla',
                value: 'Deficiência múltipla'
              },
              {
                label: 'Mobilidade reduzida',
                value: 'Mobilidade reduzida'
              },
              {
                label: 'Outro',
                value: 'Outro'
              },
              {
                label: 'Não tenho deficiência',
                value: 'no_disability'
              }
            ]}
            onChange={e => this.setState({ form: { ...this.state.form, disability: e } })}/>
            {this.state.form.disability === 'Outro' &&
            <>
              <Input
              name='expecific_disability'
              label='Expecificar Deficiência'
              onChange={e => this.setState({ form: { ...this.state.form, expecific_disability: e } })}/>
              <ErrorMessage errors={this.state.formErrors['expecific_disability']} />
            </>}
          </div>
        </div>
        <div className='columns'>
          <div className='column'>
            <Input
            name='postcode'
            label='CEP'
            mask='99999-999'
            value={this.state.form.postcode}
            onChange={e => { this.setState({ form: { ...this.state.form, postcode: e } }); this.onCEPChange(e); }}/>
            <ErrorMessage errors={this.state.formErrors['postcode']} />
            <Select
            name='city_id'
            label='Cidade'
            options={this.state.cityOptions}
            value={this.state.form.city_id}
            readonly={true}
            onSearchChange={e => this.onCityNameChange(e)}
            onChange={e => this.setState({ form: { ...this.state.form, city_id: e } })}/>
            <ErrorMessage errors={this.state.formErrors['city_id']} />
            {/* <Input
            name='number'
            label='Número'
            value={this.state.form.number}
            onChange={e => this.setState({ form: { ...this.state.form, number: e } })}/>
            <ErrorMessage errors={this.state.formErrors['number']} /> */}
            <Input
            name='address_complement'
            label='Complemento'
            value={this.state.form.address_complement}
            onChange={e => this.setState({ form: { ...this.state.form, address_complement: e } })}/>
            <ErrorMessage errors={this.state.formErrors['address_complement']} />
          </div>
          <div className='column'>
            <Input
            name='city_uf'
            label='Estado'
            value={this.state.form.city_uf}
            readonly={true}
            onChange={e => this.setState({ form: { ...this.state.form, city_uf: e } })}/>
            <ErrorMessage errors={this.state.formErrors['city_uf']} />
            <Input
            name='address'
            label='Endereço'
            value={this.state.form.address}
            onChange={e => this.setState({ form: { ...this.state.form, address: e } })}/>
            <ErrorMessage errors={this.state.formErrors['address']} />
          </div>
        </div>

        <div className='columns'>
          <div className='column'>
            <Input
            name='cnh_number'
            label='CNH'
            value={this.state.form.cnh_number}
            onChange={e => this.setState({ form: { ...this.state.form, cnh_number: e } })}/>
            <ErrorMessage errors={this.state.formErrors['cnh_number']} />
            <Input
            name='cnh_validity'
            label='Validade CNH'
            type='date'
            value={this.state.form.cnh_validity}
            onChange={e => this.setState({ form: { ...this.state.form, cnh_validity: e } })}/>
            <ErrorMessage errors={this.state.formErrors['cnh_validity']} />
          </div>
          <div className='column'>
            <Select
            name='cnh_category'
            label='Categoria CNH'
            value={this.state.form.cnh_category}
            options={this.state.cnh_categories}
            onChange={e => this.setState({ form: { ...this.state.form, cnh_category: e } })}/>
            <ErrorMessage errors={this.state.formErrors['cnh_category']} />
          </div>
        </div>
        <SubmitBtn className='secondary' text='Salvar Alterações' loadingText='Salvando...' loading={this.state.loading} onSubmit={this.onSubmit} />
        <ResponseModal
        render={this.state.responseData}
        type={this.state.responseData?.type}
        title={this.state.responseData?.title}
        text={this.state.responseData?.text}
        onClose={() => this.setState({ responseData: null })} />
      </div>
    )
  }
}

import { Component } from 'react'
import { Input } from '../../../../components/input'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../../insfrastructure/utils/form-validators'
import CheckboxInput from '../../../../components/checkbox'
import addImg from '../../../../../assets/icons/add.png'
import moneyImg from '../../../../../assets/icons/money.svg'
import { Select } from '../../../../components/select'
import BenefitsList from './benefits-list'
import './styles.sass'

interface Props {
  inital_data?: any,
  onSubmit: (data: any) => void
}

export default class Benefits extends Component<Props> {
  state: any = {
    form: {
      wage: null,
      wage_to_resolve: null,
      benefits: []
    },
    benefit_options: [
      {
        label: 'Comissão',
        value: 'Comissão',
        inputs: [
          {
            label: 'De',
            type: 'money',
            value: 'R$'
          },
          {
            label: 'À',
            type: 'money',
            value: 'R$'
          }
        ]
      },
      {
        label: 'Vale Refeição',
        value: 'Vale Refeição',
        inputs: [
          {
            type: 'money',
            value: 'R$'
          }
        ]
      },
      {
        label: 'Vale Alimentação',
        value: 'Vale Alimentação',
        inputs: [
          {
            type: 'money',
            value: 'R$'
          }
        ]
      },
      {
        label: 'Vale Transporte',
        value: 'Vale Transporte',
      },
      {
        label: 'Plano Odontológico',
        value: 'Plano Odontológico',
      },
      {
        label: 'Plano de Saúde',
        value: 'Plano de Saúde',
      },
      {
        label: 'Seguro de Vida',
        value: 'Seguro de Vida',
      },
      {
        label: 'Bolsa de Estudos',
        value: 'Bolsa de Estudos',
      },
      {
        label: 'Gym Pass',
        value: 'Gym Pass',
      },
      {
        label: 'Bônus',
        value: 'Bônus',
      },
      {
        label: 'PLR',
        value: 'PLR',
      },
      {
        label: 'Cesta Básica',
        value: 'Cesta Básica',
      }
    ],
    show_new_benefit_form: false,
    new_benefit: {},
    new_benefit_form_errors: {},
    formErrors: {}
  }

  componentDidMount() {
    if(this.props.inital_data) {
      this.setState({ form: this.props.inital_data });
    };
  }

  toggleBenefitForm = () => {
    this.setState({ show_new_benefit_form: !this.state.show_new_benefit_form, new_benefit: {} });
  }

  onSubmitBenefit = () => {
    const errors = formatErrors({
      name: [emptyField(this.state.new_benefit.name, 'string')]
    });

    this.setState({ new_benefit_form_errors: errors });

    if(errorsLength(errors) > 0) return;

    this.setState({ 
      benefit_options: [ ...this.state.benefit_options, { label: this.state.new_benefit.name, value: this.state.new_benefit.name } ], 
      show_new_benefit_form: false,
      new_benefit: null
    });
  }

  onSubmit = () => {
    const errors = formatErrors({
      wage: this.state.form.wage_to_resolve ? [] : [emptyField(this.state.form.wage, 'string')],
      wage_range: this.state.form.wage_to_resolve ? [] : [emptyField(this.state.form.wage_range, 'string')]
    });

    this.setState({ formErrors: errors });

    if(errorsLength(errors) > 0) return;

    this.props.onSubmit(this.state.form);
  }

  render() {
    return (
      <div className='benefits'>
        <div className='salary-section'>
          <div className='wage-inputs'>
            <div className='wage-input-container'>
              <Input
              name='wage'
              type='money'
              label='Salário'
              icon={moneyImg}
              value={this.state.form.wage}
              disabled={this.state.form.wage_to_resolve}
              onChange={e => this.setState({ form: { ...this.state.form, wage: e } })}/>
              <ErrorMessage errors={this.state.formErrors['wage']} />
            </div>
            <div className='wage-input-container'>
              <Select
              name='wage_range'
              label='Faixa Salárial'
              icon={moneyImg}
              value={this.state.form.wage_range}
              options={[
                {
                  value: 'Até R$ 1500',
                  label: 'Até R$ 1500'
                },
                {
                  value: 'Entre R$ 1501 e R$ 2000',
                  label: 'Entre R$ 1501 e R$ 2000'
                },
                {
                  value: 'Entre R$ 2001 e R$ 2500',
                  label: 'Entre R$ 2001 e R$ 2500'
                },
                {
                  value: 'Entre R$ 2501 e R$ 3000',
                  label: 'Entre R$ 2501 e R$ 3000'
                },
                {
                  value: 'Entre R$ 3001 e R$ 3500',
                  label: 'Entre R$ 3001 e R$ 3500'
                },
                {
                  value: 'Entre R$ 3501 e R$ 4000',
                  label: 'Entre R$ 3501 e R$ 4000'
                },
                {
                  value: 'Entre R$ 4001 e R$ 4500',
                  label: 'Entre R$ 4001 e R$ 4500'
                },
                {
                  value: 'Entre R$ 4501 e R$ 5000',
                  label: 'Entre R$ 4501 e R$ 5000'
                },
                {
                  value: 'Entre R$ 5001 e R$ 6000',
                  label: 'Entre R$ 5001 e R$ 6000'
                },
                {
                  value: 'Entre R$ 5001 e R$ 6000',
                  label: 'Entre R$ 5001 e R$ 6000'
                },
                {
                  value: 'Entre R$ 6001 e R$ 7000',
                  label: 'Entre R$ 6001 e R$ 7000'
                },
                {
                  value: 'Entre R$ 7001 e R$ 8000',
                  label: 'Entre R$ 7001 e R$ 8000'
                },
                {
                  value: 'Entre R$ 8001 e R$ 9000',
                  label: 'Entre R$ 8001 e R$ 9000'
                },
                {
                  value: 'Entre R$ 9001 e R$ 10000',
                  label: 'Entre R$ 9001 e R$ 10000'
                },
                {
                  value: 'Acima de R$ 10001',
                  label: 'Acima de R$ 10001'
                }
              ]}
              disabled={this.state.form.wage_to_resolve}
              onChange={e => this.setState({ form: { ...this.state.form, wage_range: e } })}/>
              <ErrorMessage errors={this.state.formErrors['wage_range']} />
            </div>
          </div>
          <CheckboxInput
          name='wage_to_resolve'
          single={true}
          options={[
            {
              label: 'Salário à combinar',
              value: 'yes'
            }
          ]}
          value={this.state.form.wage_to_resolve}
          disabled={this.state.form.wage_to_resolve}
          onChange={e => this.setState({ form: { ...this.state.form, wage_to_resolve: e, wage: '', wage_range: '' } })}/>
        </div>
        <div className='benefits-section'>
          <div className='benefits-section-header'>
            <h4>Benefícios</h4>
            <p>Se o campo de valor do benefício não for informado, o candidato visualizará como “valor do benefício: a combinar”</p>
          </div>
          <BenefitsList
          name='benefits'
          options={this.state.benefit_options}
          value={this.state.form.benefits}
          onChange={e => this.setState({ form: { ...this.state.form, benefits: e } })}/>
          {this.state.show_new_benefit_form &&
          <div className='benefit-form'>
            <Input
            name='name'
            label='Nome'
            onChange={e => this.setState({ new_benefit: { ...this.state.new_benefit, name: e } })}/>
            <ErrorMessage errors={this.state.new_benefit_form_errors['name']} />
            <div className='columns'>
              <button className='secondary' onClick={this.onSubmitBenefit}>Salvar</button>
              <button className='cancel' onClick={this.toggleBenefitForm}>Cancelar</button>
            </div>
          </div>}
          {!this.state.show_new_benefit_form &&
          <button className='secondary toggle-benefit-form' onClick={this.toggleBenefitForm}>
            <img src={addImg} />
            <span>Adicionar Benefício</span>
          </button>}
        </div>
        <button className='primary' onClick={this.onSubmit}>Confirmar</button>
      </div>
    )
  }
}

import { useContext, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserdataContext } from '../../../../App'
import SearchInput from '../../search-input'
import logo from '../../../../assets/images/logo.svg'
import arrow from '../../../../assets/icons/arrow_white.png'
import offers from '../../../../assets/icons/offers.png'
import './styles.sass'


export const DeskHeader = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [showUserMenu, toggleUserMenu] = useState(false)
  const {userData, setUserData} = useContext<any>(UserdataContext)
  const usertypes: any = {
    'candidate': 'Candidato',
    'company': 'Empresa'
  }

  useEffect(() => {
    window.addEventListener('click', (e) => {
      let userDataElement = document.querySelector('.user-data') as HTMLElement;
      if(!userDataElement) return;

      if(userDataElement.contains(e.target as HTMLElement)) {
        userDataElement = document.querySelector('.user-data') as HTMLElement;
        toggleUserMenu(userDataElement.classList.contains('opened') ? false : true);        
      } else {
        toggleUserMenu(false);
      };
    });
  }, [])

  useEffect(() => {
  }, [location])

  const onLogout = () => {
    localStorage.removeItem('user_id');
    localStorage.removeItem('candidate_id');
    localStorage.removeItem('auth_token');
    sessionStorage.clear();
    setUserData(null);
    navigate('/login');
  }
  
  return (
    <header className='desk'>
      <div className='content'>
        <Link to=''><img className='logo' src={logo} alt='logo' /></Link>
        <div className='left-content'>
          <div className='home-links'>
            <Link to='how-to-anounce'>
              <li>Como anunciar</li>
            </Link>
            <Link to='about-us'>
              <li>Sobre nós</li>
            </Link>
            <Link to='success-stories'>
              <li>Histórias</li>
            </Link>
          </div>
          {userData?.usertype === 'candidate' &&
          <div className='offer-links'>
            <Link to='profile/candidate/subscriptions'>
              <li>
                <img src={offers}/>
                <span>Vagas salvas</span>
              </li>
            </Link>
            <Link to='job-subscriptions'>
              <li>
                <img src={offers}/>
                <span>Candidaturas</span>
              </li>
            </Link>
          </div>}
          {userData?.usertype === 'company' &&
          <div className='offer-links'>
            <Link to='offers/create'>
              <li>
                <img src={offers}/>
                <span>Cadastrar vagas</span>
              </li>
            </Link>
            <Link to='offers'>
              <li>
                <img src={offers}/>
                <span>Minhas vagas</span>
              </li>
            </Link>
          </div>}
          {userData?.usertype === 'candidate' &&
          <SearchInput />}
        </div>
        <div className='right-content'>
          {
            userData &&
            <div className='user-menu-container'>
              <div className={'user-data '+(showUserMenu ? 'opened' : '')}>
                <div className='user-info'>
                  <h4 className='cut-text'>Olá, <b>{userData?.full_name}</b></h4>
                  <p>{usertypes[userData?.usertype]}</p>
                </div>
                <div className='profile-picture-container'>
                  {userData?.picture && <img src={userData.picture} />}
                </div>
                <img src={arrow} className='arrow' />
              </div>
              {showUserMenu &&
              <div className='user-menu'>
                <ul>
                  <Link to='profile/candidate/person'><li>Meu perfil</li></Link>
                  <li onClick={onLogout}>Sair</li>
                </ul>
              </div>}
            </div>
          }
          {!userData &&
            <ul className='unlogged-links'>
              <Link to='sign-up'><li>Cadastro</li></Link>
              <Link to='login'><li>Entre</li></Link>
            </ul>}
        </div>
      </div>
    </header>
  )
}

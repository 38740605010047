import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Home from "./presenters/navigation/logged-area/home";
import { SignUp } from "./presenters/navigation/unlogged-area/sign-up";
import CandidateFormConfirmationRequest from "./presenters/navigation/unlogged-area/sign-up/candidate/candidate-form-confirmation-request";
import CandidateFormConfirmationSuccess from "./presenters/navigation/unlogged-area/sign-up/candidate/candidate-form-confirmation-success";
import Login from "./presenters/navigation/unlogged-area/login";
import ForgotPassword from "./presenters/navigation/unlogged-area/forgot-password";
import ResetPassword from "./presenters/navigation/unlogged-area/reset-password";
import CandidateProfile from "./presenters/navigation/logged-area/candidate-profile";
import CompanyFormConfirmationRequest from "./presenters/navigation/unlogged-area/sign-up/company/company-form-confirmation-request";
import CompanyFormConfirmationSuccess from "./presenters/navigation/unlogged-area/sign-up/company/company-form-confirmation-success";
import JobOffers from "./presenters/navigation/logged-area/job-offers";
import JobOffer from "./presenters/navigation/logged-area/job-offer";
import CompanyUserProfile from "./presenters/navigation/logged-area/company-user-profile";
import JobOfferDetails from "./presenters/navigation/logged-area/job-offer-details";
import Candidates from "./presenters/navigation/logged-area/candidates";
import AllCandidates from "./presenters/navigation/logged-area/all-candidates";
import Candidate from "./presenters/navigation/logged-area/candidate";
import JobSubscriptions from "./presenters/navigation/logged-area/job-subscriptions";
import LoggedArea from "./presenters/navigation";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Home />
            },
            {
                path: '/:section',
                element: <Home />
            },
            {
                path: '/sign-up',
                element: <SignUp />
            },
            {
                path: '/sign-up/:usertype',
                element: <SignUp />
            },
            {
                path: '/sign-up/candidate/confirmation/request',
                element: <CandidateFormConfirmationRequest />
            },
            {
                path: '/sign-up/candidate/confirmation/success',
                element: <CandidateFormConfirmationSuccess />
            },
            {
                path: '/sign-up/company/confirmation/request',
                element: <CompanyFormConfirmationRequest />
            },
            {
                path: '/sign-up/company/confirmation/success',
                element: <CompanyFormConfirmationSuccess />
            },
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            },
            {
                path: '/reset-password',
                element: <ResetPassword />
            },
            {
                path: '/',
                element: <LoggedArea />,
                children: [
                    {
                        path: '/offers',
                        element: <JobOffers />
                    },
                    {
                        path: '/job-subscriptions',
                        element: <JobSubscriptions />
                    },
                    {
                        path: '/offers/create',
                        element: <JobOffer />
                    },
                    {
                        path: '/offers/:offer_id',
                        element: <JobOffer />
                    },
                    {
                        path: '/offers/:offer_id/candidates',
                        element: <Candidates />
                    },
                    {
                        path: '/offers/:offer_id/details',
                        element: <JobOfferDetails />
                    },
                    {
                        path: '/candidates',
                        element: <AllCandidates />
                    },
                    {
                        path: '/candidates/:candidate_id',
                        element: <Candidate />
                    },
                    {
                        path: '/profile/company/user',
                        element: <CompanyUserProfile />
                    },
                    {
                        path: '/profile/candidate',
                        element: <CandidateProfile />
                    },
                    {
                        path: '/profile/candidate/:section',
                        element: <CandidateProfile />
                    }
                ]
            }
        ]
    }
])
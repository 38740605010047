import { Component } from 'react'
import './styles.sass'
import { Input } from '../../../components/input'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../insfrastructure/utils/form-validators'
import { Select } from '../../../components/select'

export default class CompanyUserProfile extends Component {

  state: any = {
    form: {

    },
    formErrors: {}
  }

  onSubmit = () => {
    const errors = formatErrors({
      fullname: [emptyField(this.state.form.fullname, 'string')],
      phone: [emptyField(this.state.form.phone, 'string')],
      date: [emptyField(this.state.form.date, 'string')],
      city: [emptyField(this.state.form.city, 'string')],
      marital_status: [emptyField(this.state.form.marital_status, 'string')],
      deficiency: [emptyField(this.state.form.deficiency, 'string')],
      gender: [emptyField(this.state.form.gender, 'string')],
      uf: [emptyField(this.state.form.uf, 'string')]
    });

    this.setState({ formErrors: errors });

    if(errorsLength(errors) > 0) return;

    try {
      this.setState({ loading: true });
        setTimeout(() => {
          this.setState({ loading: false });
        }, 200);
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='company-user-profile company-page'>
        <div className='block'>
            <h3>Dados Pessoais do Responsável</h3>
            <div className='columns'>
              <div className='column'>
                <Input
                name='fullname'
                label='Nome Complate'/>
                <ErrorMessage errors={this.state.formErrors['fullname']} />
                <Input
                name='phone'
                label='Celular'/>
                <ErrorMessage errors={this.state.formErrors['phone']} />
                <Input
                name='date'
                type='date'
                label='Data de Nascimento'/>
                <ErrorMessage errors={this.state.formErrors['date']} />
                <Select
                name='city'
                label='Cidade'
                options={[
                  {
                    label: 'São Paulo',
                    value: 'São Paulo'
                  }
                ]}
                onChange={e => this.setState({ form: { ...this.state.form, city: e } })}/>
                <ErrorMessage errors={this.state.formErrors['city']} />
              </div>
              <div className='column'>
                <Input
                name='marital_status'
                label='Estado Civil'/>
                <ErrorMessage errors={this.state.formErrors['marital_status']} />
                <Select
                name='deficiency'
                label='Deficiência'
                options={[
                  {
                    label: 'Deficiência física',
                    value: 'Deficiência física'
                  },
                  {
                    label: 'Deficiência auditiva e surdez',
                    value: 'Deficiência auditiva e surdez'
                  },
                  {
                    label: 'Deficiência visual',
                    value: 'Deficiência visual'
                  },
                  {
                    label: 'Deficiência intelectual/mental',
                    value: 'Deficiência intelectual/mental'
                  },
                  {
                    label: 'Deficiência múltipla',
                    value: 'Deficiência múltipla'
                  },
                  {
                    label: 'Mobilidade reduzida',
                    value: 'Mobilidade reduzida'
                  },
                  {
                    label: 'Outro',
                    value: 'Outro'
                  },
                  {
                    label: 'Não tenho deficiência',
                    value: 'Não tenho deficiência'
                  }
                ]}
                onChange={e => this.setState({ form: { ...this.state.form, deficiency: e } })}/>
                {this.state.form.deficiency === 'Outro' &&
                <>
                  <Input
                  name='expecific_deficiency'
                  label='Expecificar Deficiência'
                  onChange={e => this.setState({ form: { ...this.state.form, expecific_deficiency: e } })}/>
                  <ErrorMessage errors={this.state.formErrors['expecific_deficiency']} />
                </>}
                <Input
                name='gender'
                label='Gênero'/>
                <ErrorMessage errors={this.state.formErrors['gender']} />
                <Select
                name='uf'
                label='Estado'
                options={[
                  {
                    label: 'São Paulo',
                    value: 'São Paulo'
                  }
                ]}
                onChange={e => this.setState({ form: { ...this.state.form, uf: e } })}/>
                <ErrorMessage errors={this.state.formErrors['uf']} />
              </div>
            </div>
            <button className='primary' onClick={this.onSubmit}>Confirmar</button>
        </div>
      </div>
    )
  }
}

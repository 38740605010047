import React, { Component } from 'react'
import './styles.sass'

export default class Subscriptions extends Component {
  state = {
    subscriptions: [
      {
        name: 'Volkswagen Zona Sul',
        type: 'Efetiva',
        location: 'São Paulo/SP',
        date: '09/07/2023'
      },
      {
        name: 'Volkswagen Zona Sul',
        type: 'Efetiva',
        location: 'São Paulo/SP',
        date: '09/07/2023'
      },
      {
        name: 'Volkswagen Zona Sul',
        type: 'Efetiva',
        location: 'São Paulo/SP',
        date: '09/07/2023'
      },
      {
        name: 'Volkswagen Zona Sul',
        type: 'Efetiva',
        location: 'São Paulo/SP',
        date: '09/07/2023'
      },
      {
        name: 'Volkswagen Zona Sul',
        type: 'Efetiva',
        location: 'São Paulo/SP',
        date: '09/07/2023'
      }
    ]
  }

  render() {
    return (
      <div className='saved-offers'>
        <h1>Vagas Salvas</h1>
        <div className='saved-offers-list'>
          {
            this.state.subscriptions.map(x => 
            <div className='saved-offer'>
              <h4>{x.name}</h4>
              <div>
                <p>Vaga: <b>{x.name}</b></p>
                <p>Tipo de Vaga: <b>{x.type}</b></p>
                <p>Local: <b>{x.location}</b></p>
                <p>Data: <b>{x.date}</b></p>
              </div>
            </div>)
          }
        </div>
      </div>
    )
  }
}

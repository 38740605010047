import { Component } from 'react'
import './styles.sass'
import { ItemSlider } from './slider'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'
import Oportunities from './components/oportunities'

class Home extends Component<RouterProps> {
  state = {
    slides: [
      // 'https://d2d7ho1ae66ldi.cloudfront.net/ArquivoNoticias/7daf2200-2aca-11ed-aa6e-9587410378a2/melkor-morgoth.jpg',
      // 'https://images.nightcafe.studio/jobs/Qgdvd7TlGKDSABG5r7Ie/Qgdvd7TlGKDSABG5r7Ie--1--mew4h.jpg?tr=w-1600,c-at_max'
      '/assets/images/banner.png'
    ]
  }

  componentDidMount() {
    this.jumpToSection((this.props.params as any)['section'])
  }

  componentDidUpdate(prevProps: Readonly<RouterProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if((prevProps.params as any)['section'] !== (this.props.params as any)['section']) {
      this.jumpToSection((this.props.params as any)['section']);
    };
  }

  jumpToSection = (section?: string) => {
    if(!section) {
      window.scrollTo({ top: 0, behavior: 'smooth' });

      return;
    };

    const sectionElement = document.querySelector('.'+section) as HTMLElement;

    window.scrollTo({ top: sectionElement.offsetTop - 50, behavior: 'smooth' });
  }

  render() {
    return (
      <div className='home'>
        <div className='slider-container'>
          <ItemSlider passedSlides={this.state.slides} />
        </div>
        <Oportunities />
      <div className='how-to-anounce'>
        <div className='content'>
          <div className='block'>
            <div className='title'>
              <h1>Como Anunciar</h1>
            </div>
            <div className='columns'>
              <div className='left'>
                <div className='steps'>
                  <h4>Para Empresas Já Cadastradas:</h4>
                  <div>
                    <p><b>1</b> - Acesse o Site</p>
                    <p>Vá para o site do Emprega Mecânico.</p>
                  </div>
                  <div>
                    <p><b>2</b> - Faça o Login</p>
                    <p>Clique em "Entrar" e faça o login com suas credenciais de empresa.</p>
                  </div>
                  <div>
                    <p><b>3</b> - Acesse o Menu de Vagas</p>
                    <p>No painel de controle da sua conta, navegue até o menu "Vagas".</p>
                  </div>
                  <div>
                    <p><b>4</b> - Adicione uma Nova Vaga</p>
                    <p>Clique em "Adicionar Nova Vaga" e preencha o formulário com as informações da vaga, incluindo título, descrição, requisitos e localização.</p>
                  </div>
                  <div>
                    <p><b>5</b> - Publique o Anúncio</p>
                    <p>Revise as informações e publique o anúncio da vaga.</p>
                  </div>
                </div>
                <div className='image-block'></div>
              </div>
              <div className='right'>
                <div className='image-block'></div>
                <div className='steps'>
                  <h4>Para Empresas no Primeiro Acesso:</h4>
                  <div>
                    <p><b>1</b> - Acesse o Site</p>
                    <p>Vá para o site do Emprega Mecânico.</p>
                  </div>
                  <div>
                    <p><b>2</b> - Clique em "Anunciar Vaga"</p>
                    <p>Clique no botão "Anunciar Vaga".</p>
                  </div>
                  <div>
                    <p><b>3</b> - Escolha "Cadastro"</p>
                    <p>Selecione a opção "Cadastro" para iniciar o processo de cadastro, se sequencia selecione a aba de empresas.</p>
                  </div>
                  <div>
                    <p><b>4</b> - Cadastre sua Empresa</p>
                    <p>Complete o formulário de cadastro com os detalhes da sua empresa e da vaga que deseja anunciar.</p>
                  </div>
                  <p>Aguarde a Confirmação</p>
                  <div>
                    <p><b>5</b> - Confirmação  </p>
                    <p>Após o cadastro, você receberá um e-mail com a confirmação da vaga e de seu cadastro.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='about-us'>
        <div className='content'>
          <div className='block'>
            <div className='title'>
              <h1>Sobre o Emprega Mecânico</h1>
            </div>
            <div className='about-text'>
              <p>Somos a Emprega Mecânico, a plataforma que conecta profissionais qualificados do setor automotivo a oportunidades de emprego em todo o Brasil. Facilitamos a colocação e recolocação de talentos, atendendo tanto alunos e ex-alunos da Escola do Mecânico quanto profissionais de diversas regiões. Nosso objetivo é assegurar que a "pessoa certa" encontre o "emprego certo".</p>
            </div>
            <div className='columns'>
              <div className='left'>
                <div className='text'>
                  <h3>O Que Fazemos</h3>
                  <div>
                    <h4>Para Empregadores:</h4>
                    <ul>
                      <li>Acesso a Talentos: Encontre currículos de profissionais qualificados em todo o Brasil.</li>
                      <li>Publicação de Vagas: Anuncie suas oportunidades de forma eficiente e simplificada.</li>
                    </ul>
                  </div>
                  <div>
                    <h4>Para Candidatos:</h4>
                    <ul>
                      <li>Descubra Vagas: Navegue e aplique-se para oportunidades no setor automotivo via nosso aplicativo ou plataforma web.</li>
                      <li>Acompanhe sua Aplicação: Monitore o status das suas candidaturas facilmente através do app ou da web</li>
                    </ul>
                  </div>
                  <div>
                    <h3>Benefícios</h3>
                    <ul>
                      <li>Para Alunos e Ex-Alunos da Escola do Mecânico: Oportunidades para iniciar ou avançar na carreira no setor automotivo.</li>
                      <li>Para Profissionais de Todo o Brasil: Acesso a vagas em diversas regiões do país.</li>
                      <li>Para Empresas: Acesso a uma ampla rede de talentos qualificados e especializados.</li>
                      <li>Para o Emprega Mecânico: Facilitamos a conexão entre profissionais e empresas, promovendo o desenvolvimento profissional.</li>
                      <li>Para a Sociedade: Contribuímos para o aprimoramento da qualidade no setor automotivo.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='right'>
                <div className='text'>
                  <h3>Como Funciona</h3>
                  <div>
                    <h4>Empregador:</h4>
                    <ol>
                      <li>Acesse o Banco de Currículos: Busque e encontre profissionais qualificados.</li>
                      <li>Anuncie Vagas: Clique em "Anunciar Vaga" e siga as instruções para criar seu anúncio.</li>
                    </ol>
                  </div>
                  <div>
                    <h4>Candidato:</h4>
                    <ol>
                      <li>Cadastre-se: Utilize nosso aplicativo ou plataforma web para se cadastrar.</li>
                      <li>Candidate-se e Acompanhe: Explore vagas, aplique-se e monitore o progresso das suas candidaturas.</li>
                    </ol>
                  </div>
                </div>
                <div className='image-block'></div>
              </div>
            </div>
            <div className='bottom-text'>
              <p>Emprega Mecânico – Conectando talentos e oportunidades para um futuro profissional bem-sucedido.
              Para mais informações, entre em contato conosco através do [email] ou visite nossa página de [contato].</p>
            </div>
          </div>
        </div>
      </div>
      <div className='success-stories'>
        <div className='content'>
          <div className='block'>
            <div className='title'>
              <h1>Histórias de Sucesso</h1>
            </div>
            <div>
              <h2>Transformando Vidas com Emprega Mecânico</h2>
              <p>No Emprega Mecânico, não apenas conectamos profissionais e empresas – transformamos vidas e carreiras. Nossa missão vai além de preencher vagas; buscamos criar oportunidades reais que impactam positivamente a trajetória de nossos candidatos e o sucesso das empresas que atendemos. Aqui, compartilhamos algumas das histórias inspiradoras de como nossa plataforma fez a diferença.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='contact'>
        <div className='content'>
          <div className='block'>
            <div className='title'>
              <h1>Fale conosco</h1>
            </div>
            <div className='block-content'>
              <div className='text'>
                <p>Estamos aqui para ajudar! Se você tiver dúvidas, precisar de suporte ou desejar mais informações sobre o Emprega Mecânico, não hesite em nos contatar. Nossa equipe está à disposição para garantir que sua experiência com nossa plataforma seja a melhor possível.</p>
              </div>
              <div className='columns'>
                <div className='left'>
                  <div className='form-container'>
                    <form>
                      <Input
                      name='name'
                      label='Nome'
                      placeholder='Insira seu nome'/>
                      <Input
                      name='email'
                      label='Email'
                      placeholder='Insira seu email'/>
                      <Input
                      name='subject'
                      label='Assunto'
                      placeholder='Insira o assunto do email'/>
                      <Input
                      name='message'
                      label='Mensagem'
                      type='textarea'
                      placeholder='Insira sua mensagem'/>
                    </form>
                    <button className='secondary'>Enviar</button>
                  </div>
                </div>
                <div className='right'>
                  <div className='image-block'></div>
                </div>
              </div>
              <p className='contact-email'>contato@empregamecanico.com.br</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const Input = ({ name, label, placeholder, type }: { name: string, label: string, placeholder: string, type?: string }) => {
  return (
    <div className='input-container'>
      <label>{label}</label>
      {
        type !== 'textarea' ?
        <input name={name} type={type ? type : 'text'} placeholder={placeholder} /> :
        <textarea name={name} placeholder={placeholder}></textarea>
      }
    </div>
  )
}

export default withRouter(Home);
import axios from "axios";
import { environment } from "../../environments/environment";
import { ListResponse } from "./utils";

export interface Course {
    id: number,
    legacy_id: number,
    institution: string,
    course_name: string,
    start_date: string,
    end_date: string,
    candidate: string
}

export const listCourses = async (candidate_id: any): Promise<ListResponse<Course>> => {
    const response = await axios.get<ListResponse<Course>>(environment.apiUrl+'/api/course?candidate_id='+candidate_id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
};

export const createCourse = async (data: Course): Promise<ListResponse<Course>> => {
    const response = await axios.post<ListResponse<Course>>(environment.apiUrl+'/api/course/', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 201) {
        throw Error('error');
    };

    return response.data;
};

export const updateCourse = async (course_id: number, data: Course): Promise<ListResponse<Course>> => {
    const response = await axios.put<ListResponse<Course>>(environment.apiUrl+'/api/course/'+course_id+'/', data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
};


export const deleteCourse = async (course_id: number) => {
    const response = await axios.delete(environment.apiUrl+'/api/course/'+course_id+'/', {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 204) {
        throw Error('error');
    };
};
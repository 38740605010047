import { Component } from 'react'
import { Input } from '../../../../components/input'
import { SubmitBtn } from '../../../../components/submit'
import { ErrorMessage, emptyField, errorsLength, formatErrors } from '../../../../../insfrastructure/utils/form-validators'
import { Select } from '../../../../components/select'
import ConfirmationModal from '../../../../components/confimation-modal'
import CheckboxInput from '../../../../components/checkbox'
import { createExpirence, deleteExpirence, Expirence, listExpirences, updateExpirence } from '../../../../../insfrastructure/services/experiences'
import './styles.sass'
import { listCities } from '../../../../../insfrastructure/services/cities'
import ResponseModal from '../../../../components/response-modal'

export default class Experiences extends Component {

  state: any = {
    experience_items: []
  }

  async componentDidMount() {
    try {
      const experiences = await listExpirences(localStorage.getItem('candidate_id'));

      const experience_items = experiences.results.map(item => {    
        return {
          item,
          editing: false
        };
      });

      this.setState({ experience_items });
    } catch (error) {}
  }

  editExperience = (id: number) => {
    const items = [...this.state.experience_items].map(x => {
      if(x.item.id === id) {
        x.editing = true;
      };
    
      return x;
    });

    this.setState({ experience_items: items });
  }

  addExperience = () => {
    const items = [...this.state.experience_items];
    items.push({
      item: {
        id: 0
      },
      editing: true
    });

    this.setState({ experience_items: items });
  }

  onSubmitExperience = async (x: any, index: number) => {
    try {
      if (x.start_date && typeof x.start_date === 'object') {
        const offset = x.start_date.getTimezoneOffset()
        x.start_date = new Date(x.start_date.getTime() - (offset*60*1000))
        x.start_date = x.start_date.toISOString().split('T')[0]
      };
  
      if (x.end_date && typeof x.end_date === 'object') {
        const offset = x.end_date.getTimezoneOffset()
        x.end_date = new Date(x.end_date.getTime() - (offset*60*1000))
        x.end_date = x.end_date.toISOString().split('T')[0]
      };
  
      const newItem = x.id === 0 ? await createExpirence(x) : await updateExpirence(x.id, x);
  
      const items = [...this.state.experience_items].map((y, i) => {
        if(i === index) {
          y.item = newItem;
          y.editing = false;
        };
      
        return y;
      });
    
      let responseData = {
        type: 'success',
        title: 'Dados salvos com sucesso!',
        text: () => <></>
      };

      setTimeout(() => {
        this.setState({ loading: false, scholarship_items: items, responseData });
      });
    } catch (error) {
      let responseData = {
        type: 'error',
        title: 'Ocorreu um erro inesperado',
        text: () => <></>
      };

      this.setState({ loading: false, responseData });
    }
  }

  removeExperience = async (id: number) => {
    try {
      await deleteExpirence(id);

      const items = this.state.experience_items.filter((x: any) => x.item.id !== id);

      let responseData = {
        type: 'success',
        title: 'Experiência removida com sucesso!',
        text: () => <></>
      };

      setTimeout(() => {
        this.setState({ loading: false, experience_items: items, responseData });
      });
    } catch (error) {
      let responseData = {
        type: 'error',
        title: 'Ocorreu um erro inesperado',
        text: () => <></>
      };

      this.setState({ loading: false, responseData });
    }
  }

  render() {
    return (
      <div className='experience-form'>
        <h1>Experiência</h1>
        <div className='experiences'>
          {
            this.state.experience_items.length > 0 ?
            this.state.experience_items.map((x: any, i: number) =>
            <ExperienceForm
            formData={x.item}
            readonly={!x.editing}
            onSubmit={(e) => this.onSubmitExperience(e, i)}
            onUpdate={this.editExperience}
            onRemove={i => this.removeExperience(i)} />) :
            <div className='empty-list'>
              <p>Nenhuma experiência adicionada</p>
            </div>
          }
        </div>
        <SubmitBtn className='secondary' text='Adicionar Experiência' onSubmit={this.addExperience} />
        <ResponseModal
        render={this.state.responseData}
        type={this.state.responseData?.type}
        title={this.state.responseData?.title}
        text={this.state.responseData?.text}
        onClose={() => this.setState({ responseData: null })} />
      </div>
    )
  }
}

class ExperienceForm extends Component<{formData: Expirence, onSubmit: (x: any) => any, onUpdate: (x: any) => any, onRemove: (i: number) => void, readonly: boolean}> {
  state: any = {
    form: {},
    formErrors: {},
    loading: false,
    cities: [],
    cityOptions: []
  }
  citiesSearchTimeout: any = null

  componentDidMount() {
    if(this.props.formData) {
      this.setState({ form: this.props.formData }, () => {
        this.state.form.city_name && this.onCityNameSearch(this.state.form.city_name);
      });
    };
  }

  componentDidUpdate(prevProps: Readonly<{ formData: Expirence; em_units: any[]; onSubmit: (x: any) => any; onUpdate: (x: any) => any; onRemove: (i: number) => void; readonly: boolean }>, prevState: Readonly<{}>, snapshot?: any): void {
    if(prevProps.formData && this.props.formData.id !== prevProps.formData.id) {
      this.setState({ form: this.props.formData });
    };
  }

  onSubmit = async () => {
    const errors = formatErrors({
      company_name: [emptyField(this.state.form.company_name, 'string')]
    });

    this.setState({ formErrors: errors });
  
    if(errorsLength(errors) > 0) return;

    this.setState({ loading: true });

    try {
        this.props.onSubmit(this.state.form);
        
        setTimeout(() => {
            this.setState({ loading: false });
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  onCityNameSearch = async (e: any) => {
    clearTimeout(this.citiesSearchTimeout);
    
    this.citiesSearchTimeout = setTimeout(async () => {
      if (e.length < 5) {
        return;
      };

      const cities = (await listCities(e));
      const cityOptions = cities.map(x => ({ value: parseInt(x.id+''), label: x.displayName }));

      this.setState({ cities, cityOptions });
    }, 1000);
  }

  onSelectCity = async (city_id: any) => {
    const city = this.state.cities.filter((x: any) => x.id === city_id+'')[0];

    this.setState({
      form: {
        ...this.state.form,
        city_id,
        city_uf: city ? city.uf : null
      }
    });
  }

  onRemoveExperience = async () => {
    this.setState({ loading: true });
    this.setState({ selectedItemToDelete: null });

    try {
        
        setTimeout(() => {
          this.setState({ loading: false });
          this.props.onRemove(this.state.form.id as number);
        });
    } catch (error) {
        this.setState({ loading: false });
    }
  }

  render() {
    return (
      <div className='form'>
        <div className='columns-group'>
          <div className='columns'>
            <div className='column'>
              <Input
              name='company_name'
              label='Empresa'
              placeholder='Empresa'
              value={this.state.form['company_name']}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, company_name: e } })}/>
              <ErrorMessage errors={this.state.formErrors['company_name']} />
              <Input
              name='position'
              label='Cargo'
              placeholder='Cargo'
              value={this.state.form['position']}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, position: e } })}/>
              <ErrorMessage errors={this.state.formErrors['position']} />
              <Select
              name='city_id'
              label='Cidade'
              options={this.state.cityOptions}
              value={this.state.form.city_id}
              readonly={this.props.readonly}
              hasSearch={true}
              onSearchChange={e => this.onCityNameSearch(e)}
              onChange={e => {this.setState({ form: { ...this.state.form, city_id: e } }); this.onSelectCity(e);}}/>
              <ErrorMessage errors={this.state.formErrors['city_id']} />
              <Select
              name='job_type'
              label='Tipo da Vaga'
              placeholder='Tipo da Vaga'
              value={this.state.form['job_type']}
              options={[
                { value: "temporary", label: "temporaria" },
                { value: "full_time", label: "efetiva" },
                { value: "part_time", label: "meio_periodo" },
                { value: "freelance", label: "freelancer" },
                { value: "internship", label: "estagio" }
              ]}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, job_type: e } })}/>
              <ErrorMessage errors={this.state.formErrors['job_type']} />
            </div>
            <div className='column'>
              <Input
              name='start_date'
              label='Data de Início'
              placeholder='Data de Início'
              type='date'
              dateFormat='MM/yyyy'
              value={this.state.form['start_date']}
              readonly={this.props.readonly}
              onChange={e => this.setState({ form: { ...this.state.form, start_date: e } })}/>
              <ErrorMessage errors={this.state.formErrors['start_date']} />
              <CheckboxInput
              name='currently_working'
              single={true}
              value={this.state.form.currently_working}
              disabled={this.props.readonly}
              options={[
                {
                  label: 'Estou trabalhando nesse cargo atualmente',
                  value: 'currently_working'
                }
              ]}
              onChange={e => this.setState({ form: { ...this.state.form, currently_working: e, end_date: null } })}/>
              <ErrorMessage errors={this.state.formErrors['currently_working']} />
              <Input
              name='end_date'
              label='Data de Conclusão'
              placeholder='Data de Conclusão'
              type='date'
              dateFormat='MM/yyyy'
              value={this.state.form['end_date']}
              readonly={this.props.readonly || this.state.form.currently_working}
              onChange={e => this.setState({ form: { ...this.state.form, end_date: e } })}/>
              <ErrorMessage errors={this.state.formErrors['end_date']} />
              <Input
              name='city_uf'
              label='Estado'
              value={this.state.form.city_uf}
              readonly={true}
              onChange={e => this.setState({ form: { ...this.state.form, city_uf: e } })}/>
              <ErrorMessage errors={this.state.formErrors['city_uf']} />
            </div>
          </div>
          <div className='full-width-column'>
            <Input
            name='description'
            type='textarea'
            label='Descrição do Curso'
            value={this.state.form['description']}
            placeholder='Descrição do Curso'
            readonly={this.props.readonly}
            onChange={e => this.setState({ form: { ...this.state.form, description: e } })}/>
          </div>
        </div>
        <div className='btns'>
          {!this.props.readonly &&
          <SubmitBtn className='secondary' text='Salvar Alterações' loadingText='Salvando...' loading={this.state.loading} onSubmit={this.onSubmit} />}
          {this.props.readonly &&
          <SubmitBtn className='secondary' text='Editar' onSubmit={() => this.props.onUpdate(this.state.form['id'])} />}
          {this.props.readonly &&
          <SubmitBtn className='secondary' text='Remover' loadingText='Removendo...' loading={this.state.loading} onSubmit={() => this.setState({ selectedItemToDelete: true })} />}
        </div>
        <ConfirmationModal
        title='Deletar essa formação?'
        text='Tem certeza que deseja deletar essa formação? Nós não mostraremos ela novamente para você.'
        render={this.state.selectedItemToDelete}
        onConfirm={this.onRemoveExperience}
        onClose={() => this.setState({ selectedItemToDelete: null })}/>
      </div>
    )
  }
}
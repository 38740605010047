import { Component } from 'react'
import Modal from '../../../../components/modal'
import { SubmitBtn } from '../../../../components/submit'
import calendarIcon from '../../../../../assets/icons/calendar-blue.png'
import './styles.sass'

interface ModalProps {
    render: boolean,
    candidate?: any,
    onClose: () => void,
    onSubmitted: () => void
}

export default class ApproveModal extends Component<ModalProps> {
    state: any = {
        form: {},
        formErrors: {},
        loading: false
    }

    onSubmit = async () => {
        if(this.state.loading) return;

        try {
            this.setState({ loading: true });

        } catch (error) {
            
        };

        setTimeout(() => {
            this.setState({ loading: false });
            this.props.onSubmitted();
        }, 500);
    }

    render() {
        return (
        <Modal render={this.props.render} onClose={() => this.props.onClose()}>
            <div className='approve-modal'>
                <div className='modal-header'>
                    <img src={calendarIcon} />
                    <h4>Marcar <b>{this.props.candidate && this.props.candidate.name}</b> como não apto</h4>
                </div>
                <p>Tem certeza dessa ação? Ao realizá-la, a vaga concorrida será preenchida.</p>
                <SubmitBtn
                className='primary'
                text='Sim, tenho certeza'
                loadingText='Carregando...'
                onSubmit={this.onSubmit}
                loading={this.state.loading} />
            </div>
        </Modal>
        )
    }
}

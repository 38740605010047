import { Component } from 'react'
import './styles.sass'

interface Props {
    name: string,
    label?: string,
    options: any[],
    disabled?: boolean,
    readonly?: boolean,
    single?: boolean,
    value?: [],
    onChange?: (value: any) => any
}

export default class CheckboxInput extends Component<Props> {
    state: any = {   
        currentValue: [],
        opened: null
    }

    constructor(props: any) {
        super(props);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.value !== this.props.value) {
            let currentValue: any = this.props.value;

            if(this.props.single) {
                currentValue = this.props.value ? [this.props.value] : [];
            };

            this.setState({ currentValue });
        };
    }

    onSelect = (option: any) => {
        let currentValue = 
        this.state.currentValue.includes(option.value) ?
        [...this.state.currentValue].filter(x => x !== option.value) :
        [...this.state.currentValue, option.value];

        this.setState({ currentValue }, () => {
            this.props.onChange && this.props.onChange(this.props.single ? this.state.currentValue.join() : this.state.currentValue);
        });
    }

    render() {
        return (
            <div className={'checkbox-container '+(this.props.disabled ? 'disabled' : '')}>
                {this.props.label && <label>{this.props.label}</label>}
                <div className='options'>
                    {
                        this.props.options && this.props.options.map((x: any, i: number) => 
                        <div key={'radio_option_'+i+(new Date().getTime())} className='option' onClick={() => this.onSelect(x)}>
                            <input
                            name={this.props.name+' '+x.value}
                            type='checkbox'
                            value={x.value}
                            disabled={this.props.disabled}
                            checked={this.state.currentValue.includes(x.value)}
                            readOnly />
                            {typeof x.label == 'string' ? <span>{x.label}</span> : x.label()}
                        </div>)
                    }
                </div>
            </div>
        )
    }
}

import { Component } from 'react'
import candidateImg from '../../../../../../assets/images/candidate.png'
import './styles.sass'
import { RouterProps, withRouter } from '../../../../../../insfrastructure/utils/with-router-component';

class CandidateFormConfirmationRequest extends Component<RouterProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className='candidate-sign-up-form-confirmation-request candadite'>
          <div className='content'>
            <div className='block'>
              <div>
                <h3>Parabéns!</h3>
                <p>Agora você faz parte da maior rede mecânicos do Brasil</p>
              </div>
              <img src={candidateImg} />
              <div>
                <p>Enviamos um e-mail para a {this.props.location.state.email}.</p>
                <p>Por favor, confirme e prossiga com a ativação da sua conta</p>
              </div>
            </div>
          </div>
      </div>
    )
  }
}

export default withRouter(CandidateFormConfirmationRequest);
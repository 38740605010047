import axios from "axios";
import { environment } from "../../environments/environment";

export interface StaticValues {
    cnh_category: Record<string, any>,
    course_progress: Record<string, any>,
    disability: Record<string, any>,
    education_level: Record<string, any>,
    em_unit: Record<string, any>,
    gender: Record<string, any>,
    job_status: Record<string, any>,
    job_type: Record<string, any>,
    marital_status: Record<string, any>
}

export const loadStaticValues = async () => {
    const response = await axios.get<any>(environment.apiUrl+'/api/load_static_values', {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
}

import { Component } from 'react'
import forgotPasswordImg from '../../../../assets/images/forgot_password.png'
import checkImg from '../../../../assets/images/check.png'
import { Link } from 'react-router-dom'
import { Input } from '../../../components/input'
import { ErrorMessage, errorsLength, formatErrors, validateEmail } from '../../../../insfrastructure/utils/form-validators'
import { SubmitBtn } from '../../../components/submit'
import ResponseModal from '../../../components/response-modal'
import './styles.sass'


export default class ForgotPassword extends Component {
    state: any = {
        form: {
            email: ''
        },
        formErrors: {},
        loading: false,
        sended: false
    }

    componentDidMount(): void {
    }

    onSubmit = async () => {
        this.setState({ formErrors: {} });
        const errors = formatErrors({
            email: [validateEmail(this.state.form.email)]
        });

        if(errorsLength(errors) > 0) {
            this.setState({ formErrors: errors });

            return;
        };

        this.setState({ loading: true });

        try {
            setTimeout(() => {
                this.setState({ sended: true, loading: false });
            });
        } catch (error) {
            let responseData = {
                type: 'error',
                title: 'Ocorreu um erro inesperado',
                text: () => <></>
            };
        
            this.setState({ loading: false, responseData });
        }
    }

    render() {
        return (
            <div className='forgot-password unlogged-page'>
                <div className='content'>
                    <div className='block'>
                        {window.innerWidth > 1000 &&
                        <div className='img-container'>
                            <img src={forgotPasswordImg} />
                        </div>}
                        {
                            !this.state.sended ?
                            <div className='block-content not-sended'>
                                <div className='text'>
                                    <h1>Esqueceu a Senha?</h1>
                                    <h2>Insira seu e-mail cadastrado</h2>
                                </div>
                                <div className='form'>
                                    <Input
                                    name='email'
                                    label='Email'
                                    placeholder='Insira seu email'
                                    onChange={e => this.setState({ form: {...this.state.form, email: e} })}/>
                                    <ErrorMessage errors={this.state.formErrors['email']} />
                                </div>
                                <p className='bottom-text'>Ao <b>confirmar</b>, enviaremos um e-mail com instruções de como <b>redefinir sua senha</b>.</p>
                                <SubmitBtn className='secondary' text='Confirmar' loadingText='Enviando...' loading={this.state.loading} onSubmit={this.onSubmit} />
                            </div> :
                            <div className='block-content sended'>
                                <div className='text'>
                                    <img className='check' src={checkImg} />
                                    <h3>E-mail de redefinição de senha enviado</h3>
                                </div>
                                <p className='bottom-text'>Um e-mail foi enviado para seu endereço de e-mail a <b>✱✱✱✱✱@g✱✱✱✱.com</b>. Siga as instruções para redefinir sua senha.</p>
                                <Link to='/login'><SubmitBtn className='secondary' text='OK' /></Link>
                            </div>
                        }
                    </div>
                </div>
                <ResponseModal
                render={this.state.responseData}
                type={this.state.responseData?.type}
                title={this.state.responseData?.title}
                text={this.state.responseData?.text}
                onClose={() => this.setState({ responseData: null })} />
            </div>
        )
    }
}
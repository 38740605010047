import { Component } from 'react'
import { RouterProps, withRouter } from '../../../../insfrastructure/utils/with-router-component'
import personImg from '../../../../assets/images/candidate-profile-options/person.svg'
import scholarshipImg from '../../../../assets/images/candidate-profile-options/scholarship.svg'
import experienceImg from '../../../../assets/images/candidate-profile-options/experience.svg'
import jobTitlesInterestsImg from '../../../../assets/images/candidate-profile-options/job-titles-interests.svg'
import subscriptionsImg from '../../../../assets/images/candidate-profile-options/subscriptions.svg'
import personBlueImg from '../../../../assets/images/candidate-profile-options/person-blue.svg'
import scholarshipBlueImg from '../../../../assets/images/candidate-profile-options/scholarship-blue.svg'
import experienceBlueImg from '../../../../assets/images/candidate-profile-options/experience-blue.svg'
import jobTitlesInterestsBlueImg from '../../../../assets/images/candidate-profile-options/job-titles-interests-blue.svg'
import subscriptionsBlueImg from '../../../../assets/images/candidate-profile-options/subscriptions-blue.svg'
import PersonalForm from './personal-form'
import Scholarships from './scholarship-form'
import ExperienceForm from './experience-form'
import JobTitleInterestsForm from './job-title-interests-form'
import Subscriptions from './subscription'
import './styles.sass'

class CandidateProfile extends Component<RouterProps> {
    state = {
        pages: [
            {
                name: 'person',
                link: '',
                icon: personImg,
                selected_icon: personBlueImg,
                component: <PersonalForm />
            },
            {
                name: 'scholarship',
                link: '',
                icon: scholarshipImg,
                selected_icon: scholarshipBlueImg,
                component: <Scholarships />
            },
            {
                name: 'experience',
                link: '',
                icon: experienceImg,
                selected_icon: experienceBlueImg,
                component: <ExperienceForm />
            },
            {
                name: 'interests',
                link: '',
                icon: jobTitlesInterestsImg,
                selected_icon: jobTitlesInterestsBlueImg,
                component: <JobTitleInterestsForm />
            },
            {
                name: 'subscriptions',
                link: '',
                icon: subscriptionsImg,
                selected_icon: subscriptionsBlueImg,
                component: <Subscriptions />
            }
        ],
        selectedPage: 'person'
    }

    componentDidMount() {
        this.changeCurrentPage();
    }

    componentDidUpdate(prevProps: Readonly<RouterProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if(prevProps.params !== this.props.params) {
            this.changeCurrentPage();
        };
    }

    changeCurrentPage() {
        if (this.props.params?.section) {
            this.setState({ selectedPage: this.props.params?.section });
        } else {
            setTimeout(() => {
                this.props.navigate('/profile/candidate/person');   
            });
        };
    }

    onSelectPage(name: string) {
        this.props.navigate('/profile/candidate/'+name);
    }

    render() {
        return (
            <div className='candidate-profile'>
                <div className='content'>
                    <div className='container'>
                        <div className='side-menu'>
                            <ul>
                                {
                                    this.state.pages.map(x =>
                                    <li onClick={() => this.onSelectPage(x.name)} className={this.state.selectedPage === x.name ? 'selected' : ''}>
                                        <img src={this.state.selectedPage !== x.name ? x.icon : x.selected_icon} />
                                    </li>)
                                }
                            </ul>
                        </div>
                        <div className='block'>
                            {
                                this.state.pages.filter(x => x.name === this.state.selectedPage)[0].component
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(CandidateProfile)
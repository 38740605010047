import React, { Component } from 'react'
import arrow from '../../../assets/icons/arrow.png'
import './styles.sass'

interface SelectOptions { 
  name: string,
  label: string,
  placeholder?: string,
  options: any[],
  readonly?: boolean,
  disabled?: boolean,
  value?: any,
  onChange?: (value: any) => any
}

export class MultiSelect extends Component<SelectOptions> {
  ref: React.RefObject<HTMLInputElement>;
  constructor(props: any) {
    super(props);
    this.ref = React.createRef();
  }

  state: any = {
    options: [],
    currentValue: [],
    opened: null
  }

  componentDidMount() {
    this.setState({ options: this.props.options });

    document.addEventListener('click', e => {
      if(this.props.disabled || this.props.readonly || !this.ref.current) return;

      const currentRef = this.ref.current as HTMLElement;
      const target = e.target as HTMLElement;
      const clickedInComponent = currentRef.contains(target);

      const inputContainer = currentRef.querySelector('.select-input-container') as HTMLElement;

      if(clickedInComponent) {
        if(inputContainer.contains(target)) {
          this.setState({ opened: !this.state.opened }, () => {
            currentRef.style.zIndex = this.state.opened ? '10000' : '1' 
          });
        };
      };
      
      if(!clickedInComponent) {
        this.setState({ opened: false });
      };
    });
  }

  componentDidUpdate(prevProps: Readonly<SelectOptions>, prevState: Readonly<{}>, snapshot?: any): void {
    if(prevProps.value !== this.props.value) {
      this.setState({ currentValue: this.props.value });
    };
  }

  onSelect = (option: any) => {
    let currentValue = 
    this.state.currentValue.includes(option.value) ?
    [...this.state.currentValue].filter(x => x !== option.value) :
    [...this.state.currentValue, option.value];

    this.setState({ currentValue }, () => {
      this.props.onChange && this.props.onChange(this.state.currentValue);
    });
  }

  render() {
    return (
      <div ref={this.ref} className={'select-container '+(this.props.disabled ? 'disabled' : '')+(this.props.readonly ? 'readonly' : '')+(this.state.opened ? 'opened' : '')}>
        <label>{this.props.label}</label>
        <div className='select-input-container'>
          <input value={this.state.currentValue ? this.props.options.filter(x => this.state.currentValue.includes(x.value)).map(x => x.label).join(', ') : ''} placeholder={this.props.placeholder} readOnly/>
          <img className='arrow' src={arrow} />
        </div>
        {this.state.opened && 
        <div className='options'>
          <ul>
            <li key={this.props.name+'_empty'} id={this.props.name+'_empty'} onClick={() => this.onSelect({ value: '' })}>-</li>
            {
              this.props.options.map((x: any, i: number) => 
              <li key={this.props.name+'_'+x.value} id={this.props.name+'_'+x.value} onClick={() => this.onSelect(x)}>
                <input
                name={this.props.name+' '+x.value}
                type='checkbox'
                value={x.value}
                checked={this.state.currentValue.includes(x.value)} />
                {x.label}
              </li>)
            }
          </ul>
        </div>}
      </div>
    )
  }
}


// export const Select = ({ name, label, placeholder, value, options, disabled, onChange }: SelectOptions) => {
//     const[currentValue, setCurrentValue] = useState(value)
//     const [opened, toggleOpened] = useState(false)
//     const ref = useRef(null)

//     useEffect(() => {
//       document.addEventListener('click', e => {
//         if(disabled) return;

//         const clickedInComponent = ref.current && (ref.current as HTMLElement).contains(e.target as HTMLElement);

//         if(clickedInComponent) {
//           toggleOpened(!opened);
//         } else {
//           toggleOpened(false);
//         };
//       });
//     }, [])

//     const onSelect = (option: any) => {
//       onChange && onChange(option.value);
//       setCurrentValue(option.value);
//     }

//     return (
//       <div ref={ref} className={'select-container '+(disabled ? 'disabled' : '')}>
//         <label>{label}</label>
//         <span>{currentValue}</span>
//         {opened && 
//         <div className='options'>
//           <ul>
//             {
//               options.map((x, i) => 
//               <li key={this.props.name+'_'+} onClick={() => onSelect(x)}>{x.label}</li>)
//             }
//           </ul>
//         </div>}
//       </div>
//     )
// }
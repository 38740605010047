import axios from "axios";
import { environment } from "../../environments/environment";

interface City {
  id: string,
  name: string,
  uf: string,
  displayName: string
};

export const listCities = async (name: string): Promise<City[]> => {
  const { data } = await axios.post(`${environment.apiUrl}/graphql`,
    {
      "operationName": "CityByName",
      "query": `query CityByName {cityByName(name: "${name}") {id, name, uf, displayName }}`,
      "variables": {}
    }, {
      headers: {
        "content-type": "application/json"
      }
    }
  );

  return data.data.cityByName;
};


import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CandidateFormWithRouter } from './candidate/candidate-form'
import CompanyForm from './company/company-form'
import candidateImg from '../../../../assets/images/candidate.png'
import companyImg from '../../../../assets/images/company.png'
import './styles.sass'

export const SignUp = () => {
    const navigate = useNavigate()
    const{ usertype } = useParams()
    const usertypes = [
        {
            image: candidateImg,
            name: 'candidate',
            label: 'Candidatos'
        },
        {
            image: companyImg,
            name: 'company',
            label: 'Empresas'
        }
    ]
    const [SelectedUsertypeForm, use_selected_usertype_form] = useState<any>(null)
    const usertypes_forms: Record<string, any> = {
        candidate: <CandidateFormWithRouter/>,
        company: <CompanyForm/>
    }

    useEffect(() => {
        if(usertype) {
            use_selected_usertype_form(usertypes_forms[usertype])
        } else {
            use_selected_usertype_form(usertype);
        };
    }, [usertype])    

    return (
        <div className={'sign-up '+usertype}>
            <div className='content'>
                <h1>Cadastro</h1>
                {!SelectedUsertypeForm ?
                    <div className='usertype-selector block'>
                        {
                            usertypes.map((x: any) => 
                            <div className={'usertype '+x.name} onClick={() => navigate("/sign-up/"+x.name)}>
                                <h3>{x.label}</h3>
                                <img src={x.image} />
                                <button className={x.name == 'candidate' ? 'secondary' : 'primary'}>Selecionar</button>
                            </div>)
                        }
                    </div> :
                    SelectedUsertypeForm
                }
            </div>
        </div>
    )
}
import axios from "axios";
import { ListResponse } from "./utils";
import { environment } from "../../environments/environment";

export interface Job {
    id: number,
    job_title: Record<string, any>,
    city: string,
    company: string,
    salary: number,
    job_type: string,
    job_description: string,
    start_date: string,
    created_at: string,
    updated_at: string,
    admin: Record<string, any>,
    contact_email: string,
    contact_name: string,
    status: string,
    escola_do_mecanico: Record<string, any>
}

// const jobMock: Job = {
//     "job_title": {
//         "id": 1,
//         "name": "Outro",
//         "description": null,
//         "created_at": "2025-01-16T11:28:44.776230-03:00",
//         "updated_at": "2025-01-16T11:28:44.786526-03:00"
//     },
//     "city": "Barra Mansa, RJ",
//     "company": "AutoZone Brasil Comércio de Auto Peças",
//     "salary": 2000.0,
//     "job_type": "full_time",
//     "job_description": "Como será?\r\nAtendimento aos clientes, seguindo os padrões Autozone; \r\nVerificar e cuidar dos aspectos internos e externos da loja;\r\nCuidar para que o estoque esteja abastecido, limpo e organizado;\r\nAtualizar e organizar os itens em amostra, etc...\r\nRequisitos:\r\nEnsino Médio completo;\r\nDisponibilidade para trabalhar nos finais de semana e feriado;\r\nDesejável conhecimento no pacote Office (nível básico)\r\nDesejável conhecimento no seguimento automotivo \r\nBeneficios:\r\nPlano de saúde;\r\nPlano dentário;\r\nAlimentação",
//     "start_date": "2025-01-16",
//     "created_at": "2025-01-15T21:00:00-03:00",
//     "updated_at": "2025-01-16T14:07:14.780472-03:00",
//     "admin": {
//         "id": "9398f9d5-0cf0-486b-9587-b250ef017e5f",
//         "legacy_id_company": 11452,
//         "updated_at": "2025-01-16T14:07:11.734888-03:00",
//         "created_at": "2025-01-16T14:07:11.731589-03:00",
//         "user": 16937,
//         "company": "f6dee685-b849-47e4-851e-184a0cdf0097"
//     },
//     "contact_email": "s9460@autozone.com",
//     "contact_name": "(11) 99757-8134",
//     "status": "active",
//     "escola_do_mecanico": {
//         "id": 35,
//         "name": "037.Volta Redonda - RJ",
//         "branch_number": "037",
//         "city": 5537
//     }
// };

export const listJobsOpen = async (params?: any): Promise<ListResponse<{node: Job}>> => {
    const response = await axios.post(`${environment.apiUrl}/graphql`,
        {
          "operationName": "AllJobs",
          "query": `query AllJobs {
                        allJobs(jobType: FULL_TIME, orderBy: "-salary,created_at", offset: ${params.offset}, first: ${params.limit}) {
                            edges {
                                node {
                                    id
                                    jobTitle {
                                        id
                                        name
                                    }
                                    city {
                                        displayName
                                    }
                                    salary
                                    jobType
                                    status
                                    jobDescription
                                    startDate
                                    updatedAt
                                }
                            }
                        }
                    }`,
          "variables": {}
        }, {
          headers: {
            "content-type": "application/json"
          }
        }
    );

    if(response.status !== 200) {
        throw Error('error');
    };

    const data = response.data.data.allJobs.edges;

    return { results: data, count: 0 };
};

export const listJobs = async (params?: any): Promise<ListResponse<{node: Job}>> => {
    const response = await axios.post(`${environment.apiUrl}/graphql`,
        {
          "operationName": "AllJobs",
          "query": `query AllJobs {
                        allJobs(jobType: FULL_TIME, orderBy: "-salary,created_at", offset: ${params.offset}, first: ${params.limit}) {
                            edges {
                                node {
                                    id
                                    jobTitle {
                                        id
                                        name
                                    }
                                    city {
                                        displayName
                                    }
                                    salary
                                    jobType
                                    status
                                    jobDescription
                                    startDate
                                    updatedAt
                                }
                            }
                        }
                    }`,
          "variables": {}
        }, {
          headers: {
            "content-type": "application/json"
          }
        }
    );

    if(response.status !== 200) {
        throw Error('error');
    };

    const count_response = await axios.post(`${environment.apiUrl}/graphql`,
        {
          "operationName": "AllJobs",
          "query": `query AllJobs {
                        allJobs(jobType: FULL_TIME) {
                            edges {
                                node {
                                    id
                                }
                            }
                        }
                    }`,
          "variables": {}
        }, {
          headers: {
            "content-type": "application/json"
          }
        }
    );

    const data = response.data.data.allJobs.edges;

    return { results: data, count: count_response.data.data.allJobs.edges.length };
};

export const retriveJob = async (id: any): Promise<Job> => {
    const response = await axios.get<Job>(environment.apiUrl+'/api/jobpost/'+id, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+localStorage.getItem('auth_token')
        }
    });

    if(response.status !== 200) {
        throw Error('error');
    };

    return response.data;
};

